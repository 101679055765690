import React, { SyntheticEvent } from 'react'
import { OptionListCont, FooterForm } from './styles'
import { Icon, Divider } from '../../../../../components/icons/styles'
import { Key } from '../../../../../components/icons'
import Accordion from '../accodion/accordion'
import { AccodionFooter, InfoItemHeader } from '../accodion/styles'
import { UPDATE_PERMISSIONS } from '../../../../../graphql/mutations/staff.mutation'
import { useMutation } from '@apollo/client'
import SubmitBtn from './submitbtn'
import { perms } from '../../../../../globals'

const Permissions = (props: any) => {
    const {id} = props

    const [submit, { error, loading, data }] = useMutation(UPDATE_PERMISSIONS, {
        // update: (cache, { data: { saveStaffInfo } }) => {
        //     let cached: any = cache.readQuery({
        //         query: GET_STAFF
        //     })
        //     const i: number = cached.staff.findIndex((s: any) => format_date(s._id.toString()) === format_date(saveStaffInfo?.createdAt?.toString()));

        //     console.log(cached)

        //     if (i === -1) {
        //         cached = {
        //             ...cached,
        //             staff: [
        //                 {
        //                     __typename: 'Accounts',
        //                     _id: saveStaffInfo.createdAt,
        //                     records: [{ ...saveStaffInfo }]
        //                 },
        //                 ...cached.staff
        //             ]
        //         }
        //     } else {
        //         cached = {
        //             ...cached,
        //             staff: cached.staff.map((s: any, n: any) => {
        //                 if (n === i) {
        //                     return {
        //                         ...s,
        //                         records: [saveStaffInfo, ...s.records]
        //                     }
        //                 }
        //                 else {
        //                     return s
        //                 }
        //             })
        //             // cached.staff.map((s:any, j:number)=>{
        //             //     return i===j  ? 
        //             //     {
        //             //         ...s, 
        //             //         records: s.records.map((r: any) => (format_date(r._id.toString()) === format_date(saveStaffInfo._id.toString()) ? saveStaffInfo: r))
        //             //     }
        //             //     :
        //             //     s
        //             // })
        //         }
        //     }

        //     cache.writeQuery({
        //         query: GET_STAFF,
        //         data: {
        //             staff: [
        //                 ...cached.staff
        //             ]
        //         }
        //     })
        // }
    })

    if(error) console.log({...error})

    const handleSubmit = (e: SyntheticEvent)=>{
        e.preventDefault();
        submit({
            variables: {
               input:{
                id,
                permissions: {
                    ...perms()
                }
               }
            }
        })
    }
  
    return (
        <OptionListCont id='opt' popover>
            <InfoItemHeader>
                <Icon>
                    <Key />
                </Icon>
                <h6>STAFF PERMISSIONS</h6> 
                <Divider />
            </InfoItemHeader>
            <Accordion type='products'/>
            <Accordion type='sales'/>
            <Accordion type='expenses'/>
            <Accordion type='analytics'/>
            <Accordion type='staff'/>
            <Accordion type='account'/> 
            <AccodionFooter>
                <FooterForm onSubmit={(e: React.SyntheticEvent) => handleSubmit(e)}>
                    <p className='cancel'>Cancel</p>
                    <SubmitBtn data={data} loading={loading} title='Update' />
                </FooterForm>
            </AccodionFooter >
        </OptionListCont>
    )
}

export default Permissions