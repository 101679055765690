// import React from 'react'
// import { getImageUrl } from '../../../../apollo'
// import { getBg } from '../../../../utils'
// import { getBg } from '../../../../../../lib/utils'
import { getBg } from '../../../../../utils'
import { ImageCont } from './styles'

function ImageItem(props: any) {

    const { source, name } = props
    return (
        <ImageCont bg={getBg()}>
            {source ? <img src={source} alt="" /> : <div>{name.charAt(0)}</div>}
        </ImageCont>
    ) 
}

export default ImageItem