import React, { SyntheticEvent, useState } from 'react'
import { getImageUrl } from '../../../../apollo'
import { Desc, DescCol, DescCont, Instock, MenuOptionsList, PriceCont, StockCont, StockPrice, Title, Option, TitleCont } from '../styles'
import { ImageItem } from '../image'

import { Ellipsis } from '../../../icons'
import { formatMoney } from '../../../../utils'
import { Divider, Icon } from '../../../icons/styles'

const ShownInvoice = (props: any) => {
    // const {item, count, index}= props
    let { stock, invoice, opened, pendings, index, count } = props;
    const [showMenu, setShowMenu] = useState<any>(false)



    const selectListOption = (e: SyntheticEvent) => {
        e.stopPropagation();
        setShowMenu(false)
    }
    return (
        <StockCont>
            <ImageItem source={ 'd063578d-733d-4aca-8809-e18368d55184'} />
            <DescCol>
                <DescCont>
                    <TitleCont>
                        <Title>{stock.item.name}</Title>
                        <Icon onClick={(e: SyntheticEvent) => setShowMenu(true)} rot={90} >
                            <Ellipsis />
                        </Icon>
                        <MenuOptionsList show={showMenu}>
                            <Option onClick={(e: SyntheticEvent) => selectListOption(e)}>
                                <p>Edit</p>
                            </Option>
                            <Option onClick={(e: SyntheticEvent) => selectListOption(e)} >
                                <p>Info</p>
                            </Option>
                            <Option onClick={(e: SyntheticEvent) => selectListOption(e)}>
                                <p style={{ color: 'red' }}>Delete</p>
                            </Option>
                        </MenuOptionsList>
                    </TitleCont>
                    <Desc>{stock.item.description}</Desc>
                </DescCont>
                <PriceCont>
                    <Instock>{stock.quantity}</Instock>
                    <StockPrice>{formatMoney(stock.item.sellingPrice * stock.quantity)}</StockPrice>
                </PriceCont>
                {
                    index + 1 !== count && <Divider />
                }
            </DescCol>
        </StockCont>
    )
}

export default ShownInvoice