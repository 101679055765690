import { useReactiveVar } from '@apollo/client'
import React, { useEffect, useState } from 'react'
import { chatView, globalUser, openChatBox } from '../../globals'
import { Divider, Icon } from '../icons/styles'
import Contacts from './contacts'
import MessageHeader from './contacts/headers'
import Messages from './messages'
import PersonHeader from './messages/headers/person'
import {  ChatCont,  ChatSubCont } from './styles'

const ChatBox = (props: any) => {
  const {account} = props

  const chatBox = useReactiveVar(openChatBox) as boolean
  const view = useReactiveVar(chatView) 

  const showContacts = (e: any)=> {
    e.stopPropagation()
    chatView('contacts')
  }

  // console.log
  const hideContacts = (e: any)=> {
    e.stopPropagation()
    chatView('chat')
  }

  return (
    <ChatCont opened={chatBox}> {
        view === 'contacts' ?
        <MessageHeader />
        :
        <PersonHeader user={account} clickCallback={(e: any) => showContacts(e)} />
        }
          <ChatSubCont>
            <Contacts
              {...props}
              in={view === 'contacts'}
              clickCallback={(e: any) => hideContacts(e)} 
            />
            <Messages {...props} in={view === 'chat'} />
          </ChatSubCont>
      </ChatCont>
  )
}

export default ChatBox