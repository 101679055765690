import { useReactiveVar } from '@apollo/client'
import React from 'react'
import { getImageUrl } from '../../../../apollo'
import { chatUser, globalUser, openChatBox } from '../../../../globals'
import { Icon } from '../../../icons/styles'
import { ImageCont } from '../../contacts/person/image/styles'
import { Header, PersonInfo } from './styles'

const PersonHeader = (props: any) => {
  const user = useReactiveVar(chatUser) as any
  
  const chatBox = useReactiveVar(openChatBox)
  const { clickCallback } = props

  const toggleChatBox = () => {
    openChatBox(!chatBox)
  }

  return (
    <Header onClick={() => toggleChatBox()} >
      <Icon onClick={clickCallback}>
        <svg viewBox="0 0 24 24" >
          <g>
            <path d="M7.414 13l5.043 5.04-1.414 1.42L3.586 12l7.457-7.46 1.414 1.42L7.414 11H21v2H7.414z"></path>
          </g>
        </svg>
      </Icon>
      <ImageCont onClick={clickCallback}>
        <img src={user?.info.dp ? getImageUrl(user.info.dp) : 'https://i.pinimg.com/474x/a4/62/d0/a462d0625c5dddc1856ca3e14e8c9435.jpg'} alt="" />
      </ImageCont>
      <PersonInfo>
        <h6>{user?.info.name}</h6>
        <p>{user.email||user.username}</p>
      </PersonInfo>
    </Header>
  )
}

export default PersonHeader