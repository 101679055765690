import React, { useEffect, useState } from 'react'
import { Button } from './styles';
import { Loader } from '../../../../../components/loaders';
import { Icon } from '../../../../../components/icons/styles';
import { SmallCheckMark } from '../../../../../components/icons';

const SubmitBtn = (props:any) => {
    const { loading, data, title } = props;
    const [state, setState] = useState(title);
    useEffect(() => {
        if (loading) {
            setState('spin')
        } else if (data) {
            setState('done');
            setTimeout(() => {
                setState(title)
            }, 3000);
        } else {
            setState(title)
        }
    }, [data, loading])
    return (
                <Button>
                    {
                        state === 'spin' ?
                            <Loader />
                            : state === 'done' ?
                                <Icon className="check">
                                    <SmallCheckMark />
                                </Icon>
                                :
                                state
                    }
                </Button>
    )
}

export default SubmitBtn