import styled, { keyframes } from 'styled-components';
const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`
const Spinner = styled.div<any>`
    z-index: 22000;
    border-radius: 50%;
    border: 2px solid ${props => props.c || '#04eb8b'};
    transition: all;
    position: absolute;
    border-top-color: #2fae3e;
    bottom: ${props => props.b}%;
    width: ${props => props.size || '25px'};
    height: ${props => props.size || '25px'};
    animation: ${rotate} .3s linear infinite;
`
const PageSpinner = styled(Spinner)`
  width: 40px;
  height: 40px;
  border-top-color: #bbf1c8;
`
const MoreSpinner = styled(Spinner)`
  width: 18px;
  height: 18px;
  z-index: 1;
  position: relative;
`

export const Loader = (props: any) => <Spinner {...props} />
export const SpinLoader = (props: any) => <Spinner {...props} />

export const PageLoader = () => <PageSpinner />

export const LoadingMore = () => <MoreSpinner />
