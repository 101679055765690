import React, { useEffect, useState } from 'react'
import { UPLOAD_FILE } from '../../../graphql/mutations';
import { NavLink } from 'react-router-dom';
import { Loader } from '../../../components/loaders';
import { useLocation } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { getImageUrl } from '../../../apollo';
import { FileInput } from '../../inventory/components/forms/stock/styles';
import { Banner, Button, CallToActionCont, CustomerLogo, ImageCont, LinkItem, Header, Container, Glass } from './styles';
import { SAVE_ACC_INFO } from '../../../graphql/mutations/account';
import { GET_MY_ACCOUNT } from '../../../graphql/queries';
import { Account } from '../../../models/account';
const StoreHeder = (props: any) => {
    const {info:accInfo} = props
    const location = useLocation();
    const [info, setInfo] = useState(accInfo)
    const [editing, setEditing] = useState(false)
    const [photo, setPhoto] = useState('')

    useEffect(() => {
        setInfo(info)
    }, [info])
    
    const [uploadFile, { loading: uploading, error: uploadError }] = useMutation(UPLOAD_FILE, {
        onCompleted: (data: any) => {
            setInfo({
                ...info,
                [photo==='dp' ? 'dp' : 'banner']: data.uploadFile.uri
            })
        },
        onError: (error: any) => {
            console.log({ ...error });
        },
    });

    const handleFileChange = async (e: any) => {
        const file = e.target.files[0];
        if (!file) {
            return;
        } else {
            uploadFile({
                variables: {
                    file,
                },
            });
        }
    }

    const [submit, { error, loading, data }] = useMutation(SAVE_ACC_INFO, {
        update: (cache, { data: { saveAccInfo } }) => {
            let myAccount: Account = cache.readQuery({
                query: GET_MY_ACCOUNT
            }) as Account;
            setEditing(false);
            
        }
    })

    if (error) console.log({ ...error });
    if (data) console.log(data);


    const saveInfo = () => {
        const {__typename, ...i} = info
        console.log(i)
        submit({
            variables: {
                info: i
            }
        })
    }
    
  return (
   <>
          <Header>
              <NavLink style={{ position: 'absolute', left: 20, top: 20, cursor: 'pointer', zIndex: 10 }} to='/' title='Home'>
                  <LinkItem active={location.pathname === '/'}>
                      <p>HOME</p>
                  </LinkItem>
              </NavLink>
              <Banner onClick={()=>setPhoto('banner')}>
                  <ImageCont>
                        {
                          info.banner && <img src={getImageUrl(info.banner)} alt="" /> 
                        }
                      <img src={info?.dp} alt="" />
                  </ImageCont>
                       {
                        editing && 
                        <>
                          <FileInput onChange={handleFileChange} />
                          <Glass>Edit </Glass>
                        </>
                      }
                      {
                        uploading && photo === 'banner' ? <Loader />:null   
                      }
              </Banner>
              <Container>
                  <CustomerLogo onClick={() => setPhoto('dp')}>
                      {
                          (info?.dp.trim()) && <img src={getImageUrl(info?.dp)} alt="" />
                      }
                      {
                          uploading && photo === 'dp' ? <Loader />:null  
                      }
                      {
                          editing &&
                          <>
                              <FileInput onChange={handleFileChange} />
                              <Glass>Edit </Glass>
                          </>
                      }
                  </CustomerLogo>
                  <CallToActionCont>
                    {
                          editing && <p onClick={()=>setEditing(false)} className='cancel'>Cancel</p>
                    }
                    {
                    !editing ?
                    <Button onClick={() => setEditing(true)}>Edit</Button>
                    :
                    <Button onClick={() => saveInfo()}>
                        {loading  ? <Loader /> : "Save"}
                    </Button>
                    }
                  </CallToActionCont>
              </Container>
             
          </Header>
   </>
  )
}

export default StoreHeder