import { useMutation, useReactiveVar } from '@apollo/client';
import React, { SyntheticEvent, useState } from 'react'
import { chatUser } from '../../../../globals';
import { SEND_MESSAGE } from '../../../../graphql/mutations';
import { GET_CHAT } from '../../../../graphql/queries';
import { Form, FormCont, MessageInput } from './styles'

const ChatForm = (props: any) => {
  const { chatId, reciever, scrollCallback } = props;

  const curUser: any  = useReactiveVar(chatUser)

  // console.log(reciever)

  const [message, setMessage] = useState('')


  const [send, { error, loading, data }] = useMutation(SEND_MESSAGE, {
    update: (cache, { data: { sendMessage } }) => {
      setMessage('');
      scrollCallback()

      let chats: any = cache.readQuery({
        query: GET_CHAT
      });

      console.log(chats)

      // cache.writeQuery({
      //   query: GET_CHAT,
      //   data: {
          
      //   }
      // })
    }
  })

  const handleChange = (e: any) => {
    e.persist();
    setMessage(e.target.value)
  }

  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault()
    if(message.trim().length) {
      console.log(`userID: ${curUser?._id}`)
      send({
        variables: {
          rcvId: curUser?._id,
          message,
        }
      })
    }
  }

  return (
      <FormCont>
        <Form onSubmit={(e: SyntheticEvent) => handleSubmit(e)}>
                <MessageInput 
                  name='message'
                  value={message}
                  placeholder='Message...' 
                  onChange={(e:SyntheticEvent)=>handleChange(e)}
                  onClick={(e: SyntheticEvent) => e.stopPropagation()}
          /> {
            message && <button>Send</button>
          }
          
        </Form>
      </FormCont>
  )
}

export default ChatForm