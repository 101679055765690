import styled from "styled-components";
export const Container = styled.div<any>`
  height: 400px;
  width: 100%;
  position: absolute;
  overflow: visible;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  border: 1px solid whitesmoke;
  background: white;
  background-color: rgb(255, 255, 255);
  border: 1px solid rgba(71, 75, 76, 0.055);
  display: ${props => props.show ? 'flex' : 'none'};
  box-shadow: rgba(196, 195, 195, 0.216) 0px 5px 25px;
`;
export const HeaderCont = styled.header`
    height: 40px;
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
     h6 {
        font-size: 14px;
        margin-bottom: 0px;
        font-weight: 700;
        display: flex;
        align-items: center;
        .icon {
          height: 16px;
          width: 16px;
          margin-right: 5px;
        }
    }
    .icon {
        height: 10px;
        width: 10px;
        cursor: pointer;
        svg {
          stroke: grey;
        }
    }
`
export const SwitchCont = styled.div<any>`
  height: 100%;
  width: 100%;
  position: relative;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
`;

export const InputsCont = styled.div<any>`
  height: 100%;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  background: white;
  z-index: 20;
  background-color: rgb(255, 255, 255);
  position: absolute;
  transition: all .15s ease-in-out;
  left: ${props => props.in ? 0 : 110}%;
`;
export const DpCont = styled.div<any>`
  height: 100%;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  background: white;
  background-color: rgb(255, 255, 255);
  position: absolute;
  transition: all .15s ease-in-out;
  left: ${props => props.in ? 0 : -110}%;
`;

export const DpImageCont = styled.div<any>`
  width: 220;
  height: 220px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 20px;
  cursor: pointer;
  margin: 0px auto;
  h4 {
    text-align: center;
    max-width: 100%;
    margin-bottom: 5px;
    margin-top: 10px;
    font-size: 16px;
    font-weight: 600;
  }
  .username,.role {
    margin-top: -4px;
    margin-bottom: 3px;
    max-width: 100%;
    text-align: center;
    color: rgb(83, 100, 113);
    font-size: 12px;
  }
  .role {
    font-size: 13px;
    /* margin-bottom: 10px !important; */
  }
`

export const ContactsCont = styled.div`
  width: 100%;
  height: 40px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 5px;
`;
export const ContactsItem = styled.div`
  height: 100%;
  width: auto;
  padding: 0px 3px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  .icon {
    width: 14px;
    height: 14px;
    margin-right: 2px;
  }
  #date {
    width: 16px;
    height: 16px;
  }
  p {
    font-size: 13px;
    margin-bottom: 0px;
    color: rgb(83, 100, 113);
    span {
      color: rgb(83, 100, 113);
      font-weight: normal;
    }
  }
  &:first-child {
    /* margin-right: 5px; */
  }
`;

export const ImageCont = styled.div`
  width:180px;
  height: 180px;
  margin: auto;
  border: none;
  position: relative;
  border-radius: 50%;
  background: #e3e3e3;
  img {
    height: 100%;
    width: 100%;
    border-radius: inherit;
    object-fit: cover;
    border: none;
    mix-blend-mode: multiply;
    filter: contrast(1);
  }
`;


export const FormItem = styled.form<any>`
  height: 420px;
  width: 260px;
  right: 0px;
  padding: 0px 10px 10px 10px;
  top: -100px;
  z-index: 777777200;
  border-radius: 10px;
  position: absolute;
  overflow: hidden;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  border: 1px solid whitesmoke;
  background: white;
  background-color: rgb(255, 255, 255);
  border: 1px solid rgba(71, 75, 76, 0.055);
  /* display: flex; */
  display: ${props => props.show ? 'flex' : 'none'};
  box-shadow: rgba(196, 195, 195, 0.216) 0px 5px 25px;  
`;
export const FormGroupCont = styled.div<any>`
  margin: 8px 0px;
  position: relative;
  border-radius: 8px;
  width: ${(props) => props.w || 100}%;
  border: 1px solid rgb(0 0 0 / 6%);
`;

export const FormGroup:any = styled.div<any>`
  display: grid;
  position: relative;
  padding: 5px 6px;
  grid-template-columns: 92% 8%;
  align-items: center;
  height: ${(props) => props.h || 45}px;
  width: ${(props) => props.w || 100}%;
  background: ${(props) => (props.focused ? "#eeeeee5c" : "initial")};
  border-bottom-left-radius: ${(props) => (props.top ? "0px" : "6px")};
  border-bottom-right-radius: ${(props) => (props.top ? "0px" : "6px")};
  border-bottom: ${(props) => (props.top ? "1px" : "0px")} solid rgb(0 0 0 / 6%);
  border-right: ${(props) => (props.left ? "1px" : "0px")} solid #d3d3d35c;
  /* border: ${(props) => (!props.focused ? 1.5: 0 )}px solid lightgreen; */
  /* border-bottom-color: ${(props) => (props.top ? "#e6e1e1" : "white")}; */
  .icon {
    width: 8px;
    height: 8px;
    cursor: pointer;
    position: relative;
    :hover {
        background: whitesmoke;
    }
  }
  #pwd {
     width: 14px;
    height: 14px;
  }
`;

export const ErrorMessage = styled.p`
font-size: 11px;
color: #ff0000b9;
width: 100%;
margin-bottom: 0px;
text-align: center;
`;

