import { gql } from "@apollo/client";

export const GET_CHAT = gql`
    query getChat($rcvId: String) {
        chat(rcvId: $rcvId) {
            _id
            participants {
                _id
                info {
                    name
                    dp
                    accType
                    storeId
                }
            }
            messages {
                _id
                sender {
                    _id
                    info {
                        name
                        dp
                    }
                }
                message
                timestamp
            }
        }
    }
`
export const GET_CONTACTS = gql`
    query getContacts {
        contacts {
             _id
             participants {
                _id
                info {
                    name
                    dp
                    role
                    accType
                    storeId
                }
                email
                username
            }
            messages {
                sender {
                    _id
                     info {
                        name
                        dp
                  }
                }
                message
            }
        }
    }
`
