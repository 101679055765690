import { genTransId } from "../utils";
import { Account, Address, CreditCard } from "./account";
import { CartItem } from "./product";

export interface Shipping {
    contact: {
        address: Address,
        phone: string,
    },
    tracking: {
        time: {
            from: Date,
            to: Date,
        },
        routes: string[],
    },
    fee: number
}
export interface Payment {
    method: {
        currency: string,
        via: string,
    },
    details?: any
}
export interface Order {
    stocks: CartItem[],
    status: string,
    payment: Payment,
    shipping: Shipping,
    buyer?: any,
    modified?: any,
    _id?: string,
    tid?: string,
    createdAt?: Date,
    updatedAt?: Date
}

export const initOrder: Order = {
    stocks: [],
    status: 'pending',
    payment: {
        method: {
            currency: 'NGN',
            via: 'direct',
        },
    },
    shipping: {
        contact: {
            address: {
                street: '',
                city: '',
                state: '',
                zipCode: ''
            },
            phone: '',
        },
        tracking: {
            time: {
                from: new Date(),
                to: new Date(),
            },
            routes: [],
        },
        fee: 0,
    },
    tid: genTransId()
}