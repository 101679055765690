import styled from "styled-components";

export const MobDetailsCont = styled.div`
 width: 100%;
 display: none;
 position: relative;
    @media (max-width: 420px) {
     display: block;
  }
`

export const Sections = styled.section`
 width: 100%;
 position: relative;
background: #e4e4e4;
top: 20px;
`

export const MobDetailsContHeader = styled.div`
 width: 100%;
 height: 60px;
 background: #aaf8aa;
 position:absolute;
 top: -20px;
 left: 0;
 border-radius: 22px 22px 0px 0px;
 padding: 5px 10px;
 a {
  color: #131212;
  font-size: 12px;
  font-weight: 500;
 }
`
export const DetailsBody = styled.div`
 width: 100%;
  top: 15px;
  position: relative;
  padding: 0px 5px;
 border-radius: 22px 22px 0px 0px;
  background: white;
  .prod--name {
    font-size: 14px;
    color: black;
    font-weight: 500;
    margin-bottom: 0px;
  }
`