import { ReactElement, SyntheticEvent, useEffect, useState } from "react";
import StockImage from "./image";
import { Button, Desc, DesCont, Input, InputCont, Item, ItemCont, QtyInputCont, SellingPriceInput, SubTotal } from "./styles";

import { cart } from "../../../../globals";
import { formatMoney } from "../../../../utils";
import { Divider } from "../../../icons/styles";
import { CartItem } from "../../../../models";

export function ListItem(props: any): ReactElement {
    const [stock, setStock] = useState(props.stock)
    const { stock: stockItem, isLastItem } = props;

    const re = /^[0-9\b]+$/;


    const handleKeyDown = (event:any) => {
        // Allow: backspace, delete, tab, escape, enter
        if ([46, 8, 9, 27, 13].includes(event.keyCode) ||
            // Allow: Ctrl+A
            (event.keyCode === 65 && event.ctrlKey === true) ||
            // Allow: Ctrl+C
            (event.keyCode === 67 && event.ctrlKey === true) ||
            // Allow: Ctrl+X
            (event.keyCode === 88 && event.ctrlKey === true) ||
            // Allow: home, end, left, right
            (event.keyCode >= 35 && event.keyCode <= 39)) {
            // Let it happen, don't do anything
            return;
        }
        // Ensure that it is a number and stop the keypress
        if ((event.shiftKey || (event.keyCode < 48 || event.keyCode > 57)) && (event.keyCode < 96 || event.keyCode > 105)) {
            event.preventDefault();
        }
    };

    useEffect(() => {
      setStock(props.stock)
    }, [props.stock])

    const handleQuantityChange = (e: any, action: string) => {
        e.persist();
        cart(
            {
                ...cart(),
                stocks: cart().stocks.map((i: CartItem) => {
                    if(i.item._id === stock.item._id ) {
                        return {
                            ...i,
                            quantity: action === '+' ? i.quantity + 1 : i.quantity - 1
                        }
                    }
                    return { ...i }
                }) 
            }
        )
    }

    const handlePriceChange = (e: any) => {
        // if(!re.test(e.target.value)) {
        //     return
        // }
        e.persist();
        cart(
            {
                ...cart(),
                stocks: cart().stocks.map((i: CartItem) => {
                    if(i._id === stock._id ) {
                        return {
                            ...i,
                            item: {
                                ...i.item,
                                sellingPrice: +e.target.value
                            }
                        }
                    }
                    return { ...i }
                })
            }
        )
    }

    const removeItem = () => {
        cart({
            ...cart(),
            stocks: cart().stocks.filter((i: CartItem) =>i.item._id !== stock.item._id)
        })
    }

    return (
        <ItemCont>
            <Item>
                <StockImage 
                    removeStockCallback={removeItem} 
                    source={stock.item?.mediaUrls[0]} 
                />
                <DesCont>
                    <Desc>
                        <div className="desc--items">
                            <p className="desc" title={stock.item.name}>
                                {stock.item.name}
                            </p>
                            <p className="sub--desc" style={{ color: 'grey' }}>
                                {stock.item.description}
                            </p>
                            <p>
                                <SellingPriceInput
                                    name='sellingPrice'
                                    placeholder="Selling price"
                                    value={stock.item.sellingPrice}
                                    onKeyDown={(e: any) =>handleKeyDown(e)}
                                    onChange={(e:SyntheticEvent) => handlePriceChange(e)}
                                 />
                            </p>
                        </div>
                    </Desc>
                    <QtyInputCont>
                        <InputCont>
                            <Button onClick={(e: any) => handleQuantityChange(e, '-')}>
                                -
                            </Button>
                            <Input 
                                name='quantity'
                                value={stock.quantity} 
                            />
                            <Button onClick={(e: SyntheticEvent) => handleQuantityChange(e, '+')}>
                                +
                            </Button>
                        </InputCont>
                        <SubTotal >
                            {
                                formatMoney(stock.item.sellingPrice * stock.quantity)
                            }
                        </SubTotal>
                    </QtyInputCont>
                    <Divider w={isLastItem ? 88 : 73} l={isLastItem ? 'auto' : '67px'} />
                </DesCont>
                
            </Item>
        </ItemCont>
    )
}