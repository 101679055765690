import { useReactiveVar } from '@apollo/client'
import React from 'react'
import { showInventoryModal, tableState } from '../../globals'
import StockForm from '../../pages/inventory/components/forms/stock'
import StockDetails from '../../pages/inventory/components/stockDetails'
import { FormContainer, StockModalCont } from './styles'


const StockModal = () => {
    const show = useReactiveVar(showInventoryModal)
    const {state, item} = useReactiveVar(tableState)
    const close = (e: Event)=>{
        e.stopPropagation()
        tableState({
            item: null,
            state: ''
        })
        showInventoryModal(false)
    }
    return (
        <StockModalCont onClick={(e: Event) => close(e)} show={show}>
            <FormContainer>
                { 
                    state === 'editing' ? <StockForm stock={item} /> : state === 'info' ? <StockDetails stock={item} /> : null
                }
            </FormContainer>
        </StockModalCont>
    )
}


export default StockModal