import { gql } from "@apollo/client";
export const CHAT_SUBSCRIPTION = gql`
  subscription OnMessageAdded($userId: String) {
    newMessage(userId: $userId)  {
            _id
            participants {
                    _id
                    info {
                    dp
                    name
                }
            }
            messages {
                sender {
                    _id
                    info {
                        dp
                        name
                    }
                }
                message
                timestamp
            }
    }   
  }
`;