import { gql } from '@apollo/client'

 export const SEND_MESSAGE = gql`
    mutation sendMessage($rcvId: String, $message: String) {
        sendMessage(rcvId: $rcvId, message: $message) {
            _id
            participants {
                    info {
                    dp
                    name
                }
            }
            messages {
                sender {
                    info {
                        dp
                        name
                    }
                }
                message
                timestamp
            }
        }
    }
`