import React from 'react'
// import { Cancel } from '../../../../../../components/icons'
// import { Icon } from '../../../../../../components/icons/styles'
import { ImageCont } from './styles'
// import { Icon } from '../../../../icons/styles'
// import { Cancel } from '../../../../icons'
import { getImageUrl } from '../../../../../apollo'
import { Icon } from '../../../../icons/styles'
import { Cancel } from '../../../../icons'
// import { getImageUrl } from '../../../../../apollo'

function StockImage(props: any) {
    const { source, expiry, expiryStatus, removeStockCallback } = props

    return (
        <ImageCont>
            <Icon onClick={removeStockCallback}>
                <Cancel />
            </Icon>
            {source ? <img src={getImageUrl(source)} alt="" /> : null}
        </ImageCont>
    )
}

export default StockImage