import React from 'react'
import { Body, Content, ListItemsCont, Total } from './styles';
import { ListItem } from './item';
import { CartItem } from '../../../types/model';
import { formatMoney, getCartTotal } from '../../../utils';
import { Divider } from '../../icons/styles';

const Cart = (props:any) => {
    const {checkOut, invoice} = props;
  return (
      <Body in={checkOut}>
          {
              <ListItemsCont>
                  <Content>{
                      invoice.stocks.map((item: CartItem) =>
                          <ListItem
                              key={item._id}
                              stock={item}
                          />
                      )
                  }
                  </Content>
                  <Total>
                      <p>TOTAL:</p>
                      <p style={{ fontSize: 16 }}>{formatMoney(getCartTotal(invoice.stocks))}</p>
                      <Divider w={90} />
                  </Total>
              </ListItemsCont>
          }
      </Body>
  )
}

export default Cart