import { getImageUrl } from "../../../../../apollo"
import { Like } from "../../../../../components/icons"
import { Icon } from "../../../../../components/icons/styles"
import { formatMoney, roundAmount } from "../../../../../utils"
import { Star } from "../../icons"
import { LikeCont, PriceCont, RatingsCont, StatsCont } from "../youmaylike/styles"
import { CardCont, Description, ImageCont } from "./styles"



const RecomendedCard = (props: any) => {
    const { item } = props
    return (
        <CardCont>
            <LikeCont>
                <Icon size={18}>
                    <Like />
                </Icon>
                <p>{0}</p>
            </LikeCont>
            <ImageCont>
                <img src={getImageUrl(item.mediaUrls[0])} alt="" />
            </ImageCont>
            <Description>
                <PriceCont>
                    <h6>
                        <sub>N</sub>{formatMoney(item.sellingPrice)}<sub>.07</sub>
                    </h6>
                </PriceCont>
                <p className="discount">{item.name}</p>
                {/* <StatsCont>
                    <p>502 Sold</p>
                    <RatingsCont>
                        <Icon>
                            <Star />
                        </Icon>
                        <p>4.7</p>
                    </RatingsCont>
                </StatsCont> */}
                <p className="desc">{item.description}</p>
            </Description>
        </CardCont>
    )
}

export default RecomendedCard