import { gql } from "@apollo/client";

export const GET_MY_CART = gql`
    query getMyCart {
        myCart {
            items {
                product {
                    _id
                    name
                    description
                    sellingPrice
                    
                    mediaUrls
                    owner
                    warningCount
                    expiry
                    expiryWarning
                    createdAt
                    updatedAt
                }
                quantity
            }
            account {
                _id
                phone
                info {
                    name
                }
                addresses {
                    city
                    street
                    state
                    zipCode
                    country
                }
                # creditCards {
                #     cvv
                #     cardNumber
                #     expiryMonthYear
                #     accountHolderName
                # }
                createdAt
            }
        }
    }
`