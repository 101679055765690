import styled from "styled-components";

export const ModalContainer = styled.div<any>`
  position: fixed;
  height: 100vh;
  width: 100vw;
  z-index: 11;
  top: 0;
  left: 0;
  background-color: rgb(0 0 0 / 27%);
  display: ${(props) => (props.opened ? "block" : "none")};
`;
export const StockModalCont = styled.div<any>`
  position: fixed;
  height: 100vh;
  width: 100vw;
  z-index: 25000000;
  top: 0;
  left: 0;
  padding: 0px;
  background-color: rgb(0 0 0 / 17%);
  display: ${(props) => (props.show ? "block" : "none")};
  @media (max-width: 768px) {
    padding-top: 74px;
  }
`;
export const CheckOutModalCont = styled.div<any>`
  top: 0;
  left: 0;
  z-index: 2500000000;
  height: 100vh;
  width: 100vw;
  position: fixed;
  background-color: rgb(0 0 0 / 17%);
  display: ${(props) => (props.opened ? "block" : "none")};
`;
export const MainCheckOutModalCont = styled.div<any>`
  top: 0;
  left: 0;
  z-index: 25;
  height: 100vh;
  width: 100vw;
  position: fixed;
  background-color: rgb(0 0 0 / 17%);
  display: ${(props) => (props.opened ? "block" : "none")};
`;

export const StockEditModalCont = styled.div<any>`
  position: fixed;
  height: 100vh;
  width: 100vw;
  z-index: 25;
  top: 0;
  left: 0;
  padding: 0px;
  background-color: rgb(0 0 0 / 17%);
  display: ${(props) => (props.opened ? "block" : "none")};
`;
export const StaffModalCont = styled.div<any>`
  position: fixed;
  height: 100vh;
  width: 100vw;
  z-index: 25;
  top: 0;
  left: 0;
  padding: 0px;
  background-color: rgb(0 0 0 / 17%);
  display: ${(props) => (props.opened ? "block" : "none")};
`;
export const Container = styled.div.attrs({className:'container'})<any>`
  display: grid;
  grid-template-columns: 20% 80%;
  column-gap: 10px;
  position: relative;
  padding-top: 120px;
  overflow-x: hidden;
`;
export const FormContainer = styled.div.attrs({className:'container'})<any>`
  height: 100vh;
  max-width: 1000px;
  position: relative;
  padding-top: 0px;
  overflow-x: hidden;
  overflow: hidden;
  @media(max-width: 768px) {
    border-radius: 20px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    padding: 0px;
    background: whitesmoke;
    height: 100%;
  }
`;
export const AccModalCont = styled.div<any>`
  top: 0;
  left: 0;
  z-index: 2;
  width: 100vw;
  height: 100vh;
  position: fixed;
  background-color: rgb(0 0 0 / 1.7%);
  display: ${(props) => (props.opened ? "block" : "none")};
`;
