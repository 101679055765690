import styled from "styled-components"

export const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: 40px 0px 20px 0px;
    position: relative;
    @media (max-width: 420px) {
      margin: 20px 0px 20px 0px;
      padding: 0px 5px;
    }
`

export const HeadeItems = styled.ul`
    width: 100%;
    height: 100%;
    padding: 0%;
    display: flex;
    align-items: center;
    justify-content: space-between;
`
export const Item = styled.li`
    /* width: 100%; */
    height: 100%;
    display: flex;
    align-items: center;
    h6 {
        font-size: 16px;
        font-weight: 700;
    }
    @media (max-width: 420px) {
      h6 {
        font-size: 14px;
        font-weight: 700;
     }
    }
`
export const TableCont = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-top: 10px;
    position: relative;
    h6 {
        font-weight: 700;
        font-size: 22px;
    }
`
export const SpecTable = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    flex-wrap: wrap;
`
export const SpecCont = styled.div`
    width: 50%;
    height:50px;
    display: flex;
    position: relative;
`
export const Spec = styled.div<any>`
    width: 50%;
    height:50px;
    padding: 0px 10px;
    display: flex;
    align-items: center;
    background: ${props => props.label ? '#f5f5f5': 'initial'};
    p {
        margin-bottom: 0px;
        font-size: 14px;
    }
`