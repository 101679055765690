
import { setContext } from '@apollo/client/link/context';
import { createUploadLink } from 'apollo-upload-client'
import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition } from '@apollo/client/utilities';
import { ApolloClient, InMemoryCache, NormalizedCacheObject, split } from '@apollo/client'
import { inDevelopment } from '../utils';
import { cart, showCheckOutModal } from '../globals';


// export const API_HOST = process.env.REACT_APP_API_HOST
// export const API_HOST = inDevelopment ? 'hv249tps-4000.euw.devtunnels.ms' : 'api.stosyst.com'
export const API_HOST = inDevelopment ? 'localhost:4000' : 'api.stosyst.com'
// export const API_URI = inDevelopment ? `https://${API_HOST}/graphql` : `https://${API_HOST}`
export const API_URI = inDevelopment ? `http://${API_HOST}/graphql` : `https://${API_HOST}`

export const getImageUrl = (name: string) =>  `https://nextoma-bucket.s3.us-east-2.amazonaws.com/${name}`;

// const SOCKET_URI = inDevelopment ? `wss://${API_HOST}` : `wss://${API_HOST}`
const SOCKET_URI = inDevelopment ? `ws://${API_HOST}` : `wss://${API_HOST}`

const wsLink = new WebSocketLink({
    uri: SOCKET_URI,
    options: {
        reconnect: true,
        timeout: 10000
    }
});

const authLink = setContext((_, { headers }) => {
    const token = localStorage.getItem('token');
    return {
        headers: {
            ...headers,
            authorization: token ??  "",
        }
    }
});

const httpLink = createUploadLink({
    uri: API_URI,
});

const splitLink = split(
    ({ query }) => {
        const definition = getMainDefinition(query);
        return (
            definition.kind === 'OperationDefinition' &&
            definition.operation === 'subscription'
        );
    },
    wsLink,
    httpLink
);

 const apollo: ApolloClient<NormalizedCacheObject> = new ApolloClient({
    link: authLink.concat(splitLink),
    cache: new InMemoryCache({
        typePolicies: {
            Query: {
                fields: {
                    products: {
                        keyArgs: false,
                        merge(existing, incoming, { args }) {
                            let merged = existing ? existing.slice(0) : [];
                            merged = (args?.offset > 0) ? [...merged, ...incoming] : incoming
                            return merged
                        }
                    },
                    goods: {
                        keyArgs: false,
                        merge(existing, incoming, { args }) {
                            let merged = existing ? existing.slice(0) : [];
                            merged = (args?.offset > 0) ? [...merged, ...incoming] : incoming
                            return merged
                        }
                    },
                    shownStocks: {
                        keyArgs: false,
                        merge(existing, incoming, { args }) {
                            let merged = existing ? existing.slice(0) : [];
                            merged = (args?.offset > 0) ? [...merged, ...incoming] : incoming
                            return merged
                        }
                    },
                    invoices: {
                        keyArgs: false,
                        merge(existing, incoming, { args }) {
                            let merged: any = existing ? existing.slice(0) : [];
                            merged = (args?.offset > 0) ? [...merged, ...incoming] : incoming
                            return merged
                        }
                    },
                    shownAggregates: {
                        keyArgs: false,
                        merge(existing, incoming, { args }) {
                            let merged: any = existing ? existing.slice(0) : [];
                            merged = (args?.offset > 0) ? [...merged, ...incoming] : incoming
                            return merged
                        }
                    },
                    shownInvoices: {
                        keyArgs: false,
                        merge(existing, incoming, { args }) {
                            let merged: any = existing ? existing.slice(0) : [];
                            merged = (args?.offset > 0) ? [...merged, ...incoming] : incoming
                            return merged
                        }
                    },
                    expenses: {
                        keyArgs: false,
                        merge(existing, incoming, { args }) {
                            let merged = existing ? existing.slice(0) : [];
                            merged = (args?.offset > 0) ? [...merged, ...incoming] : incoming
                            return merged
                        }
                    },
                    market: {
                        keyArgs: false,
                        merge(existing, incoming, { args }) {
                            let merged = existing ? existing.slice(0) : [];
                            merged = (args?.offset > 0) ? [...merged, ...incoming] : incoming
                            return merged
                        }
                    },
                    cart: {
                        read() {
                            return cart();
                        }
                    },
                    showCheckOutModal: {
                        read() {
                            return showCheckOutModal();
                        }
                    }
                }
            },
        }
    })
})
export default apollo
