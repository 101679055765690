import styled from "styled-components"

export const ReceiptCont = styled.div`
  position: absolute;
  width: 100%;
  top: 40%;
  left: 150%;
  background: white;
  ul {
    list-style: none;
    border: none;
    padding: 0px 5px;
    margin-bottom: 5px;
    li {
      height: 16px;
      font-size: 12px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: relative;
    }
    @media print {
         body {
             -webkit-print-color-adjust: exact; 
            } 
        } 
  }
  .remark {
    font-size: 11px;
    padding: 0px 10px;
    height: 70px;
    display: flex;
    position: relative;
    flex-direction: column;
    p {
        margin-bottom: 0px;
        text-align: center;
        width: 100%;
    }
    p:last-child {
        position: absolute;
        bottom: 3px;
    }

  }
`
export const PrintBtn = styled.button`
    border: none;
    outline: none;
    box-shadow: none;
    height: 33px;
    width: 100px;
    font-size: 12px;
    color: white;
    background: black;
    border-radius: 0px;
    cursor: pointer;
    bottom: 10px;
    right: 180%;
    position: absolute;
    font-weight: bold; 
`
export const ReceiptHeader = styled.header`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    padding: 0px 5px;
    p {
        margin-bottom: 0px;
        font-size: 12px;
        text-align: center;
        width: 100%;
        display: flex;
        justify-content: space-between;
    }
    .address, .phone {
        display: flex;
        justify-content: center;
    }
    h6 {
        margin-bottom: 0px;
    }
    #sub--head {
        width: 100%;
        font-size: 11px;
        padding: 0px;
    }
    #date-id {
        width: 100%;
        font-size: 11px;
        display: flex;
        justify-content: space-between;
    }
    #method {
        font-size: 11px;
        span {
            font-size: 10px;
        }
    }
`
export const ReceiptTotal = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    /* align-items: center;
    justify-content: space-between; */
    p{
        width: 100%;
        display: flex;
        margin-bottom: 0px;
        align-items: center;
        justify-content: space-between;
        font-size: 12px;
    }
   
    padding: 0px 10px;
    margin-bottom: 10px;
    span:last-child {
        font-weight: 500;
    }
`