import styled from "styled-components";

export const NavCont = styled.nav<any>`
    top: 0;
    left: 0;
    transition: all .15s ease-in-out;
    height: 100vh;
    z-index: 7777712;
    position: fixed;
    background: #f3f2f2;
    overflow: hidden;
    position: relative;
    width: ${props => props.opened ? 280 : 0}px;
    .sign {
        position: absolute;
        bottom: 0;
        left: 0;
        margin-bottom: 0px;
    }

`
export const NavHeaderCont = styled.div`
    width: 100%;
    height: 200px;
    background: #dfdfdf;
`
export const LinkItemsCont = styled.ul`
    width: 100%;
    /* height: 100%; */
    padding: 20px 10px;
    display: flex;
    list-style: none;
    flex-direction: column;
    a {
        text-decoration: none;
        color: inherit;
    }
`
export const LinkItem = styled.li`
    width: 100%;
    height: 35px;
    padding: 0px 10px;
    border-radius: 8px;
    /* display: flex;
    align-items: center; */
    font-size: 14px;
    position: relative;
    a,p {
        width: 100%;
        height: 100%;
        margin-bottom: 0px;
        position: absolute;
        display: grid;
        grid-template-columns: 30px 1fr;
        align-items: center;
        h6 {
        font-size: 14px;
        font-weight: 500;
        margin-bottom: 0px;
        width: 100%;
        text-align: left;
       }
    }

    cursor: pointer;
    :hover {
          background: whitesmoke; 
    }
    &:nth-child(3) {
        background: whitesmoke; 
    }
    .icon {
        width: 14px;
        height: 14px;
        margin-right: 10px;
        z-index: 7777712;
        position: relative;
    }
    #sales {
        height: 16px;
        width: 16px;
    }
    #payment {
        height: 18px;
         width: 18px;
    }
    #exp {
        height: 18px;
         width: 18px;
    }
    #orders {
        height: 18px;
         width: 18px;
    }
    #alt {
        height: 15px;
         width: 15px;
    }
    #account {
        height: 20px;
         width: 20px;
         svg {
            stroke: #141313;
         }
    }
`
export const Modal = styled.div<any>`
  top: 0;
  left: 0;
  padding: 0px;
  height: 100vh;
  position: fixed;
  display: block;
  z-index: 7777711;
  /* transition: all .15s ease-in-out; */
  background-color: rgb(0 0 0 / 40%);
  width: ${(props) => (props.opened ? 100 : 0)}vw;
`;
