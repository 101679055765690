// import React from 'react'
import { Plus } from '../../../../../components/icons'
import { Icon } from '../../../../../components/icons/styles'
import { showStaffModal } from '../../../../../globals'
import { AddButtonCont } from './styles'

const AddBtn = () => {
  return (
    <AddButtonCont onClick={() => showStaffModal(true)} title="Add Staff">
      <Icon>
          <Plus />
      </Icon>
    </AddButtonCont>
  )
}

// export const AddBtn = () => {
//   // const cartList = useReactiveVar(cart)
//   // const { stocks } = cartList;
//   // const clear = (e: any) => {
//   //     e.stopPropagation()
//   //     cart({ ...initInvoice })
//   // }
//   const showEditForm = () => {
//     // tableState({
//     //     item: null,
//     //     state: 'editing'
//     // })
//     showStaffModal(true)
//   }
//   return (

//     <AddButtonCont onClick={showEditForm}>

//       <Icon>
//         <Plus />
//       </Icon>

//     </AddButtonCont>
//   )
// }

export default AddBtn