import styled from "styled-components";
export const Header = styled.h6`
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 5px;
  width: 100%;
  position: relative;
  span {
    display: inline-block;
    position: absolute;
    right: 0px;
    .icon {
      height: 18px;
      width: 18px;
      
    }
  }
`;
export const TableCont = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 10px;
  position: relative;
`;
export const SpecTable = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-wrap: wrap;
`;
export const SpecCont = styled.div`
  width: 100%;
  height: 40px;
  display: flex;
  position: relative;
`;
export const Spec = styled.div<any>`
  width: 50%;
  height: 40px;
  padding: 0px;
  display: flex;
  align-items: center;
  p {
    width: 100%;
    margin-bottom: 0px;
    font-size: 12px;
    text-align: ${(props) => (props.label ? "left" : "right")};
    color: ${(props) => (props.label ? "" : "grey")};
  }
`;
