import { useMutation, useReactiveVar } from "@apollo/client"
import { useState } from "react"
import { Cancel, Drop } from "../../../../../components/icons"
import { Icon } from "../../../../../components/icons/styles"
import { showInventoryModal, tableState } from "../../../../../globals"
import { SAVE_EXPENSE } from "../../../../../graphql/mutations"
import { queryOptions } from "../../../../../types/defaults"
import { Expense } from "../../../../../types/model"
import { stripTypename } from "../../../../../utils"
import { updateExpCache } from "../../../../../utils/caches"
import Button, { SaveButton } from "../../buttons"
import { Input } from "../inputs"
import { Double } from "../styles"
import { BtnCont, Column, ColumnCont, ExpenseFormItem, FormGroup, FormGroupCont, HeaderCont } from "./styles"
import { GET_EXPENSES } from "../../../../../graphql/queries/expenses"




const ExpenseForm = (props: any) => {
    let initExpense: Expense = {
        name: '',
        desc: '',
        spender: '',
        amount: 0,
        modifier: ''
    }
    
    let [expense, setExpense] = useState(props.expense || initExpense);
    // const { group, query } = useReactiveVar(expenseCriteria)
    const queries = useReactiveVar(queryOptions)

    const [focused, setFocused] = useState('')
    const [cancel, setCancel] = useState('')

    const [saveExpense, { error, loading, data}] = useMutation(SAVE_EXPENSE, {
        update: (cache, { data: { saveExpense: newExpense } }) => {
            const prevExp: any = cache.readQuery({
                query: GET_EXPENSES,
                variables: {
                    ...queries,
                    group: 'date'
                }
            });
            // console.log(`prev: ${prevExp}`)
            cache.writeQuery({
                query: GET_EXPENSES,
                data: {
                    expenses: updateExpCache(prevExp?.expenses, newExpense, queries.group),
                },
            });
            // updateExpenseCache(cache, group, query, result.saveExpense)
            //showFeedBack()
        }
    });

    if (error) {
        console.log({ ...error })
    }

    const focus = (name: string) => {
        setFocused(name)
    }
    const mouseEnter = (name: string) => {
        setCancel(name);
    }
    const mouseLeave = (name: string) => {
        setCancel(name);
    }

    const handleClear = (name: string) => {
        setExpense({
            ...expense,
            [name]: ''
        })
    }

    const closeDetails = () => {
        tableState({
            state: '',
            item: null
        })
        showInventoryModal(false)
    }

    const handleChange = (e: any) => {
        e.persist();
        const { target: { name, value } } = e
        const isAnumber = /^[0-9\b]+$/;

        if (((name === 'amount') && isAnumber.test(value)) || value === "") {
            setExpense({
                ...expense,
                [name]: value
            })
        } else if (name === 'name' || name === 'desc' || name === 'spender') {
            setExpense({
                ...expense,
                [name]: value
            })
        }
    }

    const handleSubmit = (e: React.SyntheticEvent) => {
        e.preventDefault();
        if (expense._id) {
            const { added, modified, ...exp } = stripTypename(expense)
            const { name, desc, spender, amount } = exp;
            expense = {
                ...exp,
                name: name.trim(),
                desc: desc.trim(),
                spender: spender.trim(),
                amount: +amount,
                added: stripTypename(added),
                modified: stripTypename(modified)
            }
        } else {
            const { name, desc, spender, amount } = expense;
            expense = {
                ...expense,
                name: name.trim(),
                desc: desc.trim(),
                amount: +amount,
                spender: spender.trim(),
            }
        }
        saveExpense({
            variables: {
                expense
            }
        });
    }


    return (
        <ExpenseFormItem
            noValidate={true}
            {...props}
            id={expense?._id}
            onClick={(e: Event) => e.stopPropagation()}
            onSubmit={(e: React.SyntheticEvent) => handleSubmit(e)}
        >
            <HeaderCont>
                <h6>New expense</h6>
                <SaveButton 
                    data={data}
                    loading={loading}
                    title={expense?._id ? 'Update' : 'Add'}
                    disabled={
                        !expense.name 
                    }
                />
            </HeaderCont>
            <ColumnCont>
                <Column>
                    <h6>EXPENSE</h6>
                    <FormGroupCont className="fgroup">
                        <FormGroup onMouseLeave={() => mouseLeave('name')} onMouseEnter={() => mouseEnter('name')} top>
                            <Input
                                required
                                name="name"
                                value={expense.name}
                                label='Title'
                                placeholder='Logistics'
                                focused={focused === 'name'}
                                changeCallback={(e: any) => handleChange(e)}
                                focusedCallback={(name: string) => focus(name)}
                            />
                            {
                                (cancel === 'name') &&
                                <Icon onClick={() => handleClear('name')}>
                                    <Cancel />
                                </Icon>
                            }
                        </FormGroup>
                        <FormGroup
                            onMouseLeave={() => mouseLeave('desc')}
                            onMouseEnter={() => mouseEnter('desc')}>
                            <Input
                                required
                                name='desc'
                                label='Description'
                                value={expense.desc}
                                focused={focused === 'desc'}
                                placeholder='Fuel for generator'
                                changeCallback={(e: any) => handleChange(e)}
                                focusedCallback={(name: string) => focus(name)}
                            />
                            {
                                (cancel === 'desc') &&
                                <Icon onClick={() => handleClear('desc')}>
                                    <Cancel />
                                </Icon>
                            }
                        </FormGroup>
                    </FormGroupCont>
                </Column>
                
                <Column>
                    <h6>DETAILS</h6>
                    <FormGroupCont>
                        <Double>
                            <FormGroup w={100} onMouseLeave={() => mouseLeave('spender')} onMouseEnter={() => mouseEnter('spender')}>
                                <Input
                                    name='spender'
                                    label='Spender'
                                    placeholder='Amos Ibrahim'
                                    value={expense.spender || ''}
                                    focused={focused === 'spender'}
                                    changeCallback={handleChange}
                                    focusedCallback={(name: string) => focus(name)}
                                />
                                {
                                    (cancel === 'size') &&
                                    <Icon >
                                        <Cancel />
                                    </Icon>
                                }
                            </FormGroup>
                        </Double>
                    </FormGroupCont>
                    <FormGroupCont className="fgroup">
                        <Double>
                            <FormGroup off w={100} onMouseLeave={() => mouseLeave('amount')} onMouseEnter={() => mouseEnter('amount')}>
                                <Input
                                    required
                                    name='amount'
                                    type="number"
                                    label='Amount spend'
                                    placeholder='20'
                                    value={expense.amount || null}
                                    focused={focused === 'amount'}
                                    changeCallback={(e: any) => handleChange(e)}
                                    focusedCallback={(name: string) => focus(name)}
                                />
                                {
                                    (cancel === 'amount') &&
                                    <Icon >
                                        <Drop />
                                    </Icon>
                                }
                            </FormGroup>
                            
                        </Double>
                    </FormGroupCont>
                </Column>
            </ColumnCont>
            <BtnCont>
                <Button 
                    data={data}
                    loading={loading} 
                    title={expense?._id ? 'Update expense':'Add expense'} 
                    disabled={
                        !expense.name ||
                        !expense.desc ||
                        !expense.amount
                    }
                />
            </BtnCont>
        </ExpenseFormItem>
    )
}
export default ExpenseForm