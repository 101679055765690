// src/requestPermission.ts
import { messaging, getToken } from './firebase';

export const requestPermission = async () => {
    try {
        const permission = await Notification.requestPermission();
        if (permission === 'granted') {
            const token = await getToken(messaging, { vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY });
            // console.log('FCM Token:', token);
            localStorage.setItem('FCM_TOKEN', token);
            // You can now send the token to your server to save it
        } else {
            console.error('Notification permission denied');
        }
    } catch (error) {
        console.error('An error occurred while requesting permission:', error);
    }
};
