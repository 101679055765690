import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { NavLink } from 'react-router-dom'
import { Analytics, Clipboard, Compass, Tag, HandShake, CircleArrow, Like, Orders, Signout, Support, User, InvoiceIcon, ReactagleArrowRight, SqureFill, StoreFront } from '../icons'
import { getLocalStore, inDevelopment } from '../../utils'
import { Divider, Icon } from '../icons/styles'


import { ActionList, ListItem } from './styles'

const NavListMenu = (props: any) => {
  const { hideMenuCallback } = props
  const navigate = useNavigate();
  const [token, setToken] = useState('');
  const [accType, setAccType] = useState('');

  const signOut = () => {
    if (token) {
      if (inDevelopment) {
        localStorage.clear()
      } else {
        localStorage.removeItem('account')
        localStorage.removeItem('token')
      }
      navigate("/");
    }
    navigate("/signin");
  }



  useEffect(() => {
    const t = localStorage.getItem('token') as any;
    if (t) {
      const account = getLocalStore()
      if (account) {
        setToken(t);
        setAccType(account.info?.accType)
      }
    }
  }, []);


  return (
    <ActionList onMouseLeave={() => hideMenuCallback(false)}>
      <ListItem >
        <NavLink to='/account'>
          <Icon id='user'>
            <User />
          </Icon>
          Account
        </NavLink>
        <Divider />
      </ListItem> 
        <ListItem>
          <NavLink to='/explore'>
            <Icon>
              <Compass />
            </Icon>
            Market Place
          </NavLink>
        </ListItem>
        <ListItem>
          <NavLink to='/stores'>
            <Icon>
            <StoreFront />
            </Icon>
            Stores
          </NavLink>
        <Divider />
        </ListItem>
      
      <ListItem>
        <NavLink to='/'>
          <Icon id='stocks'>
            <SqureFill />
          </Icon>
          Inventory
        </NavLink>
        {/* <Divider /> */}
      </ListItem>
     
      <ListItem>
        <NavLink to='/sales' title='Sales'>
          <Icon id="sales">
            <HandShake />
          </Icon>
          Sales
        </NavLink>
      </ListItem>
      <ListItem>
        <NavLink to='/expenses' title='Expenses'>
          <Icon id="sales">
            <ReactagleArrowRight />
          </Icon>
          Expenses
        </NavLink>
        <Divider />
      </ListItem>
      <ListItem>
        <NavLink to='/analytics'>
          <Icon id='chart'>
            <Analytics />
          </Icon>
          Analytics
        </NavLink>
        <Divider />
      </ListItem>
      <ListItem>
        <NavLink>
          <Icon>
            <Support />
          </Icon>
          Support
        </NavLink>
      </ListItem>
      {
        !token ?
          <ListItem>
            <NavLink to='/signin'>
              <Icon id='signout'>
                <Signout />
              </Icon>
              Sign in
            </NavLink>
            <Divider bottom={100} />
          </ListItem>
          :
          <ListItem onClick={signOut}>
            {/* <Icon id='signout'>
              <Signout />
            </Icon> */}
            Sign out
            <Divider bottom={100} />
          </ListItem>
      }
    </ActionList>
  )
}

export default NavListMenu