import styled from "styled-components";

export const Button = styled.button<any>`
  height: 55px;
  width: 55px;
  border-radius: 30px;
  border:none;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color:rgba(30, 215, 95, 0.487);
  color:  #1ed760;
  font-size: 14px;
  font-weight: 700;
  margin-right: 10px;
  .icon {
    width: 26px;
    height: 26px;
    svg {
      stroke: white;
    }
  }
  &:hover {
      border: none;
      background-color:  rgba(30, 215, 95, 0.609);
  }
  `