import styled from "styled-components";

export const ItemCont = styled.div`
    width: 100%;
    height: 55px;
    position: relative;
    font-size: 13px;
    cursor: pointer;
    :hover {
      background: whitesmoke;
    }
`
export const Item = styled.div`
  height: 100%;
  width: 100%;
  padding: 5px 10px;
  display: grid;
  position: relative;
  grid-template-columns: 45px 1fr auto ;
  align-items: center;
`

export const DesCont = styled.div.attrs(() => ({
    className: 'ds'
})) <any>`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom-color: ${props => props.theme.mode === 'dark' ? props.theme.dark.colors.separators.sec : props.theme.light.colors.separators.pri
};
`
export const Desc = styled.div`
  height: 85%;
  width:85%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  h6 {
     margin-bottom: 0px;
    width:170px;
    font-size: 15px;
     white-space: nowrap;
     overflow: hidden;
     text-overflow: ellipsis;
     padding-left: 5px;
     font-weight: 700;
  }
   p {
     margin-bottom: 0px;
     padding: 0px;
     padding-left: 5px;
     position: relative;
     width:170px;
     font-size: 13px;
     white-space: nowrap;
     overflow: hidden;
     text-overflow: ellipsis;
     color: rgb(83,100,113);
   }
  
`;

export const TimeCont = styled.div<any>`
    display:flex;
    flex-direction: column;
    text-align: right;
    position:relative;
    min-width:60px;
    p {
        text-align: right;
        right: 5px;
        position: relative;
    }
`;


export const TimeStamp = styled.p<any>`
    width: auto;
    margin-bottom: 0px;
    text-align: right;
    position: absolute;
    font-size: 12px;
    top: 0;
    color: rgb(83,100,113);
`
