import styled from "styled-components";

export const FormCont = styled.div`
    width: 100%;
    height: 100%;
    background: #fafafa;
`
export const Form = styled.form`
    width: 100%;
    height: 100%;
    position: relative;
    display: grid;
    grid-template-columns: 80% 1fr;
    button {
        border: none;
        outline: none;
        color: green;
        background-color: transparent;
        font-size: 12px
    }
`
export const MessageInput = styled.input`
    width: 100%;
    height: 100%;
    position: relative;
    border: none;
    outline: none;
    padding: 10px;
    font-size: 13px;
    background-color: inherit;
    &:focus {
        border: none;
        outline: none;
        box-shadow: none;
    }
`