import { useQuery, useReactiveVar } from '@apollo/client'
import React, { useEffect, useState } from 'react'

import { dummy_products, queryOptions } from '../../types/defaults'
import SideBar from '../explore/components/sidebar'
import MainHeader from './components/headers/main'
import StockListGroup from './components/list/stockgroup'
import EmptyState from './components/pagestate/empty'
import ErrorState from './components/pagestate/error'
import LoadingState from './components/pagestate/loader'
import { ListGroupCont } from './components/styles'
import { Container, Main, TableCont } from './styles'
import InfiniteScroll from "react-infinite-scroll-component";
import { LocalCheckOutModal } from '../../components/modals'
import StockModal from '../../components/modals/stock'
import { GET_PRODUCTS } from '../../graphql/queries/product.query'
import { ChatModal } from '../../components/modals/chat'
import CircularButton from './components/buttons/circular'
import ChatBox from '../../components/chat'
import { showInventoryModal, tableState } from '../../globals'
import { CheckOutOnlineModal } from '../../components/modals/onlineCheckout'
import StocksCard from '../../components/cards/stock'
import SideNavigator from '../../components/sidenav'
import AddStockBtn from './components/buttons/add'
import StocksLoader, { CardLoader } from '../../components/cards/loaders'

const Inventory = (props: any) => {

  // const [windowSize, setWindowSize] = useState({
  //   width: window.innerWidth,
  // });

  // useEffect(() => {
  //   const handleResize = () => {
  //       setWindowSize({
  //         width: window.innerWidth,
  //       });
  //     };
  //     window.addEventListener('resize', handleResize);
  //     return () => {
  //     window.removeEventListener('resize', handleResize);
  //   };
  // }, []);

  const queries = useReactiveVar(queryOptions)

  let offset = 0;

  const { data, loading, error, fetchMore, refetch } = useQuery(GET_PRODUCTS, {
    variables: {
      ...queries,
      offset,
      group: queries.group ? queries.group : 'category',
    },
    fetchPolicy: "network-only",
  })

  if (data) {
    offset = offset + 10;
  }

  if (error) console.log({ ...error })

  const fetchMoreData = () => {
    // fetchMore({
    //   variables: {
    //     ...queries,
    //     offset: data?.products?.length,

    //     group: queries.group ? queries.group : 'category'
    //   }
    // })
  }
  const openEditors = () => {
    tableState({
      item: null,
      state: 'editing'
    })
    showInventoryModal(true)
  }


  /* <p className='window'>{windowSize.width}</p> */
  return (
    <>
      <MainHeader />
      <Main>
        <Container>
          {
            loading ?
            <>
                <LoadingState />
                <CardLoader />
            </>
              
              :
              error ?
                <ErrorState retryCallback={refetch} />
                :
                !data.products.length ?
                  <EmptyState
                    clickCallback={openEditors}
                    btnLabel='Add'
                    message='No stocks so far'
                    subMessage='Stocks appears here when created'
                  />
                  :
                  <>
                    <TableCont>
                      <ListGroupCont>
                        <InfiniteScroll
                          dataLength={data.products.length}
                          next={fetchMoreData}
                          hasMore={true}
                          loader={null}
                          style={{ overflow: 'visible' }}
                        >
                          {
                            data.products.map((items: any) => (
                              <StockListGroup
                                {...props}
                                key={items._id}
                                groupId={items._id}
                                list={items.records}
                                count={items.count}
                                total={items.total}
                              />
                            ))
                          }
                        </InfiniteScroll>
                      </ListGroupCont>
                    </TableCont>
                    <InfiniteScroll
                      dataLength={data.products.length}
                      next={fetchMoreData}
                      hasMore={true}
                      loader={null}
                      style={{ overflow: 'visible' }}
                    >
                      {
                        data.products.map((items: any) => (
                          <StocksCard
                            {...props}
                            key={items._id}
                            groupId={items._id}
                            list={items.records}
                            count={items.count}
                            total={items.total}
                          />
                        ))
                      }
                    </InfiniteScroll>
              </>
          }
        </Container>
        {/* <ChatBox />*/}
        <StockModal />
        <CircularButton />
        <LocalCheckOutModal />
        <CheckOutOnlineModal /> 
        <SideNavigator />
        <AddStockBtn />
      </Main>
    </>
  )
}

export default Inventory