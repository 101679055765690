import React from 'react'
import { Icon } from '../../../../../components/icons/styles'
import { formatMoney } from '../../../../../utils'
import { Star } from '../../icons'
import { CardCont, Description, ImageCont, PriceCont, RatingsCont, StatsCont } from './styles'
import { getImageUrl } from '../../../../../apollo'
import { AddToCartButton } from '../../../../explore/components/card/styles'
import { BagMinus, BagPlus } from '../../../../../components/icons'
import { useReactiveVar } from '@apollo/client'
import { onlineCart } from '../../../../../globals'
// import { CartItem } from '../../../../../types/model'
import { Link } from 'react-router-dom'
import { CartItem } from '../../../../../models'


const Card = (props: any) => {
    
    const { product:{_id, mediaUrls, name, description, sellingPrice} } = props
    const { stocks } = useReactiveVar(onlineCart)
    const updateCart = (e: any) => {
        e.stopPropagation()
        // if (item.q.val <= 0) return
        const { __typename, ...striped } = props.product;
        let { stocks } = onlineCart()
        const i = stocks.find((st: CartItem) => st._id === _id)

        stocks = (!i) ?
            [
                ...stocks, {
                    _id,
                    quantity: 1,
                    booked: false,
                    delivered: 0,
                    item: striped,
                }
            ]
            :
            stocks.filter((s: any) => s._id !== i._id)

        onlineCart({
            ...onlineCart(),
            stocks
        })

    }
    return (
        <CardCont>
            <Link to={`/product/${_id}`}>
            <ImageCont>
                <img src={getImageUrl(mediaUrls[0] || 'd063578d-733d-4aca-8809-e18368d55184')} alt="" />
            </ImageCont>
            </Link>
            <Description>
                <AddToCartButton selected={stocks.some((s: any) => s._id === _id)} onClick={(e: any) => updateCart(e)} className="cart-btn">
                    <Icon className='bag'>
                        {
                            stocks.some((s: any) => s._id === _id) ? <BagMinus /> : <BagPlus />
                        }
                    </Icon>
                </AddToCartButton>
                {/* <StatsCont>
                    <p>502 Sold</p>
                    <RatingsCont>
                        <Icon size={9}>
                            <Star />
                        </Icon>
                        <p>4.7</p>
                    </RatingsCont>
                </StatsCont> */}
                <p className="desc">{name}</p>
                <PriceCont>
                    <h6>
                        <sub>N</sub>
                        {formatMoney(sellingPrice)}
                    </h6>
                </PriceCont>
                {/* <p className="discount">5,070</p> */}
            </Description>
        </CardCont>
    )
}

export default Card