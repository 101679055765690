import styled from "styled-components";

export const ButtonItem = styled.button<any>`
  height: 45px;
  width: 100%;
  border-radius: 12px;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  bottom: 40px;
  outline: none;
  font-size: 12px;
  font-weight: 600;
  color: ${(props) => (props.disabled ? "grey" : "white")};
  background-color: ${(props) => (props.disabled ? "whitesmoke" : "#1ed760")};
  &:hover {
    border: none;
    background-color: ${(props) => (props.disabled ? "whitesmoke" : "#0cb848")};
    color: ${(props) => (props.disabled ? "grey" : "white")};
  }
  :hover {
    svg {
      stroke: "white";
    }
  }
  &:focus {
    border: none;
  }
`;
export const NextBtn = styled.div<any>`
  height: 45px;
  width: 100%;
  border-radius: 12px;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: none;
  bottom: 40px;
  outline: none;
  font-size: 12px;
  font-weight: 600;
  color: ${(props) => (props.disabled ? "grey" : "white")};
  background-color: ${(props) => (props.disabled ? "whitesmoke" : "#1ed760")};
   &:hover {
    border: none;
    color: ${props => props.disabled ? '#b2b3b2' : 'white'};
    background-color: ${props => props.disabled ? 'whitesmoke' : '#05b86d'} ;
  }
  :hover {
    svg {
      stroke: "white";
    }
  }
  &:focus {
    border: none;
  }
  .check {
    width: 18px;
    height: 18px;
    svg {
      stroke: white;
    }
  }
`;
export const MessageBtn = styled(ButtonItem)<any>`
  position: relative;
  bottom: 0px;
  padding: 0px 15px;
`

export const IconBtn = styled.button<any>`
  height: 27px;
  width: 27px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  outline: none;
  cursor: pointer;
  font-size: 11px;
   background-color: #0c0c0c19;
  .icon {
    width: 18px;
    height: 18px;
    svg {
      stroke: grey;
    }
  }
  &:hover {
    border: none;
    background-color: #10c34f;
    .icon {
      svg {
        stroke: white;
      }
    }
  }
  &:focus {
    border: none;
  }
`;
export const MoreBtn = styled.button<any>`
  height: 45px;
  width: 45px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  outline: none;
  font-size: 11px;
  background-color: #0c0c0c19;
  .icon {
    width: 18px;
    height: 18px;
    svg {
      stroke: grey;
    }
  }
  &:hover {
    border: none;
    /* background-color: #10c34f; */
    .icon {
      svg {
        stroke: white;
      }
    }
  }
  &:focus {
    border: none;
  }
`;

export const Container = styled.button<any>`
  height: 45px;
  width: 45px;
  border-radius: 50%;
  position: fixed;
  display: flex;
  bottom: 15%;
  right:  10%;
  transition: all .2s ease-in-out;
  box-shadow: rgba(196,195,195,0.216) 0px 5px 25px;
  z-index:10;
  align-items: center;
  justify-content: center;
  border: none;
  outline: none;
  background: #25ec78;
  font-size: 11px;
  color: #424242;
  font-weight: 600;
  &:hover { 
    border: none;
    background-color: #08d57f;
  }
  :hover {
    svg {
      stroke: "white";
    }
  }
  &:focus {
    border: none;
  }
  .icon {
    width: 18px;
    height: 18px;
     svg {
      stroke: "white";
    }
  }
`;

export const AddButtonCont = styled(Container)`
  width: 55px;
  height: 55px;
  bottom: 10%;
  display: flex;
  //display: none;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
  @media(max-width: 768px) {
    display: flex;
  }
`