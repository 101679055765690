import React from 'react'
import { showInventoryModal } from '../../../../globals'
import { MessageButton } from '../buttons/styles'
import { MessageCont } from './styles'

function EmptyState(props:any) {
    const { clickCallback, message, subMessage, btnLabel } = props;
    return (
        <MessageCont>
            <h6>{message}</h6>
            <p>{subMessage}</p>
            <MessageButton onClick={clickCallback}>{btnLabel}</MessageButton>
        </MessageCont>
    )
}

export default EmptyState