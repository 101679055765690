import { useReactiveVar } from "@apollo/client"
import { useState ,useEffect} from "react"

import { ArrowBack, Bag, Chevron, User } from "../../../../../components/icons"
import { Divider, Icon } from "../../../../../components/icons/styles"
import NavListMenu from "../../../../../components/topNavMenuList"
import { onlineCart, showMainCheckOut, showNavModal, showOnlineCheckOutModal } from "../../../../../globals"
import { queryOptions } from "../../../../../types/defaults"

import SearchForm from "../../forms"
import { FormCont } from "../../forms/styles"
// import CatHeader, { SubCatHeader } from "../table"
import { CatCont, HamdbugerMenu, Header, Back, HeaderCont, HeaderMenuCont, IconsGroup, MenuCont } from "./styles"
import { CartItemsCounter } from "../../../../inventory/components/headers/main/styles"
import CatHeader from "../../../../../components/topnav/headers/table"
import CatHeaderPath from "../table"
import { useLocation, useNavigate,NavLink } from "react-router-dom"
// import { CatHeaderPath } from "../table"

const MainHeader = (props: any) => {

 const {toggleNavCallBack} = props
    const [menu, setMenu] = useState(false)
    let { stocks } = useReactiveVar(onlineCart)

    // const { group, filter } = useReactiveVar(queryOptions)

    // const setOption = (opt: string) => {
    //     setMenu('')
    // }
    const { pathname } = useLocation();
    const navigate =useNavigate();
    const toggleSideNav = ()=>{
        showNavModal(true)
    }
    useEffect(() => {
      console.log(pathname)
    }, [pathname])
    
    return (
        <Header>
            <HeaderCont>
                <FormCont>
                    <div>
                        <NavLink className='home' to='/'>
                            <h2 >Stosyst</h2>
                        </NavLink>
                        <MenuCont>
                            <Back onClick={() => navigate(-1)}>
                                <Icon>
                                    <ArrowBack />
                                </Icon>
                            </Back>
                            <h6>Details</h6>
                        </MenuCont>
                    </div>

                    <h6 className='page__title'>Stosyst</h6>

                    <HeaderMenuCont>
                        <Icon onClick={() => showOnlineCheckOutModal(true)} id='bag'>
                            {
                                // stocks.length ?
                                    <CartItemsCounter>
                                        {/* {stocks.length} */}
                                    </CartItemsCounter>
                                    // :
                                    // null
                            }
                            <Bag />
                        </Icon>
                        <IconsGroup 
                            onClick={()=>setMenu(false)} 
                            onMouseEnter={()=>setMenu(true)}
                    >
                            <Icon>
                                <User />
                            </Icon>
                            <Icon id='cheveron'>
                                <Chevron />
                            </Icon>
                        </IconsGroup>
                        {menu && <NavListMenu hideMenuCallback={setMenu} />}
                    </HeaderMenuCont>
                </FormCont>
            </HeaderCont>
            <CatCont>
                {/* <CatHeader /> */}
                <Divider />
            </CatCont>
            {/* <CatCont>
                <CatHeader {...props} />
                <Divider />
            </CatCont> */}
            <CatHeaderPath {...props} />
        </Header>
    )
}

export default MainHeader