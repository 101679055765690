import { Container } from "react-bootstrap"
// import { Divider } from "../../../../../components/icons/styles"
import { HeaderListItems, Item } from "./styles"
import { Divider } from "../../../../../components/icons/styles"

export const TableHeader = () => {

    return (
        <Container id='thead-cont'>
            <HeaderListItems>
                <Item>
                    <h6>NAME</h6>
                </Item>
                <Item>
                    <h6>CONTACT</h6>
                </Item>
                <Item>
                    <h6>PERMISSIONS</h6>
                </Item>
               
                <Item>
                    <h6>DATE JOINED</h6>
                </Item>
                <Divider />
            </HeaderListItems>
        </Container>
    )
}