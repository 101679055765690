import styled from "styled-components";

export const Cont = styled.div<any>`
  width: 100%;
  height: 60px;
  display: grid;
  grid-template-columns: 50px auto;
  gap: 0px 10px;
  border-radius: 8px;
  cursor: pointer;
  position: relative;
  margin: auto;
  align-items: center;
  padding: 0px;
  #highlight {
    /* content:""; */
    display: block;
    position: absolute;
    height: 100%;
    width: 101%;
    left: -6px;
    bottom: 0;
    border-radius: 6px;
  }
  li:nth-child(4) {
    .icon {
      width: 22px;
      height: 22px;
      display: none;
    }
  }
  &:hover {
    cursor: pointer;
    #highlight {
      background-color: whitesmoke;
    }
    ul {
      .divider {
        display: none;
      }
    }
    li {
      p:last-child {
        color: #363636;
      }
    }
    #action {
      
      p,.divider {
        display: none;
      }
    }
     li:nth-child(4) .icon {
       display: flex;
        svg {
          fill: #073e02;
        }
     }
  }
  
  /* background: ${props => props.selected ? '#25ec78': 'white'}; */
`;
export const ImageCont = styled.div`
  width: 50px;
  height: 50px;
  border: none;
  position: relative;
  border-radius: 6px;
  position: relative;
  z-index: 1;
  background: #e3e3e3;
  img {
    height: 100%;
    width: 100%;
    border-radius: inherit;
    object-fit: cover;
    border: none;
    mix-blend-mode: multiply;
    filter: contrast(1);
    vertical-align: top center;
  }
`;
export const RowCont = styled.ul`
  width: 100%;
  height: 100%;
  display: grid;
  position: relative;
  margin: 0px;
  padding-left: 0px;
  grid-template-columns:31% 1fr 1fr 1fr;
`;
export const RowItem = styled.li<any>`
  height: 100%;
  width: 100%;
  cursor: pointer;
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  p:first-child {
    margin-bottom: 0px;
    font-size: 13px;
  }
  p {
    margin-bottom: 0px;
    font-size: 12px;
    position: relative;
    width: 98%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #181818;
  }
  p:last-child {
    color:  ${props => props.selected ? '#393a3a' : 'grey'};
  }
  &:nth-child(3) {
    p {
      text-align: right;
      padding-right: 5px;
    }
  }
  &:nth-child(3) {
    p {
      text-align: right;
      padding-right: 5px;
    }
  }
  &:nth-child(4) {
    align-items: flex-end;
    text-align: right;
    padding-right: 5px;
  }
  .badge {
    width: 60px;
    align-self: end;
    font-weight: 500;
    border-radius: 12px;
    color:  ${props => props.status === 'Verified' ? '#056600' : '#be0000ba'};
    background-color: ${props => props.status === 'Verified' ? '#00ff2f2a' : '#ff00002a'}  !important;
  }
`;

