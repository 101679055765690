import { Angle } from "../../../../../../components/icons"
import { Divider, Icon } from "../../../../../../components/icons/styles"
import { Star } from "../../../icons"
import { Container } from "../reviews/styles"
import { Header, Spec, SpecCont, SpecTable, TableCont } from "./styles"
// import { Container, Header, HeaderCont, HeaderReviewCont, HeaderStarsCont } from "./styles"

const Specs = () => {
    return (
        <Container>
                <Header>
                    Specifications
                    <span>
                        <Icon>
                            <Angle />
                        </Icon>
                    </span>
                </Header>
            <TableCont>
                <SpecTable>
                    <SpecCont>
                        <Divider bottom={100} />
                        <Spec label><p>Size</p></Spec>
                        <Spec><p>Not specified</p></Spec>
                    </SpecCont>
                    <SpecCont>
                        <Divider bottom={100} />
                        <Spec label><p>Measure</p></Spec>
                        <Spec> <p>Not specified</p></Spec>
                    </SpecCont>
                </SpecTable>
            </TableCont>
        </Container>
    )
}

export default Specs