
import { gql } from '@apollo/client'

export const GET_ACCOUNT = gql`
    query getAccount {
        account {
            _id
            info {
                dp
                name
                username
            }
            email
            addresses {
                city
                street
                country
            }
            createdAt
            updatedAt
        }
    }
`
export const GET_MY_ACCOUNT = gql`
    query getMyAccount {
        myAccount {
           _id
           email
           phone
           username
           info {
            dp
            bio
            name
            banner
            accType
            category
           }
           addresses {
            _id
            city
            street
            state
            zipCode
            country
           }
        connections {
                _id
                email
                phone
                username
                info {
                    dp
                    name
                }
                addresses {
                    _id
                    city
                    street
                    state
                    country
                }
            }
            subs {
                plan
                endDate
                startDate
            }
            cards {
                name
                cvv
                cardNumber
                expiryMonthYear
           }
           createdAt
        }
    }
`
export const GET_STORE = gql`
    query getStore($id: String) {
        store(id: $id) {
           _id
           email
           phone
           username
           info {
            dp
            bio
            name
            banner
            accType
            category
           }
           addresses {
            city
            street
            state
            zipCode
            country
           }
           createdAt
        }
    }
`
export const GET_STORES = gql`
    query getStores($query: String,  $filter: String,$group: String, $offset: Int) {
        stores(query: $query,filter: $filter,group: $group, offset: $offset) {
           _id
           email
           phone
           username
           info {
            dp
            bio
            name
            banner
            accType
            category
           }
           addresses {
            city
            street
            state
            zipCode
            country
           }
           createdAt
        }
    }
`
export const GET_HISTORY = gql`
    query history($accNumb: String!) {
        history(accNumb: $accNumb) {
            _id
           from {
            dp
            name,
            accNumb
           }
           to {
            dp
            name,
            accNumb
           }
           amount
           type
           channel
           createdAt
           updatedAt
        }
    }
`