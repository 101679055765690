
// import { cart, showCheckOutModal, showInvoiceModal } from "../../../../globals";
import { useMutation } from "@apollo/client";
import { globalUser, showStaffModal } from "../../../../../globals";
import { MenuOptionsList, Option } from "./styles";
import { DROP_STAFF } from "../../../../../graphql/mutations/staff.mutation";
import { GET_STAFF } from "../../../../../graphql/queries";

export const ActionsMenu = (props: any) => {
    let { id, rt, staff, closeMenuCallback } = props

    const editInfo = ()=>{
        const {info, username} = staff
        showStaffModal(true)
        globalUser({
            ...info, 
            id: staff._id 
        })
        closeMenuCallback(false)
    }


    const [deleteStaffAccount, { error, loading }] = useMutation(DROP_STAFF, {
        update: (cache, { data: { dropStaff: dropped } }) => {
            const cached: any = cache.readQuery({
                query: GET_STAFF,
            })
            // console.log(cached)
            let prevStaff = cached.staff.map((st: any) => {
                return (st.records.some((r: any) => r._id === id)) ?
                    ({
                        ...st,
                        records: st.records.filter((s: any) => s._id !== id)
                    })
                    :
                    st
            })
            cache.writeQuery({
                query: GET_STAFF,
                data: {
                    staff: prevStaff.filter((st: any) => st.records.length)
                }
            });
        }
    });

    if(error) {
        console.log({...error})
    }

    const deleteAccount = () => {
        deleteStaffAccount({
            variables: {
                id
            },
        })
    }

    return (
        <MenuOptionsList
            {...props}
            onClick={() => closeMenuCallback(false)}
            rt={rt} 
            onMouseLeave={() => closeMenuCallback(false)}
        >
            <Option onClick={editInfo}>
                <p>Edit</p>
            </Option>
            <Option>
                <p>Info</p>
            </Option>
            <Option onClick={() => deleteAccount()}>
                <p>Delete</p>
            </Option>
        </MenuOptionsList>
    )
}