import React from 'react'
import { Like } from '../../../../../components/icons';
import { Icon } from '../../../../../components/icons/styles';
import { Loader } from '../../../../../components/loaders';
import { Button } from './styles'

const AddToCartBtn = (props: any) => {
    const { loading, title, clickCallback } = props;
  return (
      <Button onClick={clickCallback}>
          {
              loading ? <Loader /> : 
              <Icon>
                  <Like />
              </Icon>
          }
      </Button>
  )
}

export default AddToCartBtn