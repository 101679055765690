import { useQuery } from '@apollo/client'
import React from 'react'
import { GET_CONTACTS } from '../../../graphql/queries'
import { dummies } from '../../../pages/settings/staff'
import EmptyState from '../../../pages/inventory/components/pagestate/empty'
import ErrorState from '../../../pages/inventory/components/pagestate/error'
import LoadingState from '../../../pages/inventory/components/pagestate/loader'
import { getLocalStore } from '../../../utils'
import { Person } from './person'
import { ContactsCont } from './styles'

const Contacts = (props: any) => {
    const { loading, data, refetch, error } = useQuery(GET_CONTACTS, {
        fetchPolicy: "network-only"
    })

    const getContact = (users: any[]) => {
        // const { _id } = getLocalStore()

        // const adminIndex  = users.findIndex((user: any) =>  user._id === _id)
        // if (adminIndex !== -1){}

        // let n = users[0]._id === _id ? users[0] : users[1]

        // user = user.info.storeId ? users[1] : users[0]

        // if() {
        //     me = users[1]
        // }

        // let user = users.find((user: any) =>  user._id !== _id || user.info)
        let user = users[0]

        const i = Math.floor(Math.random() * 5)

        // user = {
        //     ...user,
        //     info: {
        //         ...user?.info,
        //         dp: dummies[i]?.dp
        //     }
        // }
       
        return user;
    } 

    // if(data) {
    //     console.log(data)
    // }
    if(error) {
        console.log({...error})
    }
  return (
      <ContactsCont {...props}> {
          loading ?
              <LoadingState />
              :
              error ?
                  <ErrorState retryCallback={refetch} />
                  :
                  !data.contacts.length ?
                      <EmptyState 
                        btnLabel='Refresh' 
                        message='No messages so far'
                        clickCallbck={refetch}
                        subMessage='Message appears here when created'
                      />
                      :
                      <> {
                        data.contacts.map((contact: any) => (
                            <Person
                                {...props}
                                key={contact._id}
                                chatId={contact._id}
                                person={getContact(contact.participants)} 
                                lastMessage={contact.messages?.slice(-1)[0]?.message}
                            />
                        ))
                      }
                      </>
      }
      </ContactsCont>
  )
}

export default Contacts