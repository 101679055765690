import { gql } from "@apollo/client";

export const SAVE_STAFF_INFO = gql`
    mutation saveStaffInfo($input: StaffAccountInput!) {
        saveStaffInfo(input: $input) { 
                _id
                info {
                    dp
                    phone
                    email
                    name
                    storeId
                    accType
                }
                permissions {
                    products
                    sales
                    expenses
                    staff
                    account
                    analytics
                }
                username
                createdAt
                updatedAt
        }
    }
`
export const UPDATE_PERMISSIONS = gql`
    mutation UpdatePermissions($input: PermissionsInput!) {
        updatePermissions(input: $input) { 
            products
            sales
            expenses
            staff
            account
            analytics
        }
    }
`
export const DROP_STAFF = gql`
    mutation DropStaff($id: String!) {
        dropStaff(id: $id) { 
                _id
                info {
                    dp
                    name
                    category
                    storeId
                    accType
                }
                createdAt
                updatedAt
        }
    }
`