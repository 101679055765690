import { useReactiveVar } from '@apollo/client'
import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { showNav, showNavModal } from '../../globals'
import { HomeIcn, Invoice, Sparkle, Scroll, Analytics, Alt, SquareGrid, User, HandShake, ReactagleArrowRight, StoreFront } from '../icons'
import { Icon } from '../icons/styles'
import { LinkItem, LinkItemsCont, Modal, NavCont, NavHeaderCont } from './styles'
import { useNavigate } from 'react-router-dom'
import { getLocalStore, inDevelopment } from '../../utils'

const SideNavigator = (props: any) => {
  const [navMenu, setNavMenu] = useState(false)
  const modal = useReactiveVar(showNavModal)
  // const navOpned = useReactiveVar(showNav)


  const navigate = useNavigate();
  const [token, setToken] = useState('');
  const [accType, setAccType] = useState('');

  const signInOut = () => {
    if(token){
      if (inDevelopment) {
        localStorage.clear()
      } else {
        localStorage.removeItem('account')
        localStorage.removeItem('token')
      }
      navigate("/");
    }
    navigate("/signin");
  }


  useEffect(() => {
    const t = localStorage.getItem('token') as any;
    if (t) {
      const account = getLocalStore()
      if (account) {
        setToken(t);
        setAccType(account.info?.accType)
      }
    }
  }, []);

  const { showModal } = props

  useEffect(() => {
    setNavMenu(modal)
    // console.log(navMenu)
  }, [modal])

  return (
    <Modal opened={navMenu} onClick={() => showNavModal(false)}>
      <NavCont opened={navMenu}>
        <NavHeaderCont>
        </NavHeaderCont>
        <LinkItemsCont>
          <LinkItem>
            <NavLink to='/' title='Home'>
              <Icon >
                <SquareGrid style={{ width: '18px', height: '18px' }} />
              </Icon>
              <h6>Inventory</h6>
            </NavLink>
          </LinkItem>
          <LinkItem>
            <NavLink to='/sales' title='Sales'>
              <Icon id="payment">
                <HandShake />
              </Icon>
              <h6>Sales</h6>
            </NavLink>
          </LinkItem>
          <LinkItem>
            <NavLink to='/expenses' title='Expenses'>
              <Icon id="exp">
                <ReactagleArrowRight />
              </Icon>
              <h6>Expenses</h6>
            </NavLink>
          </LinkItem>
          <LinkItem>
            <NavLink to='/explore' title='Invoice'>
              <Icon id="orders">
                <Sparkle />
              </Icon>
              <h6>Market place</h6>
            </NavLink>
          </LinkItem>
          <LinkItem>
            <NavLink to='/stores' title='Stores'>
              <Icon id="orders">
                <StoreFront />
              </Icon>
              <h6>Stores</h6>
            </NavLink>
          </LinkItem>
          <LinkItem>
            <NavLink to='/analytics' title='Analytics'>
              <Icon id='account'>
                <Analytics />
              </Icon>
              <h6>Analytics</h6>
            </NavLink>
          </LinkItem>
          <LinkItem>
            <NavLink to='/account' title='Account'>
              <Icon id="account">
                <User />
              </Icon>
              <h6>Account</h6>
            </NavLink>
          </LinkItem>
        </LinkItemsCont>
        <LinkItemsCont className='sign'>
          <LinkItem>
            <p onClick={signInOut} title='Account'>
              <Icon id="alt">
                <Alt />
              </Icon>
              <h6>{token ? 'Sign out' : 'Sign in'}</h6>
            </p>
          </LinkItem>
        </LinkItemsCont>
      </NavCont>
    </Modal>
  )
}

export default SideNavigator