import styled from "styled-components";

export const OptionListCont = styled.ul<any>`
  top: -280%;
  width: 200px;
  margin: auto;
  left: 0%;
  min-height: 250px;
  overflow: scroll;
  padding-left: 0px;
  border-radius: 0px;
  z-index: 14;
  position: absolute;
  background-color:white;
  overflow:visible;
  li:first-child {
    border-radius: 6px 6px 0px 0px;
  }
  li:last-child {
    border-radius: 0px 0px 6px 6px;
  }
  ::-webkit-scrollbar {
    width: 0 !important
  }
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px;
`

export const CheckListCont = styled(OptionListCont)<any>`
  top: ${props => props.top}px;
  width: 180px;
  left: 102%;
  z-index: 10;
  overflow: scroll;
  height: ${props => props.mh}px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px;
`
export const DropDownItem = styled.li<any>`
  display: grid;
  grid-template-columns: 1fr 30px;
  align-items: center;
  position: relative;
  min-height: 38px;
  background: white;
  width: auto;
  overflow: visible;
  cursor: pointer;
  border-radius: 4px;
  background-color:white;
  :hover {
    background: #fbfbfb;
    overflow: visible;
  }
  p {
    width: 100%;
    margin-bottom: 0px;
    font-size: 13px;
    padding-left: 10px;
  }
  .icon {
    width: 16px;
    height: 16px;
    margin: auto;
    svg {
      stroke: grey;
    }
  }
`
export const CheckItem = styled(DropDownItem)<any>`
    grid-template-columns: 30px 1fr;
    border-radius: 0px;
    overflow:hidden;
    :hover {
        /* background: #00800010; */
    }
`
export const CheckBox = styled.div<any>`
    height: 20px;
    width: 20px;
    border-radius: 3px;
    margin-left: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: ${props=>props.selected ===true ? 0: 1}px solid grey;
    background: ${props => props.selected===true ? '#008000d0': 'white'};
    .icon {
        width: 10px;
        height: 10px;
        svg {
            fill: #ffffff;
            stroke: white;
        }
    }
`

export const Button = styled.button<any>`
  height: 32px;
  width: 80px;
  border-radius: 6px;
  position: relative;
  display: flex;
  margin-right: 10px;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  border: none;
  background: #282828;
  font-size: 12px;
  font-weight: 500;
  color: white;
  &:hover {
    border: none;
    background-color: #171717;
    color: white;
  }
  :hover {
    svg {
      stroke: "white";
    }
  }
`;

export const FooterForm =  styled.form`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: end;
`