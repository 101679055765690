import { useQuery, useReactiveVar } from '@apollo/client'
import React, { MutableRefObject, useEffect, useRef, useState } from 'react'
import { chatID, chatUser, globalUser } from '../../../globals'
import { GET_CHAT } from '../../../graphql/queries'
import { CHAT_SUBSCRIPTION } from '../../../graphql/subscription/chat.subs'
import EmptyState from '../../../pages/inventory/components/pagestate/empty'
import ErrorState from '../../../pages/inventory/components/pagestate/error'
import LoadingState from '../../../pages/inventory/components/pagestate/loader'
import { formatTime, format_date, getLocalStore } from '../../../utils'
import ChatForm from './form'
import { Chat, ChatsBody, ChatsBodyCont, Footer, Message, PlaceHolder } from './styles'

const Messages = (props: any) => {
    const user = useReactiveVar(chatUser) as any

    const [id, setid] = useState(user !== null ? user._id : '')

    const [scroll, setScroll] = useState(false)

    const scrollerRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
        setid(user?._id)

        subscribeToNewMessage()
    }, [user?._id])

    const scrollContainer = () => {
        scrollerRef?.current?.scrollIntoView({
            behavior: 'smooth',
            block: 'end'
        })
    }

    useEffect(() => {
        scrollContainer()
    }, [])

    const { loading, data, refetch, error, subscribeToMore } = useQuery(GET_CHAT, {
        variables: {
            rcvId: id
        },
        fetchPolicy: "network-only"
    })

    let messages: any = []

    if (error) console.log({ ...error })

    if (data) {
        messages = data.chat?.messages
        // console.log(data)
    }

    const sameDay = (m0: any, m1: any) => {
        return new Date(+m1.timestamp).getDate() !== new Date(+m0.timestamp).getDate()
    }

    const sameMinutes = (m0: any, m1: any) => {
        return formatTime(m0.timestamp) === formatTime(m1.timestamp)
    }

    const subscribeToNewMessage = () =>
        subscribeToMore({
            document: CHAT_SUBSCRIPTION,
            variables: {
                userId: user?._id
            },
            updateQuery: (prev, { subscriptionData }) => {
                //console.log(`prev: ${prev}`)

                console.log(`new: ${JSON.stringify(subscriptionData, null, 2)}`)

                if (!subscriptionData.data) return prev;

                // const newMessage = subscriptionData.data.messageAdded.messages;

                // console.log(`msgs: ${subscriptionData.data.messages}`)

                return Object.assign({}, prev, {
                    chat: {
                        ...prev,
                        // messages: [...newMessage]
                    }
                })
            }
        })

    return (
        <ChatsBodyCont {...props}> {
            loading ?
                <LoadingState />
                :
                error ?
                    <ErrorState retryCallback={refetch} />
                    :
                    <>
                        <ChatsBody onClick={scrollContainer} >{
                            data?.chat?.messages.length > 0 ?
                                <>
                                    {
                                        messages?.map((m: any, i: number) => (
                                            <>
                                                <Chat
                                                    key={m._id}
                                                    me={getLocalStore()._id === m.sender._id}
                                                >
                                                    <Message
                                                        className='msg'
                                                        me={user?._id !== m.sender?._id}
                                                    >
                                                        <p>{m.message}</p>
                                                    </Message> {
                                                        i < (messages.length - 1) && !sameMinutes(m, messages[i + 1]) &&
                                                        <p className="timestamp">
                                                            <span>{formatTime(m.timestamp)}</span>
                                                        </p>
                                                    }
                                                </Chat> {
                                                    i > 0 && sameDay(messages[i - 1], m) &&
                                                    <p className="time-separator">{format_date(m.timestamp)}</p>
                                                }
                                            </>
                                        ))
                                    }
                                </>
                                :
                                <p className='empty'> No chats so far</p>
                        }
                            {/* <PlaceHolder 
                                ref={scrollerRef}
                                className='scroller' 
                            /> */}
                        </ChatsBody>
                        <Footer>
                            <ChatForm
                                // reciever={user} 
                                chatId={data ? data?.chat?._id : ''}
                                scrollCallback={scrollContainer}
                            />
                        </Footer>
                    </>
        }
        </ChatsBodyCont>
    )
}

export default Messages