import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { NavLink } from 'react-router-dom'
import { Chevron, HomeIcn } from '../../../../../components/icons'
import { Divider, Icon } from '../../../../../components/icons/styles'

import { HeaderCont, HeaderListItems, HeaderMainCont, Item } from './styles'

export const SubCatHeader = () => {
    const [active, setaAtive] = useState('cosmetics')
    return (
        <HeaderCont>
            <HeaderListItems>
                <Item active={active ==='cosmetics'} onClick={() => setaAtive('cosmetics')} >
                    <NavLink to='#' title='Cosmentics'>
                        <h6>COSMETICS</h6>
                    </NavLink>
                </Item>
                <Item active={active === 'medicine'} onClick={() => setaAtive('medicine')}>
                    <NavLink to='#' title='Medicine'>
                        <h6>DEVICE</h6>
                    </NavLink>
                </Item>
                <Item active={active === 'medicine'} onClick={() => setaAtive('medicine')}>
                    <NavLink to='#' title='Medicine'>
                        <h6>INJECTABLE</h6>
                    </NavLink>
                </Item>
                <Item active={active === 'medicine'} onClick={() => setaAtive('medicine')}>
                    <NavLink to='#' title='Medicine'>
                        <h6>ANTI-BIOTIC</h6>
                    </NavLink>
                </Item>
                <Item active={active === 'medicine'} onClick={() => setaAtive('medicine')}>
                    <NavLink to='#' title='Medicine'>
                        <h6>ANTI-MALARIA</h6>
                    </NavLink>
                </Item>
                <Item active={active === 'medicine'} onClick={() => setaAtive('medicine')}>
                    <NavLink to='#' title='Medicine'>
                        <h6>ANTACID</h6>
                    </NavLink>
                </Item>
                <Item active={active === 'medicine'} onClick={() => setaAtive('medicine')}>
                    <NavLink to='#' title='Medicine'>
                        <h6>SEXUAL HEALTH</h6>
                    </NavLink>
                </Item>
                <Divider />
            </HeaderListItems>
        </HeaderCont>
    )
}
const CatHeaderPath = (props: any) => {
    const { prod: { categories }  } = props
    const formatParam = (param: string) => param ? param.replace(/\s/g, "-").toLowerCase() : ''
    return (
        <HeaderMainCont>
            <HeaderCont>
                <HeaderListItems>
                    <Item>
                        <NavLink to={`/explore/${encodeURIComponent(categories?.name)}`} title='Cosmentics'>
                            <h6>{categories?.name}</h6>
                        </NavLink>
                       
                    </Item>
                    <Item>
                        <NavLink to='#'>
                            <Icon rot={-90} >
                                <Chevron />
                            </Icon>
                        </NavLink>
                    </Item>
                    <Item >
                        <NavLink to={`/explore/${categories?.name}/${encodeURIComponent(categories?.category) }`}>
                            <h6>{categories?.category}</h6>
                        </NavLink>
                        
                    </Item>
                </HeaderListItems>
            </HeaderCont>
        </HeaderMainCont>
    )
}

export default CatHeaderPath