import React from 'react'
import { PageLoader } from '../../../../components/loaders'
import { MessageButton } from '../buttons/styles'
import { MessageCont } from './styles'

function LoadingState() {
    return (
        <MessageCont>
            <PageLoader />
        </MessageCont>
    )
}

export default LoadingState