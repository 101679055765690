import { gql } from "@apollo/client";

export const CHECK_OUT = gql`
  mutation checkOut($invoice: InvoiceInput!) {
    checkOut(invoice: $invoice) {
      _id
      stocks {
        _id
        item {
          _id
          name
          description
          categories {
            name
            category
          }
          # costPrice
          sellingPrice
        }
        booked
        delivered
        quantity
      }
      customer {
        firstName
        lastName
        phone
        email
        address
      }
      tid
      modifier
      recieved
      completed
      payable
      paymentMethod
      added {
        firstName
        lastName
        phone
        email
      }
      modified {
        firstName
        lastName
        phone
        email
      }
      createdAt
      updatedAt
    }
  }
`;
export const CHECKOUT_ONLINE = gql`
  mutation checkOutOnline($order: OrderInput!) {
    checkOutOnline(order: $order) {
      _id
      tid
      stocks {
        _id
        item {
          _id
          name
          owner {
            _id
            info {
              name
              dp
            }
          }
          description
          categories {
            name
            category
          }
          sellingPrice
        }
        booked
        delivered
        quantity
      }
      shipping {
        contact {
          address {
            street
            city
            state
            zipCode
          }
          phone
        }
        tracking {
          time {
            from
            to
          }
          routes
        }
        fee
      }
      payment {
        method {
          via
          currency
        }
        details {
          name
          cvv
        }
      }
      status
      buyer {
        _id
        info {
          name
          dp
        }
      }
      modified {
        _id
        info {
          name
          dp
        }
      }
      createdAt
      updatedAt
    }
  }
`;
