import React, { useEffect, useState } from 'react'
import { AccordionCont, AccodionHeader } from './styles'
import { Divider, Icon } from '../../../../../components/icons/styles'
import { HandShake, Tag, User, Staff, Analytics, ArrowSlideUp } from '../../../../../components/icons'
import AccodionItem from './item'
import { useReactiveVar } from '@apollo/client'
import { perms } from '../../../../../globals'

const Accordion = (props: any) => {
  let my_perms: any = useReactiveVar(perms)
  const { type:resource, defaultState } = props
  const [opened, setOpened] = useState(defaultState ?? false)

  const togglePermission = (perm: string) => {
    const pms: any = my_perms[resource];
    const hasPermission = pms.includes(perm);
    if (hasPermission) {
      // If the permission exists, remove it
      perms({
        ...my_perms,
        [resource]: pms.filter((p: string) => p !== perm)
      })
    } else {
      // If the permission does not exist, add it
      perms({
        ...my_perms,
        [resource]: [...pms, perm]
      })
    }
  }

  return (
    <>
      <AccodionHeader onClick={() => setOpened(!opened)}>
        <h6>
          <span>
            <Icon>{
              resource === 'products' ? <Tag /> 
                : resource === 'sales' ? < HandShake /> 
                  : resource === 'account' ? <User/> 
                    : resource === 'expenses' ? <ArrowSlideUp/>
                      : resource === 'analytics' ? <Analytics />
              : <Staff />
            }
            </Icon>
          </span>
          {resource.toUpperCase()}
          </h6>
        <p>{opened ? '-' : '+'}</p>
        <Divider />
      </AccodionHeader>
      {
        resource === 'products' ?
          <AccordionCont opened={opened}>
            <AccodionItem 
              resource={resource}
              privilege='view' 
              toggleCallback={togglePermission} 
            />
            <AccodionItem 
              resource={resource} 
              privilege='add' 
              toggleCallback={togglePermission} 
            />
            <AccodionItem 
              resource={resource} 
              privilege='edit' 
              toggleCallback={togglePermission} 
            />
            <AccodionItem 
              resource={resource} 
              privilege='delete' 
              toggleCallback={togglePermission} 
            />
            <AccodionItem 
              resource={resource} 
              privilege='share' 
              // permissions={myperms} 
              toggleCallback={togglePermission} 
            />
          </AccordionCont>
          :
          resource === 'sales' ?
            <AccordionCont opened={opened}>
              <AccodionItem
                resource={resource}
                privilege='view'
                // permissions={myperms}
                toggleCallback={togglePermission}
              />
              <AccodionItem
                resource={resource}
                privilege='add'
                // permissions={myperms}
                toggleCallback={togglePermission}
              />
              <AccodionItem
                resource={resource}
                privilege='edit'
                // permissions={myperms}
                toggleCallback={togglePermission}
              />
              <AccodionItem
                resource={resource}
                privilege='delete'
                // permissions={myperms}
                toggleCallback={togglePermission}
              />
              <Divider />
            </AccordionCont>
            :
            resource === 'expenses' ?
            <AccordionCont opened={opened}>
              <AccodionItem
                  resource={resource}
                privilege='view'
                // permissions={myperms}
                toggleCallback={togglePermission}
              />
                <AccodionItem
                  resource={resource}
                  privilege='add'
                  // permissions={myperms}
                  toggleCallback={togglePermission}
                />
                <AccodionItem
                  resource={resource}
                  privilege='edit'
                  // permissions={myperms}
                  toggleCallback={togglePermission}
                />
                <AccodionItem
                  resource={resource}
                  privilege='delete'
                  // permissions={myperms}
                  toggleCallback={togglePermission}
                />
              {/* <Divider /> */}
            </AccordionCont>
              : resource === 'account' ?
              <AccordionCont opened={opened}>
                <AccodionItem
                    resource={resource}
                  privilege='view'
                  // permissions={myperms}
                  toggleCallback={togglePermission}
                />
              
                <AccodionItem
                    resource={resource}
                  privilege='edit'
                    permissions={resource}
                  toggleCallback={togglePermission}
                />
             
                {/* <Divider /> */}
              </AccordionCont>
                : resource === 'staff' ?
              <AccordionCont opened={opened}>
                <AccodionItem
                      resource={resource}
                  privilege='view'
                  // permissions={myperms}
                  toggleCallback={togglePermission}
                />
                <AccodionItem
                      resource={resource}
                  privilege='add'
                  // permissions={myperms}
                  toggleCallback={togglePermission}
                />
                <AccodionItem
                  resource={resource}
                  privilege='edit'
                  // permissions={myperms}
                  toggleCallback={togglePermission}
                />
                <AccodionItem
                      resource={resource}
                  privilege='delete'
                  // permissions={myperms}
                  toggleCallback={togglePermission}
                />
                {/* <Divider /> */}
              </AccordionCont>
              :
              <AccordionCont opened={opened}>
                <AccodionItem
                  resource={resource}
                  privilege='view'
                  // permissions={myperms}
                  toggleCallback={togglePermission}
                />
                {/* <Divider /> */}
              </AccordionCont>

      }
    </>

  )
}

export default Accordion