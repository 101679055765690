import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  flex-direction: column;
  padding: 10px 5px;
  position: relative;
  display: flex ;
  background: white;
  margin-bottom: 4px;
`;

export const Header = styled.h6`
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 0px;

`;
export const HeaderCont = styled.div`
  width:100%;
  height: auto;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
`;
export const HeaderReviewCont = styled.div`
  width: 100px;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: space-between;
  p{
    margin-bottom: 0px;
    font-size: 12px;
  }
  .icon {
        height: 18px;
        width: 18px;
  }
`;
export const HeaderStarsCont = styled.div`
  width: 55px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  .icon {
        height: 10px;
        width: 10px;
  }
`;

export const RatingsCont = styled.div`
  width: 100%;
  display: flex;
  position: relative;
  justify-content: space-between;
`;
export const StarsCountCont = styled.div`
  width: 40%;
  display: flex;
  position: relative;
  flex-direction: column;
  h2 {
    font-size: 64px;
    font-weight: 700;
  }
  @media (max-width: 420px) {
    align-items: center;
    justify-content: center;
    width: 50%;
    p {
      font-size: 12px;
      text-align: center;
    }
    h2 {
      font-size: 34px;
      font-weight: 500;
    }
    .reviewed--stars {
      width: 70px;
      .icon {
        height: 20px;
        width: 20px;
      }
    }
  }
  @media (min-width: 744px) and (max-width: 1024px) {
    width: 50%;
    h2 {
      font-size: 54px;
      font-weight: 700;
    }
  }
`;
export const StarsCont = styled.div`
  width: 120px;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: space-between;
  .icon {
    height: 22px;
    width: 22px;
  }
  @media (min-width: 420px) and (max-width: 1024px) {
    width: 100px;
    .icon {
      height: 18px;
      width: 18px;
    }
  }
`;
export const BarsCountCont = styled.div`
  width: 50%;
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (max-width: 420px) {
    height: 85px;
    align-items: start;
  }
`;
export const BarsRating = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 10px;
  position: relative;
  align-items: center;
  justify-content: space-between;
  p {
    margin-bottom: 0px;
    font-size: 12px;
    .icon {
      display: none;
    }
  }
  @media (max-width: 420px) {
    p {
      margin-bottom: 0px;
      font-size: 11px;
      display: flex;
      span {
        position: relative;
        bottom: 1px;
        margin-left: 3px;
        .icon {
          display: inline-block;
          height: 10px;
          width: 10px;
        }
      }
    }
    width: 75%;
    margin-bottom: 0px;
  }
`;
export const Bar = styled.div<any>`
  width: 270px;
  height: 8px;
  margin: 0px 5px;
  border-radius: 5px;
  position: relative;
  background-color: #f5f5f5;
  ::before {
    content: "";
    top: 0;
    left: 0;
    height: 100%;
    width: ${(props) => props.w}%;
    background: black;
    position: absolute;
    border-radius: 5px;
  }
  @media (max-width: 420px) {
    width: 75%;
    height: 6px;
  }
`;

export const ReviewCont = styled.div`
  width: 100%;
  display: flex;
  /* margin-top: 5px; */
 
  @media (min-width: 744px) and (max-width: 1024px) {
    margin-top: 10px;
  }
`;
export const Review = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  /* margin: 5px 0px; */
  p {
    margin-bottom: 0px;
    font-size: 14px;
  }
  p:first-child {
    font-weight: 700;
    font-size: 16px;
    width: 100%;
    position: relative;
    span {
      color: grey;
      position: absolute;
      right: 0;
      font-weight: 500;
    }
  }
  @media (max-width: 420px) {
    p {
      font-size: 12px;
    }
    p:first-child {
      font-weight: 600;
      font-size: 14px;
      span {
        font-size: 12px;
        font-weight: 500;
      }
    }
  }
  @media (min-width: 744px) and (max-width: 1024px) {
    margin-top: 10px;
    p {
      margin-bottom: 0px;
      font-size: 14px;
    }
    p:first-child {
      font-weight: 600;
      font-size: 14px;
      position: relative;
    }
  }
`;
export const StarsCount = styled.div`
  width: 65px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .icon {
    height: 18px;
    width: 18px;
  }
  @media (max-width: 420px) {
    width: 60px;
    height: 15px;
    .icon {
      height: 10px;
      width: 10px;
    }
  }
  @media (min-width: 744px) and (max-width: 1024px) {
    width: 54px;
    .icon {
      height: 12px;
      width: 12px;
    }
  }
`;

export const ImageCont = styled.div`
  border: none;
  position: relative;
  width: 39px;
  height: 35px;
  border-radius:50%;
  overflow: hidden;
  margin-right: 10px;
  background: #c1bfbf;
  top: 3px;
  img {
    height: 100%;
    width: 100%;
    border-radius: inherit;
    object-fit: cover;
    border: none;
    filter: contrast(1);
    mix-blend-mode: multiply;
    vertical-align: top center;
  }
  @media (min-width: 744px) and (max-width: 1024px) {
    width: 35px;
    height: 35px;
  }
`;
