import { useReactiveVar } from '@apollo/client'
import React from 'react'
import { chatView, openChatBox } from '../../../../globals'
import { Chevron } from '../../../icons'
import { Icon } from '../../../icons/styles'

import { Header } from './styles'

const MessageHeader = () => {
    const chatBox = useReactiveVar(openChatBox)
    const toggleChatBox = ()=> {
        // openChatBox(!openChatBox)
        openChatBox(!chatBox)
        // chatView('contacts')
    }
  return (
      <Header onClick={() => toggleChatBox() }>
            <h6>Messages</h6>
          {/* <Icon>
              <svg viewBox="0 0 24 24" >
                  <g>
                    <path d="M1.998 5.5c0-1.381 1.119-2.5 2.5-2.5h15c1.381 0 2.5 1.119 2.5 2.5V12h-2v-1.537l-8 3.635-8-3.635V18.5c0 .276.224.5.5.5H13v2H4.498c-1.381 0-2.5-1.119-2.5-2.5v-13zm2 2.766l8 3.635 8-3.635V5.5c0-.276-.224-.5-.5-.5h-15c-.276 0-.5.224-.5.5v2.766zM19 18v-3h2v3h3v2h-3v3h-2v-3h-3v-2h3z"></path>
                  </g>
                </svg>
          </Icon> */}
          <Icon>
            <Chevron />
              {/* <svg viewBox="0 0 24 24" >
                  <g>
                      <path d="M7.414 13l5.043 5.04-1.414 1.42L3.586 12l7.457-7.46 1.414 1.42L7.414 11H21v2H7.414z"></path>
                  </g>
              </svg> */}
          </Icon>
      </Header>
  )
}

export default MessageHeader