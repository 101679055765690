import styled from "styled-components";

export const ChatCont = styled.div<any>`
  width: 300px;
  height: 90%;
  right: 7%;
  z-index: 1000000;
  position: fixed;
  overflow: hidden;
  background: #ffffff;
  border-radius: 15px 15px 0px 0px;
  display: grid;
  grid-template-rows: 50px 1fr;
  transition: all .2s ease-in-out;
  bottom: ${(props) => (props.opened ? 0 : -82.5)}%;
  box-shadow: rgba(196, 195, 195, 0.216) 0px 5px 25px;
  @media(max-width: 768px) {
    display: none;
  }
`;
export const ChatSubCont = styled.div`
  width:100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
`;

