import styled from "styled-components";

export const ImageCont = styled.div`
  width: 40px;
  height: 40px;
  border: none;
  position: relative;
  border-radius: 50%;
  position: relative;
  z-index: 1;
  background: #e3e3e3;
  cursor: pointer;
  img {
    height: 100%;
    width: 100%;
    border-radius: inherit;
    object-fit: cover;
    border: none;
    mix-blend-mode: multiply;
    filter: contrast(1);
    vertical-align: top center;
  }
`;