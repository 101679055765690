import styled from "styled-components";
// export const HeaderMainCont = styled.div`
//   height: 100%;
//   width: 100%;
//   position: relative;
// `

// export const HeaderCont = styled.div.attrs({
//     className: 'container'
// })`
//   height: 100%;
//   max-width: 1000px;
//   padding:0px 0px;
//   margin-bottom: 10px;
// `
export const HeaderListItems = styled.ul`
  width: 100%;
  height: 40px;
  margin: auto;
  display: grid;
  position: relative;
  background: white;
  padding: 0px;
  z-index: 200;
  grid-template-columns: 35% 1fr 1fr 1fr;
`;

export const Item = styled.li`
  height: 100%;
  width: 100%;
  cursor: pointer;
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  h6, p {
    margin-bottom: 0px;
    font-size: 10px;
    position: relative;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #cbcbcb;
    font-weight: 700;
  }
  &:nth-child(3) {
    text-align: right;
  }
  &:nth-child(4) {
    flex-direction: column;
    text-align: right;
    align-self:end;
  }
`;
