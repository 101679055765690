import styled from 'styled-components'

export const Main = styled.main`
  width: 100%;
  height: 100vh;
  position: relative;
`
export const Container = styled.div.attrs({
  className: 'container'
})`

column-gap: 10px;
position: relative;
padding: 0px;
height: 100%;
max-width: 1000px;
padding-top: 180px;
  @media (max-width: 7680px) {
    padding: 100px 0px 0px 0px;
  }
`
export const ProductDetailsContainer = styled.div`
  position: relative;
  display: grid;
  padding: 0px;
  width: 100%;
  @media (max-width: 420px) {
    padding: 5px 0px 0px 0px;
  }
`

export const LikeCont = styled.div<any>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  z-index: 1;
  bottom: 25px;
  right: 10px;
  padding: 0px 5px;
  cursor: pointer;
  border-radius: 12px;
  height: 24px;
  background: #ffffffa2;
  :hover {
    background-color: #0000002c;
  }
  p {
    margin-bottom: 0px;
    font-size: 12px;
    color: grey;
  }
  .icon {
    width: 18px;
    height: 18px;
    svg {
      stroke: grey;
    }
  }
  @media (max-width: 420px) {
    p {
      /* font-weight: 400; */
    }
  }
`
export const DeskDetailsCont = styled.div`
  width: 100%;
  display: grid;
  @media (max-width: 420px) {
    display: none;
  }
`
export const DetailsSec = styled.section<any>`
  position: relative;
  width: 100%;
  @media (max-width: 420px) {
    grid-template-columns: 100%;

  }
  @media (min-width: 744px) and (max-width: 1024px) {
    grid-template-columns: 100%;
  }
`
export const DescSec = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  flex-direction: column;
`
export const ImageAndDescCont = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  padding-bottom: 10px;
  @media (max-width: 420px) {
    flex-direction: column;
  }
`
export const DescSection = styled.section`
  position: relative;
  display: flex;
  width: 100%;
  @media (max-width: 420px) {
    width: 100%;
  }
  @media (min-width: 744px) and (max-width: 1024px) {
    width: 100%;
  }
`
export const DetailImgCont = styled.div`
  width: 50%;
  display: flex;
  position: relative;
  flex-direction: column;
  @media (max-width: 420px) {
    width: 100%;
  }
  @media (min-width: 833px) and (max-width: 1024px) {
    width: 50%;
  }
`

export const ImageCont = styled.div`
  width: 100%;
  height: 500px;
  border: none;
  position: relative;
  border-radius: 16px;
  break-inside: avoid;
  position: relative;
  img {
    height: 100%;
    width: 100%;
    border-radius: inherit;
    object-fit: cover;
    border: none;
    mix-blend-mode: multiply;
    filter: contrast(1);
    vertical-align: top center;
    transition: transform 0.2s;
    :hover {
      cursor: pointer;
    }
  }
  @media (max-width: 420px) {
    height: 370px;
    border-radius: 22px 22px 0px 0px;
  }
  @media (min-width: 744px) and (max-width: 1024px) {
    border-radius: 10px;
    height: 300px;
  }
`
export const ImageViews = styled.div`
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: start;
  flex-wrap: wrap;
  margin: 10px 0px 20px 0px;
  img {
    height: 50px;
    width: 50px;
    border-radius: 4px;
    object-fit: cover;
    border: none;
    margin: 0px 5px;
    cursor: pointer;
    filter: contrast(1);
    mix-blend-mode: multiply;
    vertical-align: top center;
  }
  @media (max-width: 420px) {
    img {
      height: 45px;
      width: 45px;
      margin: 5px 3px;
    }
  }

  @media (min-width: 834px) and (max-width: 1024px) {
    border-radius: 3px;
    margin-top: 20px 0px 20px 0px;
    img {
      height: 40px;
      width: 40px;
      margin: 5px 3px;
    }
  }
`
export const DescCont = styled.div`
  width: 50%;
  height: 500px;
  display: flex;
  position: relative;
  flex-direction: column;
  padding: 0px 20px;
  padding-top: 10px;
  .prod-desc {
    font-size: 14px;
  }
  @media (max-width: 728px) {
    width: 100%;
    padding: 0px;
    z-index: 2;
    height: 100px;
  }
  @media (min-width: 744px) and (max-width: 1024px) {
    padding: 0px 15px;
    width: 50%;
  }
`
export const Details = styled.p`
  font-size: 16px;
  margin-bottom: 0px;
  font-weight: 700;
  line-height: 1.3;
  @media (max-width: 768px) {
    font-size: 12px;
    font-weight: normal;
    color: grey;
    line-height: 1.5;
    margin-bottom: 10px;
  }
  @media (min-width: 420px) and (max-width: 800px) {
    font-size: 14px;
    font-weight: 700;
  }
`

export const ReviewCont = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 70px;
  padding: 0px 0px 10px 0px;
  position: relative;
  justify-content: space-between;
  p {
    margin-bottom: 0px;
    font-size: 16px;
    font-weight: 700;
    span {
      font-weight: normal;
      margin-left: 10px;
      color: grey;
      span {
        font-size: 10px;
        margin-left: 0px;
        bottom: 2px;
        position: relative;
        margin: 0px 5px;
      }
    }
  }
  .store {
    text-decoration: none;
    font-size: 14px;
    margin-bottom: 0px 20px;
    color: #18c35f;
    :hover {
      color: #069a44;
      text-decoration: underline;
    }
  }
  @media (max-width: 420px) {
    height: 20px;
    justify-content: start;
    p {
        font-size: 12px;
        margin-left: 10px;
        span {
          font-size: 12px;
           margin-left: 5px;
        }
    }
    .divider {
        display: none;
    }
    margin-bottom: 10px;
  }
  @media (min-width: 744px) and (max-width: 1024px) {
    height: 40px;
    p {
      margin-bottom: 0px;
      font-size: 14px;
      font-weight: 700;
      span {
        margin-left: 10px;
        font-weight: normal;
        span {
          font-size: 8px;
          margin: 0px 5px;
        }
      }
    }
  }
`
export const Rating = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`
export const StarsCont = styled.div`
  display: flex;
  align-items: center;
  svg {
    margin: 0px 2px;
  }
  .icon {
    width: 20px;
    height: 20px;
  }
  @media (max-width: 420px) {
    .icon {
    width: 16px;
    height: 16px;
  }
  }
  @media (min-width: 744px) and (max-width: 1024px) {
    .icon {
      width: 14px;
      height: 14px;
    }
  }
`

export const PriceCont = styled.div`
  min-height: 50px;
  position: relative;
  @media (max-width: 420px) {
    width: 100%;
    min-height: 30px;
  }
  @media (min-width: 420px) and (max-width: 800px) {
    min-height: 30px;
  }
`
export const Price = styled.h4`
  font-size: 42px;
  font-weight: 700;
  margin: 20px 0px;
  sub {
    font-size: 26px;
    position: relative;
    bottom: 0.3px;
    margin: 0px 3px;
  }
  span {
    font-size: 20px;
    margin-left: 10px;
    text-decoration: line-through;
  }
  .off {
    font-size: 16px;
    font-weight: normal;
    text-decoration: none;
    color: grey;
  }
  @media (max-width: 420px) {
    font-size: 30px;
    margin: 15px 0px;
    sub {
      font-size: 16px;
    }
    span {
      margin-left: 20px;
    }
    .off {
      font-size: 16px;
      margin-left: 10px;
    }
  }
  @media (min-width: 744px) and (max-width: 1024px) {
    font-size: 30px;
    font-weight: 600;
    margin: 10px 0px;
    sub {
      font-size: 12px;
      position: relative;
      bottom: 0.3px;
      margin: 0px 3px;
    }
    span {
      font-size: 18px;
      margin-left: 10px;
      text-decoration: line-through;
    }
    .off {
      font-size: 14px;
    }
  }
`
export const ColorsCont = styled.div`
  width: 100%;
  min-height: 50px;
  position: relative;
  display: flex;
  align-items: start;
  flex-direction: column;
  h6 {
    margin: 10px 0px;
  }
  @media (max-width: 420px) {
    h6 {
      margin: 5px 0px;
      font-size: 14px;
      font-weight: 700;
    }
  }
  @media (min-width: 744px) and (max-width: 1024px) {
    h6 {
      margin: 5px 0px;
      font-size: 14px;
      font-weight: 600;
    }
  }
`
export const ColorsImageCont = styled.div`
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: start;
  flex-wrap: wrap;
  img {
    height: 60px;
    width: 60px;
    border-radius: 6px;
    object-fit: cover;
    border: none;
    margin: 5px;
    cursor: pointer;
    filter: contrast(1);
    mix-blend-mode: multiply;
    vertical-align: top center;
    
  }
  @media (max-width: 420px) {
    img {
      height: 42px;
      width: 42px;
      margin: 3px;
      border-radius: 4px;
    }
  }
  @media (min-width: 744px) and (max-width: 1024px) {
    img {
      height: 35px;
      width: 35px;
      margin: 3px;
      border-radius: 4px;   
    }
      
    }

`
export const SizesCont = styled.ul`
  width: 100%;
  padding: 0px;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: start;
  flex-wrap: wrap;
`
export const SizeItem = styled.li`
  width: auto;
  height: 35px;
  padding: 5px;
  border-radius: 6px;
  border: 1px solid lightgrey;
  color: grey;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  margin: 0px 3px;
  @media (max-width: 420px) {
    height: 30px;
    border: none;
    background-color:#f5f5f5;
    font-size: 14px;
  }
  @media (min-width: 420px) and (max-width: 1024px) {
    height: 25px;
    border-radius: 4px;
    font-size: 12px;
    margin: 2px 3px;
  }
`
export const DetailsButtonsCont = styled.div`
  width: 100%;
  
  margin: 20px 0px 0px 0px;
  position: relative;
  display: flex;
  justify-content: end;
  margin-top: auto;

`
