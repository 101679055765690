import React from 'react'
import { CartItem } from '../../../../models'
import { formatMoney } from '../../../../utils'

const Content = (props: any) => {
    const {invoice} = props
  return (
      <ul>
        --------------------------------------
        {
            invoice.stocks.map((stock: CartItem, index: number) => (
                <li key={stock._id}>
                    <span className='product'>{stock.quantity } {stock.item.name}</span>
                    <span className='price'>{formatMoney(stock.item.sellingPrice * stock.quantity)}</span>
                </li>
            ))  
        }
        --------------------------------------
      </ul>
  )
}

export default Content