import { useReactiveVar } from '@apollo/client'
import React from 'react'
import { showAccModal } from '../../globals'
import { AccModalCont } from './styles'

const AccModal = () => {
    const show = useReactiveVar(showAccModal)
    return (
        <AccModalCont onClick={() => showAccModal('')} opened={show} />
    )
}

export default AccModal