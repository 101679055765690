import { gql } from '@apollo/client'

export const SIGN_UP = gql`
    mutation signUp($creds: Creds!) {
        signUp(creds: $creds) {
            token
            account {
                _id
                info {
                    dp
                    banner
                    name
                    accType
                }
                cards {
                    name
                    cardNumber
                    expiryMonthYear
                    cvv
                }
                email
                phone
                username
                addresses {
                    street
                    city
                    state
                    zipCode
                }
            }
        }
    }
`
export const ONBOARD = gql`
    mutation onBoard($info: InfoInput!) {
        onBoard(info: $info) { 
                _id
                info {
                    dp
                    banner
                    name
                    accType
                }
                email
                username
        }
    }
`
export const SIGN_UP_GOOGLE = gql`
    mutation signUpGoogle($creds: GoogleCreds!) {
        signUpGoogle(creds: $creds) { 
            token
            account {
                _id
                info {
                    dp
                    banner
                    name
                    accType
                }
                cards {
                    name
                    cardNumber
                    expiryMonthYear
                    cvv
                }
                email
                phone
                username
                addresses {
                    street
                    city
                    state
                    zipCode
                }
            }
        }
    }
`
export const SIGN_IN_GOOGLE = gql`
    mutation signInGoogle($token: String!) {
        signInGoogle(token: $token) { 
            token
            account {
                _id
                info {
                    dp
                    banner
                    name
                    accType
                    storeId
                }
                cards {
                    name
                    cardNumber
                    expiryMonthYear
                    cvv
                }
                email
                phone
                username
                addresses {
                    street
                    city
                    state
                    zipCode
                }
            }
        }
    }
`
export const SIGN_IN = gql`
    mutation signIn($creds: Creds!) {
        signIn(creds: $creds) {
            token
            account {
                 _id
                info {
                    dp
                    banner
                    name
                    category
                    accType
                    storeId
                }
                cards {
                    name
                    cardNumber
                    expiryMonthYear
                    cvv
                }
                email
                phone
                username
                addresses{
                    street
                    city
                    state
                    zipCode
                }
            }
        }
    }
`

export const ADD_ACCOUNT = gql`
    mutation addAccount($input: AddAccInput!) {
        addAccount(input: $input) {
            _id
            dp
            name
            username
            address
        }
    }
`
export const UPDATE_ACCOUNT = gql`
    mutation updateAccount($input: AccInput!) {
        updateAccount(input: $input) {
            _id
            name
            email
            phone
            address
            category
            dp
            banner
            username
            createdAt
        }
    }
`

export const DeleteProduct = gql`
    mutation deleteProduct( $id: String!) {
        deleteProduct(id: $id) {
            _id
        }
    }
`
export const SAVE_ADDRESS = gql`
    mutation saveAddress($address: AddressInput!) {
        saveAddress(address: $address) {
            _id
            email
            phone
            username
            info {
                dp
                bio
                name
                banner
                accType
                category
            }
            addresses {
                _id
                city
                street
                state
                zipCode
                country
            }
                subs {
                    plan
                    endDate
                    startDate
                }
                cards {
                    name
                    cvv
                    cardNumber
                    expiryMonthYear
            }
            createdAt
        }
    }
`
export const SAVE_ACC_INFO = gql`
    mutation saveAccInfo($info: InfoInput!) {
        saveAccInfo(info: $info) {
            _id
           info {
                dp
                bio
                name
                banner
                accType
                category
           }
        }
    }
`
export const UPGRADE_ACCOUNT = gql`
    mutation upgradeAccount($card: CardInput!) {
        upgradeAccount(card: $card) {
            _id
            info {
                accType
            }
            subs {
                plan
            }
            cards {
                cvv
                cardNumber
                expiryMonthYear
                accountHolderName
           }
        }
    }
`
export const SAVE_PHONE_NUMBER = gql`
    mutation savePhoneNumber($phone: String!) {
        savePhoneNumber(phone: $phone) {
             _id
            email
            phone
            username
            info {
                dp
                bio
                name
                banner
                accType
                category
            }
            addresses {
                _id
                city
                street
                state
                zipCode
                country
            }
                subs {
                    plan
                    endDate
                    startDate
                }
                cards {
                    name
                    cvv
                    cardNumber
                    expiryMonthYear
            }
            createdAt
        }
    }
`
export const SAVE_EMAIL = gql`
    mutation saveMail($email: String!) {
        saveMail(email: $email) {
           _id
            email
            phone
            username
            info {
                dp
                bio
                name
                banner
                accType
                category
            }
            addresses {
                _id
                city
                street
                state
                zipCode
                country
            }
                subs {
                    plan
                    endDate
                    startDate
                }
                cards {
                    name
                    cvv
                    cardNumber
                    expiryMonthYear
            }
            createdAt
        }
    }
`
export const SAVE_USERNAME = gql`
    mutation saveUsername($username: String!) {
        saveUserName(username: $username) {
            _id
            email
            phone
            username
            info {
                dp
                bio
                name
                banner
                accType
                category
            }
            addresses {
                _id
                city
                street
                state
                zipCode
                country
            }
                subs {
                    plan
                    endDate
                    startDate
                }
                cards {
                    name
                    cvv
                    cardNumber
                    expiryMonthYear
            }
            createdAt
        }
    }
`
export const SAVE_PASSWORD = gql`
    mutation saveMail($pwd: String!, $newpwd: String!) {
        savePassword(pwd: $pwd) {
            pwd,
            newpwd
        }
    }
`
export const DELETE_STAFF = gql`
    mutation deleteStaff($id: String!) {
        deleteStaff(id: $id) {
            id
        }
    }
`
