import styled from "styled-components";

export const ChatsBodyCont = styled.div<any>`
  padding-top: 10px;
  height: 100%;
  width: 100%;
  position: absolute;
  display: grid;
  grid-template-rows: 1fr 45px;
  transition: all 0.15s ease-in-out;
  left: ${(props) => (props.in ? 0 : -100)}%;
`;
export const ChatsBody = styled.div<any>`
  padding: 0px 10px;
  height: 100%;
  width: 100%;
  position: relative;
  overflow-y: scroll;
  .time-separator {
    font-size: 11px;
    color: grey;
    width: 100%;
    text-align: center;
  }
  .empty {
    width: 100%;
    text-align: center;
    color:grey;
    font-size:12px;
  }

  /* .me {
    flex-direction: row-reverse;
    .msg {
      border-radius: 20px 15px 0px 20px;
      background: #39f286;
      p {
        color: #032c0a;
      }
    }
    p:last-child {
      text-align: right;
    }
  }
  .you {
    position: relative;
    .msg {
      max-width: 80%;
      border-radius: 15px 25px 25px 0px;
      background-color: rgba(239,243,244,1.00);
    }
  } */
`;


export const ChatGroup = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 15px;
`;
export const Chat = styled.div<any>`
  position: relative;
  display: flex;
  width: 100%;
  flex-direction: column;
  .timestamp {
    margin-bottom: 10px;
    width: 100%;
    font-size: 11px;
    color: grey;
    text-align: ${props => props.me ? 'right' : ''};
  }
`;
export const Message = styled.div<any>`
  min-height: 40px;
  position: relative;
  margin-bottom: 1px;
  max-width: 85%;
  display: flex;
  align-self: ${props => props.me ? 'end' : 'start'};
  flex-direction: ${props=>props.me ? 'row-reverse' : ''};
  border-radius: ${props => props.me ? '20px 0px 0px 20px' : '0px 25px 25px 0px'};
  background-color: ${props => props.me ? '#08d45d' : 'rgba(239,243,244,1.00)'}; 
  p {
    font-size: 14px;
    padding: 10px;
    margin-bottom: 0px;
    color: ${props => props.me ? 'white' : 'black'};
    line-height: 1.3;
  }
`;
export const PlaceHolder = styled.div`
  position: relative;
  width: 100%; 
  height: 30px; 
`;
export const Footer = styled.div`
  width: 100%;
  height: 45px;
  position: relative;
  margin-top: auto;
  border-radius: 0px 0px 8px 8px;
`;
