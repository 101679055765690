import { gql } from '@apollo/client'

export const GET_CHART_HEADER = gql`
    query ChartHeader {
        chartHeader {
            stocksCount
            uniqueStocks
            categories
            totalAmount
    }
}`
export const GET_CHART_FOOTER = gql`
    query ChartFooter {
        chartFooter {
            expired
            expiring
            outOfStocks
            lowStocksCount
       }
    }
`
export const GET_SALES_EXPENSES = gql`
    query SalesExpenses($duration: String) {
        salesExpenses(duration: $duration) {
            sales {
                _id
                totalSales
                head {
                    createdAt
                }
                tail {
                    createdAt
                }
            }
            expenses {
                _id
                totalExpenses
            }
           
        }
    }
`

export const GET_TRENDS = gql`
    query getTrends($item: String, $duration: String) {
        trends(duration: $duration, item: $item) {
            _id
            items {
                stocks {
                    item {
                        name
                        description
                        categories {
                            name
                            category
                        }
                        mediaUrls
                        sellingPrice
                    }
                    quantity
                }
            }
            head {
                createdAt
            }
            tail {
                createdAt
            }
            totalSalesCount
        }
    }
`