import styled from "styled-components";

export const RelatedCont = styled.section`
  width: 100%;
  display: flex;
  position: relative;
  flex-wrap: wrap;
  /* @media (max-width: 420px) {
    overflow: scroll;
    flex-wrap: nowrap;
  } */
`;
export const Header = styled.h6`
  margin: 16px 0px;
  font-weight: 700;
  font-size: 20px;
  @media (max-width: 420px) {
    margin: 10px 0px;
    font-weight: 700;
    font-size: 16px;
  }
`;
