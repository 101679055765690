import styled from "styled-components"

export const DropCont = styled.div<any>`
  background-color: inherit;
  height: 32px;
  width: 100%;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  margin-bottom: 0px;
  display: flex;
  overflow: visible;
  flex-direction: column;
  align-items: space-between;
  justify-content: center;
  position: relative;
  text-align: left;
  font-size: 13px;
   label {
    font-size: 11px;
    width: auto;
    left: 0px;
    font-size: 11px;
    color: grey;
    margin-bottom: 0px;
    pointer-events: none;
    position: relative;
    background-color: transparent;
    span {
      font-size: 12px;
      color: #ff0000c4;
    }
  }
  .icon {
    height: 14px;
    width: 14px;
    svg {
      stroke: 'grey'
    }
  }
  div {
    height: 20px;
  }
`
export const Header = styled.header`
  width: 100%;
  top: 0px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 10px;
  h6{
    font-size: 13px;
    margin-bottom: 0px;
    font-weight: 600;
  }
  .icon {
    width: 10px;
    height: 10px;
    cursor: pointer;
  }
`
 
export const DropDownListCont = styled.ul<any>`
  top: 90%;
  width: 100%;
  /* max-width: 80%; */
  margin: auto;
  left: 0%;
  height: ${props=>props.h || 130}px;
  overflow: scroll;
  padding-left: 0px;
  border-radius: 6px;
  z-index: 14;
  position: absolute;
  background-color:white;
  overflow:visible;
  /* overflow-y: auto; */
  li:first-child {
    border-radius: 6px 6px 0px 0px;
  }
  li:last-child {
    border-radius: 0px 0px 6px 6px;
  }
  ::-webkit-scrollbar {
    width: 0 !important
  }
  border: 1px solid rgba(71, 75, 76, 0.055);
  box-shadow: rgba(0, 0, 0, 0.05) 0 0 0 1px, rgba(0, 0, 0, 0.12) 0 15px 30px 0px;
`
export const SingleDropDownListCont = styled(DropDownListCont)<any>`
  height: ${props => props.h}px;
  left: 0%;
  width: 130px;
  z-index: 10;
  top: 0%;
  overflow: scroll;
`
export const SubDropDownListCont = styled(DropDownListCont)<any>`
  top: 10px;
  width: 160px;
  left: 40%;
  z-index: 10;
  overflow: scroll;
  height: ${props=>props.mh}px;
`
export const DropDownItem = styled.li<any>`
  display: grid;
  grid-template-columns: 1fr 30px;
  align-items: center;
  position: relative;
  min-height: 30px;
  background: white;
  width: auto;
  overflow: hidden;
  cursor: pointer;
  border-radius: 4px;
  background-color:white;
  :hover {
    background: whitesmoke;
    overflow: visible;
  }
  p {
    width: 100%;
    margin-bottom: 0px;
    font-size: 13px;
    padding-left: 10px;
  }
  .icon {
    width: 16px;
    height: 16px;
    margin: auto;
    svg {
      stroke: grey;
    }
  }
`

