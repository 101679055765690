import styled from "styled-components";

export const Card = styled.div.attrs({
  className:'stockCard'
})`
  width: 97%;
  padding: 10px;
  padding-bottom: 7px;
  border-radius: 20px;
  margin: auto;
  position: relative;
   /* max-height: 425px; */
  overflow: hidden ;
  background: #ffffff;
  margin-bottom: 5px;
  display: none;
  @media(max-width: 768px) {
    display: block;
    overflow: hidden;
  }
`;
export const Header = styled.header`
margin: 5px 0px;
color: #000000;
margin: auto;
display: flex;
width: 100 %;
height: 30px;
align-items: center;
position: relative;
justify-content: space-between;
p {
  margin-bottom: 0px;
  font-size: 12px;
}
  h6 {
  font-size: 10px;
  font-weight: 700;
  color: #2b2b2b;
  margin-bottom: 0px;
  text-transform: uppercase;
    a {
    color: #2b2c2c;
    text-decoration: none;
      &:hover {
      text-decoration: underline;
    }
  }
}
`
export const Footer = styled.footer`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 30px;
  color: black;
  position: relative;

  h6 {
    font-size: 16px;
    font-size: 700;
    margin-bottom: 0px;
  }
  p {
    font-size: 14px;
    color: grey;
    margin-bottom: 0px;
  }
`
export const StockCont = styled.div`
  display: grid;
  gap: 0px 10px;
  width: 100%;
  height: 70px;
  border: none;
  position: relative;
  cursor: pointer;
  align-items: start;
  justify-content: start;
  p {
    margin-bottom: 0px;
  }
  grid-template-columns: 55px 1fr;
`;
export const DescCol = styled.div`
  height: 65px;
  position: relative;
  flex-direction: column;
  justify-content: space-between;
`;
export const TitleCont = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  .icon {
    width: 10px;
    height: 10px;
  }
`;

export const DescCont = styled.div`
  height: 40px;
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: start;
  p {
    /* font-size: 14px; */
    margin-bottom: -3px;
    max-width: 220px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-left: 0px;
    color: #222222;
  }
  p:last-child {
     color: grey;
     margin-bottom: 0px;
  }
`;

export const PriceCont = styled.div`
  height: 20px;
  width: 100%;
  display: flex;
  flex-direction: row;
  position: relative;
  justify-content: space-between;
  align-items: center;
  bottom: 3px;
  p {
    /* font-size: 14px; */
    margin-bottom: 0px;
  }
  p:last-child {
    font-size: 13px;
    color: black;
    font-weight: normal;
    text-align: right;
    /* font-weight: 500; */
  }
`;
export const StorePriceCont = styled(PriceCont)`
  height: 25px;
  width: 100%;
  position: relative;
  left: 0px;
  bottom: 0px;
  flex-direction: row;
`;

export const DescRow = styled.div`
  width: 100%;
  flex-direction: row;
  overflow: visible;
  justify-content: space-between;
`;

export const TotalCont = styled(PriceCont)`
  height: 55px;
  width: 100%;
  margin-bottom: 0px;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;


export const Title = styled.p<any>`
  font-size: 13px;
`;
export const StockPrice = styled.p`
  font-size: 13px;
`;

export const Money = styled(Title) <any>`
  text-align: right;
  position: relative;
  color: ${({ pending }: any) => (pending ? '#fa6767' : 'black')};
`;
export const SubTotal = styled(Title) <any>`
  position: absolute;
  text-align: right;
  width: 50%;
  right: 0px;
  font-weight: normal;
  color: ${({ pending }: any) => (pending ? '#fa6767' : 'black')};
`;
export const CheckOutLabel = styled.p`
  text-align: center;
  font-weight: normal;
  color: #00a3fe;
  font-size: 12px;
  margin-top: -5px;
`;

export const Desc = styled.p`
  width: 90%;
  font-size: 11px;
`;
export const Instock = styled.p<any>`
  font-size: 12px;
  text-align: right;

`;

export const ImageCont = styled.div<any>`
    background: rgb(230 230 230);
    height: 55px;
    width: 55px;
    border-radius: 8px;
    overflow: hidden;
    position: relative;
    background-size: cover;
    background-position: center;
    img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        border-radius: inherit;
    }
`;

export const StockIndicator = styled.div<any>`
    top: 0;
    left: 0;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center; 
    background-color: ${({ expiryStatus }) => expiryStatus === 'weak' ? '#ffd108' : 'red'}; 
`
export const DateIndictor = styled(StockIndicator)`
    top: 73%;
    bottom: 0;
    left: 24%;
    width: 30px;
    height: 10px;
    border-radius: 5px;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center; 
    background-color: rgba(0,0,0,.46);
    p {
        font-size: 9px;
        color: white;
        position: relative;
        margin-bottom: 0px;
        font-weight: 700;
    }
    @media(max-width: 768px) {
      left: 32%;
      width: 34px;
      height: 14px;
      top: 70%;
      p {
        font-size: 11px;
      }
    }
`
export const MenuOptionsList = styled.ul<any>`
  font-size: 12px;
  position: absolute;
  overflow: hidden;
  padding: 0px 0px;
  right: 0;
  z-index: 50;
  min-width: 150px;
  top: -140%;
  color: ${props => props.theme.light.colors.labels.pri};
  background-color: ${props => props.theme.light.colors.backgrounds.base.pri};
  border: 1px solid ${props => props.theme.light.colors.separators.pri};
  border-radius: 10px;
  box-shadow: 0 5px 25px #c4c3c337;
  transition: all .15s ease-in-out;
  transform: scale(${props => props.show ? 1 : 0});
`
export const Option = styled.li<any>`
  height: 35px;
  width: 100%;
  display: flex;
  cursor: pointer;
  flex-direction: column;
  justify-content: center;
  position: relative;
  p {
      margin-bottom: 0px;
      font-size: 12px;
      padding: 0px 10px;
      color: black;
      font-weight: 500;
  }
`
export const Glass = styled.div`
    width:100%;
    height: 110%;
    position: absolute;
    top: 0; left: 0;
    background: #00000073;
    z-index: 1000;
    border-radius: inherit;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 11px;
    color: white;
    .icon {
      font-size: 14px;
      width: 14px;
      height: 14px;
      svg {
        stroke: white;
        fill: white;
      }
    }
`
