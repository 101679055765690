import React, { useEffect, useState } from 'react'
import { CheckBox, InfoItem, PermissionLabel } from './styles'
import { Icon } from '../../../../../components/icons/styles'
import { Check } from '../../../../../components/icons'
import { perms } from '../../../../../globals'

const AccodionItem = (props: any) => {

  const { privilege, resource, toggleCallback } = props

  return (
      <InfoItem checked={perms()[resource]?.includes(privilege)} onClick={() => toggleCallback(privilege)}>
          <PermissionLabel>
              <h6>{privilege.toUpperCase()}</h6>
              <p>{`Can ${privilege} ${resource}`}</p>
          </PermissionLabel>
          <CheckBox checked={perms()[resource]?.includes(privilege)} >
              <Icon>
                  <Check />
              </Icon>
          </CheckBox>
      </InfoItem>
  )
}

export default AccodionItem