import { useReactiveVar } from "@apollo/client";
import { ReactElement, useState } from "react";
import { Divider } from "../../../../components/icons/styles";
import { globalInvoice, initInvoice, invCriteria } from "../../../../types/defaults";
import { Invoice as InvoiceModel } from "../../../../types/model";
import { formatFigures, format_date, getNetInvoiceToatal, roundAmount, sortByPaid } from "../../../../utils";
import { Header } from "../../styles";

import { ElasticContainer } from "../containers/elastic";
import InvoiceInfo from "../details";
import { Invoice } from "../invoice";
import { Footer } from "../invoice/styles";
import { InnerList } from "./inner";
import { ItemsCont } from "./styles";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";

export function ShownInvoiceList(props: any): ReactElement {
    // const { count, total, groupId } = props;
    // const { group } = useReactiveVar(invCriteria)
    // const [printerIcon, showPrinterIcon] = useState(false)

    // const [showInfo, setShowInfo] = useState(false)
    const [selectedIvoice, setSelectedInvoice] = useState(initInvoice)

    const { list } = props;
    const showInvoieInfo = (i: any)=>{
        setSelectedInvoice(i)
    }

    const location = useLocation() as any
    const params = new URLSearchParams(location.search)

    return (
        <section
            className="stocksContainer"
        >
            {
                <>
                    <Header>
                        <h6>{params.get("p") !== 'customer' ? format_date(params.get("q")) : params.get("q")}</h6>
                        <h6></h6>
                        <Divider />
                    </Header>
                    {
                        list.map((record: any, j: number) =>
                            <ElasticContainer key={j} contentHeight={55} initHeight={55}> 
                                    <ItemsCont>
                                        <Invoice showInfoCallback={showInvoieInfo} stock={record.stocks[0]} invoice={record} /> {
                                    } 
                                    </ItemsCont>
                            </ElasticContainer>
                        )
                    }
                </>
            }
        </section>
    )
}