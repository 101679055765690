import styled from "styled-components";

export const CardCont = styled.div.attrs({
    className: "card"
})`
border: none;
width: 152px;
display: flex;
margin: 10px 5px;
border-radius: 10px;
position:relative;
cursor: pointer;
flex-direction: column;
@media (max-width: 420px) {
    width: 32%;
    margin: 5px 2px;
}
@media (min-width: 744px) and (max-width:1024px){
   width: 105px;
   margin: 5px 5px;
}
`
export const ImageCont = styled.div`
border: none;
position:relative;
border-radius: 10px;
width: 100%;
height:152px;
overflow:hidden;
img {
    height: 100%;
    width: 100%;
    border-radius: inherit;
    object-fit: cover;
    border: none;
    filter: contrast(1);
    mix-blend-mode: multiply;
    vertical-align: top center;
}

@media (max-width: 420px) {
   height:110px;
   border-radius: 8px;
}
@media (min-width: 744px) and (max-width:1024px){
   height:105px;
   border-radius: 6px;
}
`
export const PriceCont = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    h6 {
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 0px;
        sub {
            font-size: 12px;
            bottom: 0px;
        }
    }
    p {
        margin-bottom: 0px;
        font-size: 12px;
        text-align: right;
        span {
            color: grey;
        }
    }
    @media (min-width: 744px) and (max-width:1024px){
        h6 {
            font-size: 16px;
            font-weight: 700;
            sub {
                font-size: 10px;
                bottom: 0px;
            }
        }
        p {
            font-size: 10px;
            text-align: right;
            span {
                color: grey;
            }
        }
    }
`
export const StatsCont = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    p {
        margin-bottom: 0px;
        font-size: 12px;
        color: grey;
    }
    @media (max-width: 420px) {
    display: none;
    .discount {
        display: none;
    }
}
    @media (min-width: 420px) and (max-width:800px){
        p {
            font-size: 11px;
        }
    }
`
export const RatingsCont = styled.div`
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
`
export const Description = styled.div`
    padding: 10px;
    max-height: 100px;
    position:relative;
    display: flex;
    flex-direction: column;
    .desc {
        color: grey;
        font-size: 12px;
        width: 98%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-bottom: 0px;
    }
    .discount {
        text-decoration: line-through;
        margin-bottom: 0px;
        color: grey;
        font-size: 14px;
    }
    @media (max-width: 420px) {
         padding: 5px;
    .discount {
        display: none;
    }
    p {
        margin-bottom: 0px;
    }
}
    @media (min-width: 420px) and (max-width:1024px){
       padding: 10px 0px;
    }
`
export const LikeCont = styled.div<any>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  z-index: 1;
  top: 6px;
  right: 6px;
  padding: 0px 4px;
  cursor: pointer;
  border-radius: 12px;
  height: 25px;
  min-width: 45px;
  background: #0000001c;
  :hover {
    background-color:  #0000002c;
  }
  p {
    margin-bottom: 0px;
    font-size: 12px;
    color: white;
    font-weight: 600;
  }
  @media(max-width: 420px) {
        p {
           font-weight: 400;
        }
    }
`
