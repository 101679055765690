import { useReactiveVar } from "@apollo/client"
import { showMainCheckOut } from "../../globals"
// import { CheckOut } from "../../pages/details/components/cart"
import { MainCheckOutModalCont } from "./styles"

export const MainCheckOutModal = () => {
    const show = useReactiveVar(showMainCheckOut)
    const closeModal = () => {
        showMainCheckOut(false)
    }
    return (
        <MainCheckOutModalCont onClick={closeModal} opened={show}>
            {/* <CheckOut /> */}
        </MainCheckOutModalCont>
    )
}