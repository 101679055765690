
import { gql } from '@apollo/client'
export const GET_PRODUCTS = gql`
    query getProducts(
        $query: String, 
        $filter: String, 
        $group: String, 
        $offset: Int) {
        products(query: $query,filter: $filter,group: $group, offset: $offset) {
            _id
            records {
                _id
                name
                description
                categories {
                    name
                    category
                }
                size {
                    val
                    unit
                }
                q {
                    val
                    unit
                }
                form
                status
                publish
                mediaUrls
                costPrice
                sellingPrice
                owner
                warningCount
                expiry
                expiryWarning
                added {
                    firstName
                    lastName
                    phone
                    email
                }
                modified {
                    firstName
                    lastName
                    phone
                    email
                }
                createdAt
                updatedAt
            }
            count
            total
        }
}`
export const GET_SHOWN_STOCKS = gql`
    query getShownStocks($p: String,  $q: String,$offset: Int, $r: String,  $s: String) {
        shownStocks(q: $q, p: $p, offset: $offset , r: $r, s: $s) {
                _id
                name
                description
                categories {
                    name
                    category
                }
                size {
                    val
                    unit
                }
                q {
                    val
                    unit
                }
                form
                status
                publish
                mediaUrls
                costPrice
                sellingPrice
                owner
                warningCount
                expiry
                expiryWarning
                added {
                    firstName
                    lastName
                    phone
                    email
                }
                modified {
                    firstName
                    lastName
                    phone
                    email
                }
                createdAt
                updatedAt
            
        }
}`
export const GET_GOODS = gql`
    query getGoods($query: String, $category: String, $subCategory: String, $offset: Int) {
        goods(query: $query,category: $category, subCategory: $subCategory, offset: $offset) {  
                _id
                name
                description
                categories {
                    name
                    category
                }
                size {
                    val
                    unit
                }
                q {
                    val
                    unit
                }
                form
                status
                publish
                mediaUrls
                costPrice
                sellingPrice
                owner 
                warningCount
                expiry
                expiryWarning
                added {
                    firstName
                    lastName
                    phone
                    email
                }
                modified {
                    firstName
                    lastName
                    phone
                    email
                }
                createdAt
                updatedAt
    }
              
}`
export const GET_CATEGORIES = gql`
    query Categories($category: String, $subCategory: String) {
        categories(category: $category, subCategory: $subCategory) {  
            category
            subCategories
        }         
}`
export const GET_PRODUCT = gql`
    query getProduct($id: String) {
        product(id: $id)
             {
                _id
                name
                description
                categories {
                    name
                    category
                }
                size {
                    val
                    unit
                }
                q {
                    val
                    unit
                }
                form
                status
                publish
                mediaUrls
                costPrice
                sellingPrice
                owner 
                warningCount
                expiry
                expiryWarning
                added {
                    firstName
                    lastName
                    phone
                    email
                }
                modified {
                    firstName
                    lastName
                    phone
                    email
                }
                createdAt
                updatedAt
        }
}`
export const GET_PRODUCT_DETAILS = gql`
    query getProductDetails($id: String) {
        productDetails(id: $id) {
            product {
                _id
                name
                description
                categories {
                    name
                    category
                }
                size {
                    val
                    unit
                }
                q {
                    val
                    unit
                }
                form
                status
                mediaUrls
                costPrice
                sellingPrice
                owner {
                    _id
                    info {
                        name
                    }
                }
        }
        related {
             _id
                name
                description
                size {
                    val
                    unit
                }
                mediaUrls
                sellingPrice
        }
    }
}`
export const GET_STORE_ITEMS = gql`
    query getStoreItems($id: String) {
        storeItems(id: $id)
             {
                _id
                name
                description
                categories {
                    name
                    category
                }
                size {
                    val
                    unit
                }
                q {
                    val
                    unit
                }
                form
                status
                mediaUrls
                sellingPrice
                owner
        }
}`
