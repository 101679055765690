
import { useState } from 'react'

// import { CartItem, Invoice, Product as StockModel } from '../../../../types/model'

import ImageItem from '../image'
import { RowItem, RowCont, Cont } from './styles'
import { ActionsMenu } from '../actionsMenu'
import { Badge } from 'react-bootstrap'
import { Divider, Icon } from '../../../../../components/icons/styles'
import { More } from '../../../../../components/icons'
import Permissions from '../permissions'
// import { Divider, Icon } from '../../../../../../components/icons/styles'
// import { More } from '../../../../../../components/icons'
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { format_date, stripTypename } from '../../../../../utils'
import { perms } from '../../../../../globals'

export const Row = (props: any) => {
  const [showMenu, setShowMenu] = useState(false)

  const {staff} =  props;
  const {_id, permissions, info: {dp, name, phone, email}, username, createdAt} = staff

  console.log(staff)


  const popover = (
    <Popover id="popover-basic">
      <Popover.Body>
        <Permissions id={_id} /> 
      </Popover.Body>
    </Popover>
  );

// const openMenu = (e: any) => {
//   e.stopPropagation();
//   setShowMenu(true)
// }

const closeMenu = () => {
  setShowMenu(false)
}

  const setPerms = ()=> {
    console.log('hi')
      perms({
        ...stripTypename(permissions) 
      })
  }


return (
  <Cont>
    <div id="highlight"></div>
    <ImageItem name={name} source='' />
    <RowCont>
      <RowItem >
        <p>{name}</p>
        <p>@{username||'no username'}</p>
      </RowItem>
      <RowItem>
        <p>{phone||'no phone number'}</p>
        <p>{email||'no email'}</p>
      </RowItem>
      <RowItem  popovertarget='opt'>
        <OverlayTrigger trigger="click" placement="right" overlay={popover}>
          <p onClick={setPerms}  style={{ cursor: 'pointer', display: 'inline-block' }} >
              12
          </p>
           
        </OverlayTrigger>
      </RowItem>
      <RowItem id="action">
        <Icon onClick={() => setShowMenu(true)}>
          <More />
        </Icon>
        <p>{format_date(createdAt)}</p>
      </RowItem>
      <Divider />
    </RowCont>
    {
      showMenu &&
      <ActionsMenu  id={staff._id} closeMenuCallback={closeMenu}  {...props} />
    }
  </Cont>
)
}
