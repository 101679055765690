import styled from "styled-components";
export const HeaderMainCont = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
`
export const HeaderCont = styled.div.attrs({
  className: 'container'
})`
  height: 100%;
  max-width: 1000px;
  padding:0px 5px;
`
export const HeaderListItems = styled.ul`
  width: 100%;
  height: 40px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background: white;
  padding: 0px;
  a {
      text-decoration: none;
      color: inherit;
    h6 {
      margin-bottom: 0px;
      font-size: 10px;
      position: relative;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-weight: 700;
  }
}
 
  
`;
export const Item = styled.li<any>`
  height: 100%;
  padding: 0px 2px;
  cursor: pointer;
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  color:  "rgba(83, 100, 113, 0.267)";
  overflow: hidden;
  a h6 {
    font-size: 12px;
    font-weight: 500;
  }
  &:hover {
    color: #05af2f;
  }
  .icon {
    width: 12px;
    height: 12px;
    svg {
      stroke: grey;
    }
  }
`;