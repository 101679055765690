// import React from 'react'
import { Footer, Header, ItemsCont } from './styles';
import { Row } from '../row';
import { Divider } from '../../../../../components/icons/styles';
import { format_date } from '../../../../../utils';

const RowGroup = (props: any) => {
    const {  items, groupId } = props;

    return (
        <>
            <Header>
                <h6>{format_date(groupId)}</h6>
                {/* {
                    count - 5 > 0 &&
                    <h6>
                        <Link to={generateLink(group, groupId)}>SHOW ALL</Link>
                    </h6>
                } */}
                <Divider />
            </Header>
            {
                items.map((staff: any) => (
                    <ItemsCont key={staff._id}>
                        <Row
                            {...props}
                            staff={staff} 
                    />
                    </ItemsCont>
                )
                )
            }
            <Footer id='footer' className='container'>
              
            </Footer>
        </>
    )
}

export default RowGroup