import React from 'react'
import { Divider } from '../../../../../components/icons/styles'
import Card from '../../cards/relateds'
import { Container } from '../moreInfo/styles'
import { Header, RelatedCont } from './styles'
import { Link } from 'react-router-dom'

const RelatedItems = (props:any) => {
    const {related} = props
    return (
        <Container>
            <Header>Related Items</Header>
            <RelatedCont>
                {
                    related.map((product: any) => (
                        <Card key={product._id} product={product} />
                    ))
                }
            </RelatedCont>
        </Container>

    )
}

export default RelatedItems