import styled from "styled-components";
export const Container = styled.div.attrs({
    className: "container",
})`
  height: auto;
  width: 100%;
  overflow: visible;
  background: yellow;
  padding-top: 0px;
  max-width: 1000px;
  position: relative;
  margin: 0 auto;
  margin-bottom: 0px;
  bottom: 80px;
  @media (max-width: 420px) {
    columns: 165px;
    column-gap: 5px;
  }
`;
export const CallToActionCont = styled.div`
  width: 240px;
  height: 50px;
  right: 0px;
  bottom: -122px;
  z-index: 1000;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: end;
  padding: 0px 20px 0px 0px;
  .cancel {
    font-size: 13px;
    margin-bottom: 0px;
    font-weight: 500;
    color: #222323
    margin-right: 20px;
    position: relative;
    right: 10px;
    cursor: pointer;
    :hover {
        text-decoration: underline;
    }
  }
`;
export const Button = styled.button<any>`
  height: 40px;
  width: 80px;
  border-radius: 30px;
  position: relative;
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  border: 1px solid lightgrey;
  background: white;
  font-size: 12px;
  font-weight: 500;
  span {
    font-size:  18px;
    margin-right: 5px;
    position: relative;
    bottom: 1px;
  }

  &:hover {
    border: none;
    background-color: ${(props) => (props.buy ? "#10c34f" : "#1ed760")};
    color: ${(props) => (props.buy ? "white" : "white")};
  }
  :hover {
    svg {
      stroke: "white";
    }
  }
 
`;

export const Header = styled.header`
  width: 99%;
  height: 200px;
  top: 10px;
  margin: auto;
  border-radius: 8px;
  position: relative;
  margin-bottom: 20px;
  @media(max-width: 768px) {
    height: 100px;
  }
`;
export const Banner = styled.aside`
  width: 99%;
  height: 100%;
  top: 0px;
  overflow: hidden;
  margin: auto;
  border-radius: inherit;
  position: relative;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  
`;
export const ImageCont = styled.div`
  border: none;
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 8px;
  img {
    height: 100%;
    width: 100%;
    border-radius: inherit;
    object-fit: cover;
    border: none;
    mix-blend-mode: multiply;
    filter: contrast(1);
    vertical-align: top center;
  }
  
`;

export const Glass = styled.div`
    width:100%;
    height: 110%;
    position: absolute;
    top: 0; left: 0;
    background: #00000073;
    z-index: 1000;
    border-radius: inherit;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 11px;
    color: white;
`

export const CustomerLogo = styled.div`
  border: none;
  position: absolute;
  top: 55%;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 0px;
  width: 120px;
  height: 120px;
  z-index: 1000;
  overflow: hidden;
  border-radius: 50%;
  border: 3px solid white;
  background: whitesmoke;
  img {
    height: 100%;
    width: 100%;
    border-radius: inherit;
    object-fit: cover;
    border: none;
    mix-blend-mode: multiply;
    filter: contrast(1);
    vertical-align: top center;
  }
   @media(max-width: 768px) {
    top: 65%;
    left: 20px;
    width: 100px;
    height: 100px;
  }
`;

export const LinkItem = styled.li<any>`
  height: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 0px;
  margin: 0px 16px;
  cursor: pointer;
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  &:first-child {
    margin-left: 0px;
  }
  :hover {
    p {
      color: black;
    }
  }
  p {
    margin-bottom: 0px;
    font-size: 12px;
    font-weight: 600;
    color: ${(props) => (props.active ? "black" : "rgb(83, 100, 113)")};
  }
  border-bottom: 2px solid  ${(props) => (props.active ? "#1b1c1b" : "initial")};
`;

