import React from 'react'
import RecomendedCard from '../../../cards/recomended'
import YouAlsoLikeCard from '../../../cards/youmaylike'
import { Container, Header, ItemsList } from './styles'
const data = [
    {
        id: 1234567890,
        name: 'Shower Jell',
        price: 4700,
        likes: '4K',
        img: 'https://i.pinimg.com/474x/cf/1c/7b/cf1c7b2f2aba71de27dc25ba07631fe8.jpg'
    },
    {
        id: 3320000,
        likes: '100K+',
        price: 42800,
        name: 'Shower Jell',
        img: 'https://i.pinimg.com/474x/c4/77/f2/c477f24804a3f368ccb7ad764e258b4e.jpg'
    },
    {
        id: 3320000,
        price: 600,
        likes: '1K',
        name: 'Shower Jell',
        img: 'https://images.unsplash.com/photo-1694101493127-eca6dfef5011?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTh8fGNoaXBzfGVufDB8MHwyfHx8Mg%3D%3D&auto=format&fit=crop&w=700&q=60'
    },

    {
        id: 3320000,
        likes: '84K+',
        price: 47500,
        name: 'Shower Jell',
        img: 'https://i.pinimg.com/474x/34/64/d2/3464d2df36a5d592222054beb7e3be77.jpg'
    },
    {
        id: 3320000,
        likes: '2.7K',
        price: 692000,
        name: 'Shower Jell',
        img: 'https://i.pinimg.com/564x/17/e2/c5/17e2c55762f8ecf5ea0a6cd5ba5f3ed9.jpg'
    },
    {
        id: 3320000,
        likes: '600',
        price: 1400,
        name: 'Shower Jell',
        img: 'https://i.pinimg.com/474x/7c/5c/f8/7c5cf8c1c3078da441f9ec04e2f4d7e1.jpg'
    },
    {
        id: 3320000,
        likes: '7K',
        price: 7000,
        name: 'Shower Jell',
        img: 'https://i.pinimg.com/474x/47/c0/00/47c0005ca7f8608e0a68f579ef75213d.jpg'
    },
    {
        id: 3320000,
        price: 1100,
        likes: '10K',
        name: 'Shower Jell',
        img: 'https://i.pinimg.com/474x/63/d5/81/63d5819dc3b4ad75723dcdb2a0c6df0b.jpg'
    },
    {
        id: 3320000,
        price: 800,
        name: 'Shower Jell',
        img: 'https://i.pinimg.com/564x/21/28/86/212886aee0feb46375f3417035986111.jpg'
    },
    {
        id: 3320000,
        price: 170,
        likes: '24',
        name: 'Shower Jell',
        img: 'https://i.pinimg.com/564x/3e/2b/ea/3e2beabf3e7c6154d2c83c8d95b2004f.jpg'
    },
    {
        id: 3320000,
        likes: '45K+',
        price: 70600,
        name: 'Shower Jell',
        img: 'https://i.pinimg.com/474x/b1/55/1e/b1551e7b6c4933718a540b6c0d6c9ae9.jpg'
    },
]
const Recomendation = (props:any) => {
    const {related} =props;
    return (
        <Container>
            <Header>For you</Header>
            <ItemsList>
                {
                    related.map((d: any) => (
                        <RecomendedCard key={Math.random()} item={d} />
                    ))
                }
            </ItemsList>
        </Container>

    )
}

export default Recomendation