import styled from "styled-components";
export const Container = styled.div<any>`
  height: 100%;
  width: 100%;
  position: absolute;
  display: flex;
  justify-content: center;
  padding: 0px 10px;
  padding-bottom: 10px;
  overflow: hidden;
  transition: all 0.15s ease-in-out;
  left: ${(props) => (props.in ? 0 : -100)}%;
`;
export const Card = styled.div<any>`
  position: relative;
  height:100%;
  z-index: 300000000000000;
  width: 300px;
  border-radius: 10px;
  display: grid;
  grid-template-rows: 40px 1fr auto;
  overflow: hidden;
  background-color: ${props => props.theme.mode === 'dark' ? props.theme.dark.colors.backgrounds.base.pri : props.theme.light.colors.backgrounds.base.pri
  };
  transition: all .4s ease-in-out;
  right: ${props => props.slideIn ? -70 : -300}%;
  @media(max-width: 768px) {
    width: 100%;
    right: 100%;
    border-radius: 16px;
    height: 90%;
    top: 10%;
    right: 0%;
    border-radius: 16px 16px 0px 0px;
  }
`;

export const BodyCont = styled.div<any>`
    height:100%;
    width: 100%;
    position: relative;
    overflow-x: hidden;
`;
export const Body = styled.div<any>`
  height:100%;
  width: 100%;
  overflow: hidden;
  overflow-x: hidden;
  position: absolute;
  transition: all .15s ease-in-out;
  left: ${props => props.in ? 100 : 0}%;
  background-color: #ffffff;
 `;

export const InfoItemHeader = styled.header`
  width: 100%;
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  h6 {
    text-align: left;
    margin-bottom: 10px;
    font-size: 12px;
    margin-top: 0px;
    font-weight: 500;
    color: rgb(0, 0, 0);
    display: flex;
  }
  .icon {
      width: 10px;
      height: 10px;
  }
`;
export const FormItem = styled.form`
  width: 100%;
  height: 94vh;
  padding-bottom: 30px;
  position: relative;
  /* h6 {
    font-size: 28px;
    width: 100%;
    font-weight: 600;
    text-align: center;
    margin-bottom: 20px;
    span {
      font-size: 12px;
    }
  } */
`;
export const ListItemsCont = styled.div<any>`
    left:0%;
    width:100%;
    overflow: hidden;
    position: absolute;
    overflow-x: hidden;
    max-height: cal(100%-50px);
    transition: all .2s ease-in;
`
export const Content = styled.div<any>`
    width:100%;
    max-height: 80vh;
    /* overflow: scroll; */
    position: relative;
    overflow-x: hidden;
`
export const InfoSection = styled.div`
  width: 100%;
  padding: 10px 0px;
  margin: 0px;
  position: relative;
  #total--cont {
    display:flex;
    flex-direction: row;
    align-items: center;
  }
`;
export const MethodOpts = styled.div<any>`
  width: 100%;
  padding: 10px 0px;
  margin: 0px;
  height: 32px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  h6 {
    color: rgba(60, 60, 67, 0.6);
  }
  .opt {
    height: 32px;
    width: 180px;
    display: grid;
    grid-template-columns: 30% 40% 30%;
    padding: 0px 4px;
    align-items: center;
    border-radius: 8px;
    cursor: pointer;
    background: whitesmoke;
    p {
      height: 26px;
      border-radius: 6px;
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: center;
      padding: 0px 4px;
    }
    .selected {
       background: white;
       color: black;
    }
    .icon {
      width: 12px;
      height: 12px;
      margin-left: 5px;
      svg {
        fill: #929191;
      }
    }
  }
  .check {
    height: 24px;
    width: 34px;
    padding: 4px;
    display: flex;
    align-items: center;
    border-radius: 17px;
    position: relative;
    cursor: pointer;
    border-width: ${props=>props.checked ? 0 :1}px;
    background: ${props => props.checked ? ' rgb(35, 35, 35)' : '#d0d0d0'};
    ::before {
      content: '';
      width: 16px;
      height: 16px;
      background: ${props => props.checked ? ' white' : '#f5f4f4'};
      position: absolute;
      transform: translateX(${props => props.checked ? 60 : 0}%);
       transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
      border-radius: 50%;
    }
    .icon {
      width: 12px;
      height: 12px;
      margin-left: 5px;
      display: ${props => props.checked ? 'flex':'none' };
      svg {
        fill: #262626;
        stroke: #1f1e1e;
      }
    }
  }
`;


export const InfoItem = styled.div`
  width: 100%;
  padding: 0px 0px;
  margin: 0px;
  position: relative;
  display: flex;
  align-items: start;
  flex-direction: column;
  justify-content: space-between;
  font-size: 13px;
  .edit {
    float: right;
    position: relative;
    text-decoration: underline;
    align-self: end;
    color: black;
    cursor: pointer;
    font-size: 11px;
  }
  p {
    margin: 0px;
    width:  100%;
    position: relative;
    //font-weight: 500;
    color: rgba(63, 63, 64, 0.8);
    span {
      position: absolute;
      right: 0;
      text-align: right;
      span {
        position:relative;
        color: rgba(60, 60, 67, 0.3);
      }
    }
    .form-control {
      border: none;
      outline: none;
      padding: 0px 0px;
      border-radius: 0px;
      line-height: .7;
      margin-bottom: 3px;
      color: rgba(52, 52, 53, 0.9);
      &:focus {
        box-shadow: none !important;
        background: whitesmoke;
      }
      &::placeholder {
        color: #cacaca;
      }
      font-size: 13px;
    }
  }
  label {
    color: rgba(60, 60, 67, 0.6);
    margin-bottom: -2px;
    font-size: 12px;
  }
  .subinfo {
    //color: rgba(60, 60, 67, 0.3);
    font-size: 12px;
    font-weight: normal;
  }
  .total {
    color: #000000;
    font-size: 14px;
    font-weight: 500;
  }
  h6 {
    margin: 0px;
    font-size: 12px;
    font-weight: normal;
    text-align: left;
    margin-top: 0px;
    color: rgba(60, 60, 67, 0.3);
  }
`;
export const Header = styled.header`
  height: 40px;
  width: 100%;
  background: white;
  position: relative;
  display: flex;
  /* grid-template-columns:25px 1fr;  */
  align-items: center;
  justify-content: space-between;
  padding: 0px 10px;
  h4 {
    font-size: 14px;
    width:100%;
    text-align: center;
    font-weight: 600;
    margin-bottom: 0px;
  }
  p {
    font-size: 12px;
    width:auto;
    text-align: right;
    font-weight: 600;
    margin-bottom: 0px;
    text-decoration: underline;

  }
   .icon {
    width: 20px;
    height: 20px;
    cursor: pointer;
    svg {
        stroke:#343534;
    }
  }
`

export const Footer = styled(Header)`
  padding: 0px 10px;
  display: grid;
  grid-template-columns: 1fr 25px;
  align-items: center;
  p {
    margin-bottom: 0px;
    width: 100%;
    font-size: 14px;
    text-align: right;
    font-weight: bold;
    position: relative;
    cursor: pointer;
    &:hover {
      color: #25ec78;
    }
  }
  .icon {
    width: 14px;
    height: 14px;
    svg {
        stroke:#333433;
    }
  }
`

export const Total = styled.div`
    height: 40px;
    width: 100%;
    padding: 5px 16px;
    margin: auto;
    position: relative;
    display: flex;
    align-items: center;
    overflow: visible;
    justify-content: space-between;
    span {
        position: absolute;
        right: 0px;
    }
     p {
       &:first-child {
         color: lightgrey;
         padding-left: 0px;
       }
       &:last-child {
           padding-left: 0px;
           font-size: 16px;
           color: #25ec78;
       }
       padding: 0px 0px;
       margin-bottom: 0px;
       font-size: 16px;
       font-weight: 500;
       text-align: right;
       
     }
  `
export const MenuOptionsList = styled.ul<any>`
  font-size: 12px;
  position: absolute;
  overflow: hidden;
  padding: 0px 0px;
  right: 0;
  z-index: 50;
  min-width: 120px;
  top: -100%;
  color: ${props => props.theme.light.colors.labels.pri};
  background-color: ${props => props.theme.light.colors.backgrounds.base.pri};
  border: 1px solid ${props => props.theme.light.colors.separators.pri};
  border-radius: 10px;
  box-shadow: 0 5px 25px #c4c3c337;
  transition: all .15s ease-in-out;
  transform: scale(${props => props.show ? 1 : 0});
`
export const Option = styled.li<any>`
  height: 35px;
  width: 100%;
  display: flex;
  cursor: pointer;
  flex-direction: column;
  justify-content: center;
  position: relative;
  p {
      margin-bottom: 0px;
      font-size: 12px;
      padding: 0px 10px;
      color: black;
      font-weight: 500;
  }
  :hover {
    background: whitesmoke;
  }
`

export const Toast = styled.div<any>`
  height: 45px;
  width: 100%;
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  right: 0px;
  top: 0;
  background: #25ec78;
  z-index: 100000;
  transition: all .4s ease-in-out;
  top: ${props => props.done ? 0 : -100}%;
  .msg {
    font-size: 12px;
    color: white;
    margin-bottom: 0px;
  }
`
export const PrintOption = styled.div<any>`
  height: 100%;
  width: 60%;
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: space-between;
  position: relative;
  background: #25ec78;
`
export const Print = styled.button`
    border: none;
    outline: none;
    box-shadow: none;
    height: 70%;
    width: 150px;
    margin-right: 10px;
    border-radius: 6px;
    font-size: 12px;
    color: black;
    background: #03d35a;
    cursor: pointer;
    position: relative;
    font-weight: bold;
    :hover {
      background: #0dc75a;
    }
`
export const Cancel = styled.button`
    border: none;
    outline: none;
    box-shadow: none;
    width: 100px;
    font-size: 12px;
    background: inherit;
    border-radius: 0px;
    cursor: pointer;
    position: relative;
    font-weight: bold;
    p {
      margin-bottom: 0px;
      color: black;
      text-decoration: underline
    }
`
export const PaymentChanger = styled.div`
    width: 100%;
    height: 48px;
    border: 1px solid whitesmoke;
    border-radius: 10px;
    position:absolute;
    font-size: 12px;
    top: 3px;
    left: 0;
    background: whitesmoke;
    z-index: 1000;
    display: grid;
    grid-template-columns: 40% 40% 1fr;
    .new--price {
      align-items: end;
      p {
        text-align: right;
      }
    }
    box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
`
export const PaymentsCont = styled.div`
    width: 100%;
    height: 45px;
    border-radius: 10px;
    position:relative;
    display: flex;
    flex-direction: column;
    padding: 10px;
    align-items: start;
    justify-content: center;
    #vdivider {
      height: 50%;
      top: 30%;
      background: #d7d7d7;
    }
    p {
      margin-bottom: 0px;
      font-weight: normal;
    }
    h6 {
      color: black;
      font-size: 14px;
    }
    input {
      border: none;
      outline: none;
      padding: 0px 0px;
      border-radius: 0px;
      text-align: right;
      margin-bottom: 0px;
      color: rgba(52, 52, 53, 0.9);
      background: inherit;
      &:focus {
        box-shadow: none !important;
        background: whitesmoke;
      }
      &::placeholder {
        color: #cacaca;
      }
      font-size: 14px;
    }
`
