import styled from "styled-components";

export const ActionList = styled.ul`
    list-style: none;
    margin: 0;
    padding-left: 0;
     width: 250px;
    height: 450px;
    left: -40px;
    top: 45px;
    border-radius: 8px;
    z-index: 750000;
    display: flex;
    flex-direction: column;
    position: absolute;
    background: white;
    padding: 0px 10px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
`
export const ListItem = styled.li`
    width: 100%;
    height: 35px;
    position: relative;
    border-radius: 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
    :hover { 
        background: whitesmoke;
    }
    a {
        color: inherit;
        text-decoration: none;
        font-size: 13px;
        width: 100%;
        height: 30px;
        display: flex;
        align-items: center;
        position: relative;
        padding: 0px 5px;
        .icon {
            width: 14px;
            height: 14px;
            margin-right: 5px;
        }
        #user {
             width: 16px;
            height: 16px;
            margin-right: 5px;
            stroke: grey;
        }
        #stocks {
             width: 12px;
            height: 12px;
        }
        #sales {
             width: 12px;
            height: 14px;
        }
        #orders {
            width: 14px;
            height: 14px;
        }
        #payment {
            width: 14px;
            height: 14px;
        }
        #likes {
            width: 22px;
            height: 22px;
            svg {
                stroke: grey;
            }
        }
        #orders {
            width: 14px;
            height: 14px;
        }
        #chart {
            width: 16px;
            height: 16px;
        }
    }
    #signout {
        width: 16px;
        height: 16px;
        margin-right: 5px;
    }
    #inv {
        width: 18px;
        height: 18px;
    }
   &:last-child {
        margin-top: auto;
        margin-bottom: 5px;
        display: flex;
        align-items: center;
        position: relative;
        padding: 0px 5px;
        font-size: 13px;
   }
`