import React, { ReactElement, useState, useEffect, useRef, Fragment } from 'react'

import { Card, Footer, Total, Body, Header, ListItemsCont, BodyCont, PrintOption, Toast, Print, Cancel } from './styles';
// import { useReactToPrint } from 'react-to-print';
import { ListItem } from './item';

import { useReactiveVar } from '@apollo/client';
// import OfflineCustomerForm from '../forms/customer/offline';

import Cart from './cart';
import { cart, showCheckOutModal } from '../../../globals';
import { CartItem } from '../../../types/model';
import { Divider, Icon, IconBtn } from '../../icons/styles';
import { Chevron } from '../../icons';
import Payment from './payment';
import Receipt from './receipt';

import ReactToPrint from 'react-to-print';
import { PrintBtn } from './receipt/styles';
import { genTransId, getLocalStore } from '../../../utils';

export function LocalCheckOut(): ReactElement {

    const componentRef:any = useRef();
    const printRef:any = useRef();

    const showCheckOut = useReactiveVar(showCheckOutModal);
    const myCart:any = useReactiveVar(cart);

    let [invoice, setInvoice] = useState(myCart)
    const [checkOut, setCheckOut] = useState(false)
    const [tid, setTxnId] = useState('')
    const [account, setAccount] = useState(null)
    const [done, setDone] = useState(false)

    useEffect(() => {
        setInvoice(myCart)
    }, [myCart])
    useEffect(() => {
        // setTransId(genTransId())
        // console.log(genTransId())
        const a = getLocalStore()
        if (a) {
            setAccount(a);
        }
    }, [])


    const getCartTotal = (items: CartItem[]) => {
        return items
        .reduce((total: number, i: CartItem) => total + i.item.sellingPrice * i?.quantity, 0)
    }

    const toggleCheckOut = () => {
        setCheckOut(!checkOut)
    }

    const handleClick = (e: React.SyntheticEvent) => {
        e.stopPropagation();
    }

    const handlePrintButtonClick = (e:any) => {
        e.stopPropagation()
        setDone(false);
        printRef.current.click();
    };

    const reactToPrintTrigger = React.useCallback(() => {
        return <PrintBtn ref={printRef} className='my-printer'>Print</PrintBtn>;
    }, []);


    const pageStyle = `
        @page {
        size: auto;
        margin: 15mm;
        }
        @media print {
        body {
            -webkit-print-color-adjust: exact;
        }
        .printable-content {
            font-size: 16px;
            color: black;
        }
        no-print {
            display: none;
        }
        .product,.price {
            font-size: 12px;
            font-weight: 400;
        }
        #date-id {
            font-size: 11px;
        }
        #method span {
            font-size: 10px;
        }
        #remarks p{
            font-size: 12px;
            font-weight: 400;
        }
        #time {
            margin: 5px 0px;
        }
        #date, #timestamp  {
            font-size: 11px;
            color: grey;
        }

        #receipt {
            left: 0;
        }
        #receipt ul {
            margin-bottom: 0px;
            }
        }
  `;

    return (
        <Card
            slideIn={showCheckOut}
            onClick={(e: React.SyntheticEvent) => handleClick(e)}>
            <Toast done={done}>
                <p className='msg'> </p>
                <PrintOption >
                    <Cancel onClick={()=>setDone(false)}>
                        <p>Not now</p>
                    </Cancel>
                    <Print onClick={(e: React.SyntheticEvent) => handlePrintButtonClick(e)}>Print invoice</Print>
                </PrintOption>
            </Toast>
           
            <Header>
                {
                    <>
                        <IconBtn title='Back' size={30} onClick={toggleCheckOut} >
                            {
                            checkOut && 
                            <Icon rot={90}>
                                <Chevron />
                            </Icon>
                            }
                        </IconBtn> 
                        {
                            checkOut ? 
                            <>
                                <h4>Cart({invoice.stocks.length}) </h4>
                                <p>{(invoice._id && checkOut) ? '' : ''}</p>
                            </>
                            :
                            <h4> Checkout </h4>
                        }
                    </>
                }
                <Divider />
            </Header>
            <BodyCont>
               <Cart invoice={invoice} checkOut={checkOut} />
                <Payment 
                    in={checkOut}
                    tid={tid}
                    invoice={invoice}
                    updateInvoiceCallback ={setInvoice}
                    doneCallback={setDone}
                    updateTxnIdCallback ={setTxnId}
                    total={getCartTotal(invoice.stocks)}
                />
            </BodyCont>
            {
                checkOut ?
                    <></>
                    :
                    <Footer>
                        <p onClick={toggleCheckOut}>Continue</p>
                        <Icon onClick={toggleCheckOut} rot={-90}>
                            <Chevron />
                        </Icon>
                    </Footer>
            }
            <div>
                <ReactToPrint
                    trigger={reactToPrintTrigger}
                    content={() => componentRef?.current}
                    pageStyle={pageStyle}
                />
                <Receipt account={account} tid={tid} invoice={invoice} ref={componentRef} />
            </div>
        </Card>
    )
}
 