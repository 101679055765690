import { gql } from '@apollo/client'

export const GET_STAFF = gql`
    query staff {
        staff {
            _id
            records {
                _id
                info {
                    dp
                    name
                    phone
                    email
                    bio
                    storeId
                    category
                }
                permissions {
                    products
                    expenses
                    account
                    staff
                    sales
                    analytics
                }
                username
                createdAt
                updatedAt
            }
        }
    }
`