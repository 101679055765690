import styled from "styled-components";

export const ImageCont = styled.div`
  width: 45px;
  height: 45px;
  border: none;
  position: relative;
  border-radius: 6px;
  position: relative;
  z-index: 1;
  background: #e3e3e3;
  img {
    height: 100%;
    width: 100%;
    border-radius: inherit;
    object-fit: cover;
    border: none;
    mix-blend-mode: multiply;
    filter: contrast(1);
    vertical-align: top center;
  }
  .icon {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    padding: 3px;
    z-index: 9999;
    position: absolute;
    cursor: pointer;
    background: #0606064f;
    svg {
      stroke: red;
    }
    :hover {
      background: #f5272798;
    }
  }
  @media (max-width: 768px) {
    width: 55px;
    height: 55px;
  }
`;