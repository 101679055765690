import { useQuery } from '@apollo/client'
import React, { ReactElement } from 'react'
import { useLocation } from 'react-router-dom'
import { NavLink } from 'react-router-dom'
import { Outlet } from 'react-router-dom'
import { Phone, Location, Calender } from '../../components/icons'
import { Divider, Icon } from '../../components/icons/styles'
import { PageLoader } from '../../components/loaders'
import { ChatModal } from '../../components/modals/chat'
import { GET_MY_ACCOUNT } from '../../graphql/queries'
import { format_date } from '../../utils'
import { Main, AccountCont, Address, BizInfoCont, BizName, ContactsCont, ContactsItem, Container, FollowsCont, Item, ItemsCont, LinkItem, NavLinks, CustomerPriInfo, Bio } from './styles'
import StoreHeder from './storeheader'

interface Props {

}


const Settings = ({ }: Props): ReactElement => {
    const location = useLocation();

    const { loading, data, refetch, error } = useQuery(GET_MY_ACCOUNT, {
        fetchPolicy: "network-only"
    })

    if (error) {
        console.log({...error});
    }


    return (
        <Main>
            {data ? <StoreHeder info={data.myAccount.info} />  : null}
            <Container>
                <> {
                    data ?
                        <>
                            {/* <AccountCont>
                            </AccountCont> */}
                            <CustomerPriInfo>
                                <BizInfoCont>
                                    <BizName>
                                        {data.myAccount.info?.name ?? '-'}
                                    </BizName>
                                    <Address>
                                        @{data.myAccount.username ?? 'username'}
                                    </Address>
                                </BizInfoCont>
                                <Bio>
                                   {
                                        data?.myAccount.info?.bio ?? 'Add Bio'
                                }
                                </Bio>
                                <ContactsCont id='contact'>
                                        <ContactsItem>
                                            <Icon>
                                                <Phone />
                                            </Icon>
                                            <p>
                                                {data?.myAccount.phone ?? '-'}
                                            </p>
                                        </ContactsItem>
                                        <ContactsItem>
                                            <Icon>
                                                <Location />
                                            </Icon>
                                            <p className='address'>
                                                {`${data.myAccount.addresses[0]?.street ?? ''}  `}
                                                {/* <br /> */}
                                            {`${data.myAccount.addresses[0]?.city ?? ''}, ${data.myAccount.addresses[0]?.state ?? ''} State Nigeria`}
                                            </p>
                                        </ContactsItem>
                                        <ContactsItem>
                                            <Icon>
                                                <Calender />
                                            </Icon>
                                            <p>
                                                Joined {format_date(data?.myAccount.createdAt)}
                                            </p>
                                        </ContactsItem>
                                    {/* </Engagements> */}
                                </ContactsCont>
                                {/* <FollowsCont>
                                    <ItemsCont>
                                        <Item>
                                            <p>0K <span>Followers</span></p>
                                        </Item>
                                        <Item>
                                            <p>0K <span>Following</span></p>
                                        </Item>
                                        <Item>
                                            <p>0K <span>Likes</span></p>
                                        </Item>
                                    </ItemsCont>
                                </FollowsCont> */}
                            </CustomerPriInfo>
                        </>
                        :
                        loading ?
                        <PageLoader />
                        :
                        null
                }
                </>
                <NavLinks>
                    {/* <Divider bottom={100} /> */}
                    <NavLink to='/account' title='Account'>
                        <LinkItem active={location.pathname === '/account'}>
                            <p>ACCOUNT</p>
                        </LinkItem>
                    </NavLink>
                    <NavLink to='address' title='Address'>
                        <LinkItem active={location.pathname === '/account/address'}>
                            <p>ADDRESS</p>
                        </LinkItem>
                    </NavLink>
                    <NavLink to='subscriptions' title='Subscription'>
                    {/* <LinkItem>
                        <p>SUBSCRIPTION</p>
                    </LinkItem> */}
                    </NavLink>
                    <NavLink to='staff' title='Staff'>
                        <LinkItem active={location.pathname === '/account/staff'}>
                            <p>STAFF</p>
                        </LinkItem>
                    </NavLink>
                    <Divider />
                </NavLinks>
                <Outlet />
            </Container>
            {/* <ChatModal /> */}
        </Main>
    )
}

export default Settings
