import styled from "styled-components"

export const CardCont = styled.div.attrs({
    className: "card"
})`
border: none;
border-radius: 10px;
display: flex;
flex-direction: column;
width: 100%;
margin: 5px 0px;
position:relative;
break-inside: avoid;
cursor: pointer;
`
export const ImageCont = styled.div`
border: none;
position:relative;
border-radius: 10px;
width: 100%;
background: #eeeeef; 

/* max-height:200px ; */
overflow:hidden;
break-inside: avoid;
img {
   max-height:180px;
    width: 100%;
    border-radius: inherit;
    object-fit: cover;
    border: none;
    mix-blend-mode: multiply;
    filter: contrast(1);
    vertical-align: top center;
}
`
export const Description = styled.div`
  padding: 10px;
  max-height: 100px;
  position: relative;
  display: flex;
  flex-direction: column;
      /* background: lightpink; */
  .desc {
    color: grey;
    font-size: 12px;
    width: 98%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 0px;
  }
  .discount {
    /* text-decoration: line-through; */
    margin-bottom: 0px;
    color: #3b3a3a;
    font-size: 13px;
     width: 98%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  @media (min-width: 744px) and (max-width: 1024px) {
    padding: 10px 5px;
  }
`