import { useMutation } from '@apollo/client'
import { stripTypename } from '@apollo/client/utilities'
import React, { useEffect, SyntheticEvent, useState, useRef } from 'react'
// import Button from '../../buttons/checkout'

import { Container, MethodOpts, FormItem, InfoItem, InfoItemHeader, InfoSection, Option, MenuOptionsList, PaymentChanger, PaymentsCont} from './styles'
import { Divider, Icon, VDivider } from '../../icons/styles'
import { Plus, CheveronUpDown, Check } from '../../icons'
import { CHECK_OUT } from '../../../graphql/mutations'
import { GET_PRODUCTS } from '../../../graphql/queries'
import { queryOptions } from '../../../types/defaults'
import { formatMoney, genTransId, getCartTotal } from '../../../utils'
import Button from './forms/buttons/submit'
import PaymentMenthods from './mehtods'
import { DoneBtn } from './forms/buttons/styles'
// import Button from './form/buttons/submit'

const Payment = (props: any) => {
    const { tid, total, invoice: i, updateInvoiceCallback, doneCallback, updateTxnIdCallback } = props

    const [invoice, setInvoice] = useState(i);
    const [customer, setCustomer] = useState(i.customer);
    const [showMenu, setShowMenu] = useState<any>(false)
    const [newPayment, setNewPayment] = useState<any>('')
    const [editPayment, setEditPayment] = useState<any>(false)


    useEffect(() => {
        setInvoice({
            ...i,
            recieved: i.recieved > 0 ? i.recieved : +getCartTotal(i.stocks).toFixed(0)
        })
        console.log(i.recieved)
        setCustomer(i.customer)
        //TODO: fix this re-render
    }, [i])
    useEffect(() => {
        setCustomer(i.customer)
        //TODO: fix this re-render
    }, [i])

    let newInvoice: any = {}

    const handleClearInput = (name: string) => {
        setInvoice({
            ...invoice,
            customer: {
                ...customer,
                [name]: ''
            }
        })
    }

    const handleAmountChange = (e: any) => {
        setInvoice({
            ...invoice,
            recieved: +e.target.value
        })
    }
    const handleChange = (e: any) => {
        e.persist();
        setCustomer({
            ...customer,
            [e.target.name]: e.target.value
        })

    }
    const select = (method: string) => {
        //e.stopPropagation();
        setInvoice({
            ...invoice,
            paymentMethod: method
        });
        setShowMenu(false)
        updateInvoiceCallback({
            ...invoice,
            paymentMethod: method
})
    }
    const toggleInvoiceStatus = () => {
        // window.alert('hi')
        // console.log('Hi')
        setInvoice({
            ...invoice,
            completed: !invoice.completed
        });
        updateInvoiceCallback({
            ...invoice,
            completed: !invoice.completed
        })
    }
    const updatePayment = () => {
        // window.alert('hi')
        // console.log('Hi')
        // setInvoice({
        //     ...invoice,
        //     recieved: +newPayment
        // });
        updateInvoiceCallback({
            ...invoice,
            recieved: +newPayment
        })
        setEditPayment(false);
    }

    const [checkOut, { error, loading, data }] = useMutation(CHECK_OUT, {
        update: (cache, { data: { checkOut } }) => {
            if (!props.invoice._id) {
                let result: any = cache.readQuery({
                    query: GET_PRODUCTS,
                    variables: {
                        ...queryOptions()
                    }
                })
                checkOut.stocks.forEach(((stock: any) => {
                    result = {
                        ...result,
                        products: result.products.map((p: any) => ({
                            ...p,
                            records: p.records.map((record: any) => (record._id === stock.item._id) ?
                                ({
                                    ...record,
                                    q: {
                                        ...record.q,
                                        val: record.q.val - stock.quantity
                                    }
                                })
                                :
                                record
                            )
                        }))
                    }
                }))
                updateTxnIdCallback(checkOut.tid)
                cache.writeQuery({
                    query: GET_PRODUCTS,
                    data: {
                        products: result.products
                    }
                });
            }
            doneCallback(true)
        }
    });

    if (error) console.log({ ...error })

    const handleSubmit = (e: any) => {
        e.preventDefault();
        e.stopPropagation();
        if (loading) {
            return
        }
        let { stocks } = invoice
        stocks = stocks.map((stock: any) => {
            const {
                 item, 
                 item: { added, q, size, categories, modified }, _id, ...rest  } = stock
               
            return {
                ...stripTypename(rest),
                item: {
                    ...stripTypename(item),
                    q: stripTypename(q),
                    size: stripTypename(size),
                    added: stripTypename(added),
                    modified: stripTypename(modified),
                    categories: stripTypename(categories),
                }
            }
        })

        const { seenBy, ...rest } = invoice

        newInvoice = {
            ...rest,
            stocks,
            customer,
            tid,
        }

        if (invoice._id) {
            const { __typename, added, modified, ...i } = invoice
            newInvoice = {
                ...i,
                stocks: [...stocks],
                added: stripTypename(added),
                modified: stripTypename(modified),
                customer: stripTypename(customer),
            }
        }
        const { recieved } = invoice

        checkOut({
            variables: {
                invoice: {
                    ...newInvoice,
                    customer: { ...stripTypename(customer) },
                    recieved: recieved > 0 ? recieved : +getCartTotal(invoice.stocks).toFixed(0)
                }
            },
        })
    }

    return (
        <Container {...props}>
            <FormItem onSubmit={(e: React.SyntheticEvent) => handleSubmit(e)}>
                <InfoSection>
                    <InfoItemHeader>
                        <h6>Payment</h6>
                    </InfoItemHeader>
                    <InfoItem>
                        <MethodOpts>
                            <h6 style={{ color: 'rgba(60, 60, 67, 0.6)' }}>Method</h6>
                            <div onClick={(e: SyntheticEvent) => setShowMenu(true)} className='opt'>
                                <p onClick={() => select('Cash')} className={`${invoice.paymentMethod==='Cash'? 'selected':''}`}>Cash</p>
                                <p onClick={() => select('Transfer')} className={`${invoice.paymentMethod==='Transfer'? 'selected':''}`}>Transfer</p>
                                <p onClick={() => select('POS')} className={`${invoice.paymentMethod==='POS'? 'selected':''}`}>POS</p>
                            </div>
                        </MethodOpts>
                    </InfoItem>
                
                    <Divider />
                </InfoSection>
                <InfoSection>
                    <InfoItemHeader>
                        <h6>Customer</h6>
                        <Icon>
                            <Plus />
                        </Icon>
                    </InfoItemHeader>
                    <InfoItem>
                        <label>Name</label>
                        <p>
                            <input
                                name='firstName'
                                placeholder='Enter customer name'
                                className='form-control firstName'
                                value={customer?.firstName}
                                onChange={(e: SyntheticEvent)=>handleChange(e)} 
                            />
                        </p>
                        <label>Contact</label>
                        <p>
                            <input
                                name='phone'
                                placeholder='Email or phone number'
                                className='form-control firstName'
                                value={customer?.phone}
                                onChange={(e: SyntheticEvent)=>handleChange(e)} 
                            />
                        </p>
                        <label>Address</label>
                        <p>
                            <input
                                name='address'
                                placeholder='Address'
                                value={customer?.address}
                                className='form-control firstName'
                                onChange={(e: SyntheticEvent)=>handleChange(e)} 
                            />
                        </p>
                    </InfoItem>
                    <Divider />
                </InfoSection>
                <InfoSection>
                    <InfoItemHeader>
                        <h6>Summary</h6>
                    </InfoItemHeader>
                    <InfoItem>
                        {
                            editPayment &&
                            <PaymentChanger>
                                <PaymentsCont>
                                    <p className=''>Current total</p>
                                        <h6>{formatMoney(total)}</h6>
                                    <VDivider id='vdivider' />
                                </PaymentsCont>
                                <PaymentsCont className='new--price'>
                                    <p className=''>New total</p>
                                    <input
                                        name='payment'
                                        placeholder='78,000'
                                        className='form-control firstName'
                                        value={newPayment}
                                        onChange={(e: any) => setNewPayment(+e.target.value)}
                                    />
                                </PaymentsCont>
                                <DoneBtn
                                    onClick={updatePayment}
                                    style={{ backgroundColor: 'inherit' }}>
                                    Done
                                </DoneBtn>
                            </PaymentChanger>
                        }
                        
                        <label onClick={()=>setEditPayment(true)} className='edit'>Edit</label>
                        <p>Paid <span>{formatMoney(invoice.recieved)}</span></p>
                        <p className='subinfo'>Outstanding <span>{formatMoney(total-invoice.recieved)}</span></p>
                    </InfoItem>
                    {/* <Divider /> */}
                </InfoSection>
                <InfoSection style={{ paddingTop: '0px' }}>
                    <InfoItem style={{ alignItems: 'center', flexDirection: 'row', height:'30px' }}>
                        <p>
                            Total recieved
                            <span  className='total'>
                                <span>N</span> 
                                {formatMoney(invoice.recieved)}
                            </span>
                        </p>
                        <Divider/>
                    </InfoItem>
                    <InfoItem style={{ marginTop: '18px' }}>
                        <MethodOpts checked={invoice.completed}>
                            <h6 style={{ color: 'rgba(60, 60, 67, 0.6)' }}>Completed</h6>
                            <div onClick={toggleInvoiceStatus} className='check'>
                                {/* <Icon>
                                    <Check />
                                </Icon> */}
                            </div>
                        </MethodOpts>
                    </InfoItem>
                </InfoSection>
                <Button
                    data={data}
                    loading={loading}
                    onClick={(e: Event) => handleSubmit(e)} 
                    title={`${props.invoice._id ? 'Update invoice' : 'Confirm payment'}`} />
            </FormItem>
        </Container>
    )
}
export default Payment