import styled from "styled-components";

export const Header = styled.header`
  width: 100%;
  height: 100%;
  padding: 10px 15px;
  display: grid;
  grid-template-columns: 20px 40px auto;
  align-items: center;
  background: #ffffff;
  position: relative;
  cursor: pointer;
  border-radius: 15px 15px 0px 0px;
  h6 {
    margin-bottom: 0px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 170px;
  }
  .icon {
    width: 18px;
    height: 18px;
    svg {
      stroke: #363535;
      fill: #585858;
    }
  }
`;
export const PersonInfo = styled.div`
  width: 100%;
  height: 100%;
  padding: 0px 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #ffffff;
  position: relative;
  border-radius: 15px 15px 0px 0px;
  h6, p {
    margin-bottom: 0px;
    font-size: 18px;
    font-weight: 800;
  }
  p {
    font-size: 12px;
    font-weight: normal;
  }
  
`;