import { useEffect, useState } from 'react'
// import TopNav from '../../components/navs/top'
import { Container } from 'react-bootstrap'

// import Head from './components/table'

import { TableHeader } from './components/thead';
// import UsersCard from '../../components/cards/user'
import RowGroup from './components/rowgroup';
import { useQuery } from '@apollo/client';
import { GET_STAFF } from '../../../graphql/queries';
import LoadingState from '../../inventory/components/pagestate/loader';
import ErrorState from '../../inventory/components/pagestate/error';
import EmptyState from '../../inventory/components/pagestate/empty';
import StaffForm from './components/forms';
import AddBtn from './components/button/add';





const MyStaff = (props: any) => {

    // const [navOpened, setNavOpened] = useState(false)
    // const [user, setUser] = useState<any>(null)
    // const [show, setShow] = useState(false)
    // const toggle = () => setNavOpened(!navOpened)


    useEffect(() => {
        // getFarmers();
    }, []);

    // const getFarmers = async () => {
    //     let farmers = await fetchFarmers();
    //     const Obj: any = Object;
    //     farmers = Obj.groupBy(farmers, (farmer: any) => farmer.date_created)
    //     setFarmers(farmers)
    // };

    const { data, loading, error, fetchMore, refetch } = useQuery(GET_STAFF, {
        fetchPolicy: "network-only",
    })

    let staff = []

    if (data) {
        staff = data.staff;
        // staff = data.staff.map((st: any) => {
        //     const i = Math.floor(Math.random() * 5)
        //     return {
        //         ...st,
        //         info: {
        //             ...st.info,
        //             dp: dummies[i]?.dp
        //         }
        //     }
        // })
        // console.log(data)
    }
    if (error) console.log({ ...error });


    return (
        <>
            {/* <Main> */}
            <Container style={{ paddingTop:0}} id='desktop'>
                <StaffForm />
                <TableHeader />
                {
                    // Object.keys(farmers).map(date_created => (
                    //     <RowGroup
                    //         {...props}
                    //         key={date_created}
                    //         groupId={date_created}
                    //         list={farmers[date_created]}
                    //         count={farmers[date_created].length}
                    //     />
                    // ))
                    loading ?
                        <>
                            <LoadingState />
                            {/* <CardLoader /> */}
                        </>

                        :
                        error ?
                            <ErrorState retryCallback={refetch} />
                            :
                            !data.staff.length ?
                                <EmptyState
                                    // clickCallback={openEditors}
                                    btnLabel='Add'
                                    message='No records to show'
                                    subMessage='Try adding staff to show'
                                />
                                :
                                staff.map((group: any) => (
                                    <RowGroup
                                        {...props}
                                        key={group._id}
                                        groupId={group._id}
                                        items={group.records}
                                    />
                                ))
                }
            </Container>
            {/* <div id="mobile">
                    {
                        Object.keys(users).map((role: any) => (
                            <UsersCard
                                {...props}
                                deleteCallback={deleteUser}
                                key={role}
                                groupId={role}
                                items={users[role]}
                                count={users[role].length}
                            />
                        ))
                    }
                </div> */}
            {/* </Main> */}
            {/* <UserModal initUser={initUser} addUserCallback={addUser} updateUserCallback={handleUserUpdate} show={show} closeCallback={closeModal} /> */}
            <AddBtn />
        </>

    )
}

export default MyStaff


