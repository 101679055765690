import React, { ReactElement } from 'react'

import { Star } from '../../icons'
// import CallToAction from './components/sections/checkoutActions'
import { ColorsCont, ColorsImageCont, DescCont, DescSec, DetailImgCont, Details, DetailsSec, ImageAndDescCont, ImageCont, Price, PriceCont, ReviewCont, SizeItem, SizesCont, StarsCont, LikeCont } from '../../../components/styles'

import { Like } from '../../../../../components/icons'
import { Divider, Icon } from '../../../../../components/icons/styles'
import { DetailsBody, MobDetailsCont, MobDetailsContHeader, Sections } from './styles'
import Reviews from './reviews'
import RelatedItems from './related'
import Specs from './specs'
import Recomendation from './recomendation'
import { formatMoney } from '../../../../../utils'
import { getImageUrl } from '../../../../../apollo'
import { Link } from 'react-router-dom'



interface Props {

}


const MobProdDetSec = (props: any): ReactElement => {
    const {product, related} = props

    return (
        <MobDetailsCont>
            <DetailsSec>
                <DescSec>
                    <ImageAndDescCont id='jj'>
                        <DetailImgCont>
                            <ImageCont>
                                {
                                    <img src={getImageUrl( product?.mediaUrls[0] ||  'd063578d-733d-4aca-8809-e18368d55184')} alt="" />
                                }
                                <LikeCont>
                                    <Icon>
                                        <Like />
                                    </Icon>
                                    <p>27.4K</p>
                                </LikeCont>
                            </ImageCont>
                        </DetailImgCont>
                        <DescCont>
                            <MobDetailsContHeader>
                                <Link className="store" to={`/store/${product?.owner?._id}`}>{product?.owner?.info.name}</Link>
                            </MobDetailsContHeader>
                            <DetailsBody>
                                <PriceCont>
                                    <Price>
                                        <sub>N</sub>{formatMoney(product?.sellingPrice)}<sub>.52</sub>
                                        <span></span>
                                        <span className='off'></span>
                                    </Price>
                                </PriceCont>
                                <Details className='prod--name'>
                                    {product.name}
                                </Details>
                                <Details>
                                    {product.description}
                                </Details>
                                {/* <ReviewCont>
                                    <StarsCont>
                                        <Icon>
                                            <Star />
                                        </Icon>
                                        <Icon>
                                            <Star />
                                        </Icon>
                                        <Icon >
                                            <Star />
                                        </Icon>
                                        <Icon >
                                            <Star />
                                        </Icon>
                                    </StarsCont>
                                    <p>
                                        4.7
                                        <span>2.8K Review <span>|</span> 502+ sold</span>
                                    </p>
                                    <Divider />
                                </ReviewCont> */}
                                {/* <ColorsCont>
                                    <h6>Colors:</h6>
                                    <ColorsImageCont>
                                        <img src="https://i.pinimg.com/474x/3c/91/c1/3c91c1b4a66680ee987c369455bcd9f6.jpg" alt="" />
                                        <img src="https://i.pinimg.com/474x/06/ae/6a/06ae6aead1f13f5743cd2e3ff2f3c91d.jpg" alt="" />
                                        <img src="https://i.pinimg.com/474x/e2/72/d3/e272d353f8c43b1ce5cfa1eb2633b79f.jpg" alt="" />
                                        <img src="https://i.pinimg.com/474x/2c/6a/c8/2c6ac8baee98f7412a964289170e7275.jpg" alt="" />
                                        <img src="https://i.pinimg.com/474x/2b/31/67/2b3167100f46bd1905cfb26d28533e13.jpg" alt="" />
                                        <img src="https://i.pinimg.com/474x/98/51/fa/9851fabda782f5478cbdbabb97e0f0b8.jpg" alt="" />
                                        <img src="https://i.pinimg.com/474x/c1/1c/f4/c11cf492edbb6a3c1cf0c5d9993649a0.jpg" alt="" />
                                    </ColorsImageCont>
                                    <h6>Sizes:</h6>
                                    <SizesCont>
                                        <SizeItem>200mg</SizeItem>
                                        <SizeItem>250mg/100mg</SizeItem>
                                        <SizeItem>200mg</SizeItem>
                                    </SizesCont>
                                    <Divider />
                                </ColorsCont> */}
                            </DetailsBody>
                        </DescCont>
                        <Divider />
                    </ImageAndDescCont>
                    <Sections>
                        {/* <RelatedItems /> */}
                        
                        {/* <MoreProdInfoSec /> */}
                        {/* <Reviews /> */}
                        <Specs />
                        <Recomendation related={related} />
                    </Sections>
                </DescSec>
            </DetailsSec>
        </MobDetailsCont>
    )
}

export default MobProdDetSec 
