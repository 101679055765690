import { makeVar } from "@apollo/client";
import { Invoice, Order, Product } from "../models";
import { Client, Expense, FeedbackMsg, Person, Staff } from "../types/model";

export let initProduct: Product = {
    _id: '',
    name: '',
    description: '',
    categories: {
        name: null,
        category: null
    },
    q: {
        val: 0,
        unit: ''
    },
    publish: 'Both',
    status: 'Active',
    size: { val: null, unit: null },
    form: '',
    sellingPrice: 0,
    costPrice: 0,
    warningCount: 0,
    expiry: '',
    expiryWarning: 0,
    mediaUrls: [],
}
let defCustomer: Person = {
    firstName: '',
    lastName: '',
    email: '',
    address: '',
    phone: ''
}
export let initExpense: Expense = {
    _id: '',
    name: '',
    desc: '',
    spender: '',
    amount: 1,
    modifier: '',
    seenBy: []
}
export let initStaff: Staff = {
    firstName: '',
    lastName: '',
    department: '',
    position: '',
    phone: '',
    email: '',
    address: '',
    permisions: {
        creates: ['stocks', 'invoices', 'expenses'],
        edits: ['stocks', 'invoices', 'expenses'],
        deletes: ['stocks', 'invoices', 'expenses']
    },
    dp: 'a35f9e5f-771c-4afd-b4b7-0869842c8dc9',
    banner: 'd063578d-733d-4aca-8809-e18368d55184',
}

export let USERPERMS = {
    products: ['add', 'edit', 'delete', 'view'],
    sales: ['add', 'edit', 'delete', 'view'],
    expenses: ['add', 'edit', 'delete', 'view'],
    account: ['edit', 'view'],
    staff: ['add', 'edit', 'delete', 'view'],
    analytics: ['view']
}


export let initInvoice: Invoice = {
    stocks: [],
    seenBy: [],
    recieved: 0,
    payable: 0,
    paymentMethod: 'Cash',
    completed: true,
    modifier: '',
}
export const globalInvoice: any = makeVar(initInvoice);


export let defClient: Client = {
    name: '',
    username: '',
}
export const initFeedback: FeedbackMsg = {
    success: true,
    msg: '',
    done: false
}