import styled from "styled-components";

export const ActionsCont = styled.section`
    width: 100%;
    height: 570px;
    border-radius: 8px;
   
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 10px;
     border: 1px solid rgb(0 0 0 / 10%);
     @media(max-width: 420px) {
       display: none;
    }
    @media (min-width: 420px) and (max-width:1023px ){
         display: none;
    }
`
export const PriceCont = styled.div`
    min-height: 50px;
    position: relative;
`
export const ShippingCont = styled.div`
    min-height: 50px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    h6, p {
        margin-bottom: 0px;
    }
    h6 {
        font-weight: 600;
        font-size: 14px;
    }
    p {
        font-size: 14px;
        text-align: right;
    }
`
export const SelectionCont = styled.div`
    min-height: 50px;
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 15px 0px;
`

export const SelectionLabelsCont = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    margin: 0px 0px 5px 0px;
    justify-content: space-between;
    h6{
        font-weight: 600;
        font-size: 14px;
        margin-bottom: 0px;
    }
    h6:nth-child(2) {
        font-weight: 700;
    }
`
export const SelectedImagesCont = styled.div`
    position: relative;
    display: flex;
    align-items: center;
`
export const ImageCont = styled.div`
    border: none;
    position:relative;
    border-radius: 6px;
    width: 50px;
    height:50px;
    overflow:hidden;
    margin: 3px;
    img {
        height: 100%;
        width: 100%;
        border-radius: inherit;
        object-fit: cover;
        border: none;
        filter: contrast(1);
        mix-blend-mode: multiply;
        vertical-align: top center;
        @media (max-width: 420px) {
        object-fit: contain;
        }
}
`
export const DeliveryCont = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 15px 0px;
    h6{
        font-weight: 600;
        font-size: 14px;
        margin-bottom: 0px;
    }
`
export const ButtonsCont = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 15px 0px;
`
export const Button = styled.button<any>`
  height: 55px;
  width: 48%;
  border-radius: 30px;
  border:none;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #111111;
  color:  white;
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 10px;
  &:hover {
      border: none;
      background-color: #121212
  }


`

export const LikeShareCont = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
`
export const ShareCont = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 70%;
    height: 55px;
    border-radius: 30px;
    border: 1px solid grey;
    cursor: pointer;
    p {
        font-size: 14px;
        margin: 0px 5px ;
        font-weight: 600;
        
    }
`
export const LikeCont = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 25%;
    height: 55px;
    border-radius: 30px;
    border: 1px solid grey;
    cursor: pointer;
    .icon {
        width: 20px;
        height:20px;
    }
`

export const Price = styled.h4`
    font-size: 28px;
    font-weight: 700;
    margin: 15px 0px;
    sub {
        font-size: 16px;
        position: relative;
        bottom: .2px;
        margin: 0px 3px;
    }
    span {
        font-size: 16px;
        margin-left: 10px;
        text-decoration: line-through;
    }
    .off {
        font-size: 12px;
        font-weight: normal;
        text-decoration: none;
        color: grey;
    }
`

export const QtyCont =  styled.div`
    width: 100%;
    height: 100px;
    display: flex;
    position: relative;
    justify-content: center;
    flex-direction: column;
    h4 {
        font-size: 16px;
        font-weight: 700;
        margin-bottom: 10px;
    }
`

export const Input = styled.input.attrs(props => ({
    placeholder: props.placeholder,
    className: 'form-control',
}))`
  padding: 0px 3px;
  width: 30px;
  height: 100%;
  position: relative;
  border:none;
  background-color: inherit;
  text-align: center;
  font-size: 14px;
  font-weight: bold;
  color: ${props => props.theme.mode === 'dark' ? props.theme.dark.colors.labels.pri : props.theme.light.colors.labels.pri
    };
  &:focus {
    box-shadow: none;
    outline: none;
    background-color: transparent;
    color: ${props => props.theme.mode === 'dark' ? props.theme.dark.colors.labels.pri : props.theme.light.colors.labels.pri
    };
  }
  ::placeholder {
    color: lightgrey;
  }
`

export const Form = styled.form`
    display: flex;
    align-items: center;
    position: relative;
`
export const Btn = styled.button`
    width: 35px;
    height: 35px;
    outline: none;
    border: none;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #f5f5f5;
    font-weight: bold;
`