import { ApolloProvider } from "@apollo/client";
import { Outlet } from "react-router-dom";
import { createGlobalStyle, ThemeProvider } from "styled-components";
import apollo from "../apollo";
import ChatBox from "../components/chat";
import Modal from "../components/modals";
// import Notification from "../components/notification";
import AccModal from "../components/modals/account";
import { MainCheckOutModal } from "../components/modals/checkout";
// import { StockEditModal } from "../components/modals/stock";
import SideNavigator from "../components/sidenav";

import { theme } from "../theme";


const GlobalStyle = createGlobalStyle`
  body {
    background-color: ${props => props.theme.mode === 'dark' ? props.theme.dark.colors.backgrounds.base.pri : props.theme.light.colors.backgrounds.base.pri
    }
  }
`

export function Root() {

    return (
        <ThemeProvider theme={theme}>
            <GlobalStyle />
            <ApolloProvider client={apollo}>
              <Modal/>
              <AccModal />
              <MainCheckOutModal />
              <Outlet />
            </ApolloProvider>
        </ThemeProvider>
        
    );
}