import styled from "styled-components"

export const MenuOptionsList = styled.ul<any>`
  font-size: 12px;
  position: absolute;
  padding: 0px 0px;
  right: ${props => props.rt || '-55px'};
  z-index: 50;
  min-width: 150px;
  top: ${props => props.top || '40%'};
  color:black;
  background-color: white;
  border: 1px solid whitesmoke;
  border-radius: 10px;
  box-shadow: 0 5px 25px #c4c3c337;
  overflow: hidden;
`
export const Option = styled.li<any>`
  height: 35px;
  width: 100%;
  display: flex;
  cursor: pointer;
  flex-direction: column;
  justify-content: center;
  position: relative;
  &:hover {
    background: whitesmoke;
  }
  p {
    margin-bottom: 0px;
    font-size: 12px;
     padding: 0px 10px;
  }
  
`