
import { gql } from '@apollo/client'

export const ADD_TO_CART = gql`
    mutation addToCart($item: CartInput!) {
        addToCart(item: $item) {
            product {
                _id
                name
                description
                category
                subCategory
                costPrice
                sellingPrice
                instock
                stockImage
                owner
                warningCount
                expiry
                expiryWarning
                added {
                    firstName
                    lastName
                    phone
                    email
                }
                modified {
                    firstName
                    lastName
                    phone
                    email
                }
                createdAt
                updatedAt
           }
           quantity
        }
    }
`
// export const DELETE_EXPENSE = gql`
//     mutation deleteExpense( $id: String!) {
//         deleteExpense(id: $id) {
//             _id
//         }
//     }
// `
