import styled from "styled-components";

export const ContactsCont = styled.div<any>`
   height:100%;
   width: 100%;
   padding-top: 5px;
   position: absolute;
   transition: all .15s ease-in-out;
   left: ${props => !props.in ? 100 : 0}%;
   background-color: #ffffff;
`;