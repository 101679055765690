import styled from "styled-components";

export const CatCont = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
`
export const MenuCont = styled.div`
  align-items: center;
  justify-content: space-between;
  position: relative;
  display: none;
  width: 18px;
  height:15px;
  h6 {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 0px;
    margin-left: 4px;
  }
  @media (max-width: 768px) {
    max-width: 100%;
    padding: 0px;
    display: grid;
    grid-template-columns: 18px 1fr;
  }
`
export const HamdbugerMenu = styled.div`
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  position: relative;
  display: none;
  width: 100%;
  height:100%;
  .bar {
    width: 100%;
    height: 2px;
    background: #4a4949;
  }
`
export const Back = styled(HamdbugerMenu)`

  justify-content: center;
  position: relative;
  display: none;
  width: 18px;
  height:15px;
  .icon {
    width: 17px;
    height:19px;
  }
  @media (max-width: 768px) {
    max-width: 100%;
    padding: 0px;
    display: flex;
  }
`
export const HeaderMenuCont = styled.div`
    height: 100%;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: end;
    overflow: visible;
    #bag {
      width: 18px;
      height: 18px;
      position: relative;
      top:  1px;
      margin-right: 20px;
      cursor: pointer;
      svg {
        fill: #242424;
      }
  }
  @media (max-width: 768px) {
   width: auto;
   #bag {
      width: 20px;
      height: 20px;
      top: auto;
      margin-right: 0px;
    }
  }
`
export const HeaderCont = styled.div.attrs({
  className: 'container'
})`
  height: 100%;
  max-width: 1000px;
  margin-bottom: 15px;
`
export const Action = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
   position: relative;
  cursor: pointer;
  .icon {
     width: 26px;
     height: 26px;
  }
`
export const IconsGroup = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-right: 0px;
  height: 100%;
  .icon {
        width: 22px;
        height: 22px;
        svg {
          stroke: #161616;
        }
        
        margin-right: 0px;
    }
  #cheveron {
      width: 11px;
      height: 11px;
  }
  @media (max-width: 768px) {
    display: none;
  }
  
`

export const Container = styled.div<any>`
  background-color: inherit;
  height: 32px;
  width: 100%;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  margin-bottom: 0px;
  display: flex;
  flex-direction: column;
  align-items: space-between;
  justify-content: center;
  position: relative;
  text-align: left;
  font-size: 13px;
   label {
    font-size: 11px;
    width: auto;
    left: 0px;
    font-size: 11px;
    color: grey;
    margin-bottom: 0px;
    pointer-events: none;
    position: relative;
    background-color: transparent;
   
  }
  div {
    height: 20px;
  }
`

export const Header = styled.header`
    width: 100%;
    margin-bottom: 5px;
    position: relative;
    background: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: fixed;
    z-index: 22;
    padding-top: 20px;
    justify-content: space-between; 
`

export const ListCont = styled.ul<any>`
  top: 5px;
  width: 100px;
  margin: auto;
  left: 0%;
  padding-left: 0px;
  border-radius: 6px;
  z-index: 4;
  position: absolute;
  background-color:white;
  overflow-y: auto;
  li:first-child {
    border-radius: 6px 6px 0px 0px;
  }
  li:last-child {
    border-radius: 0px 0px 6px 6px;
  }
  ::-webkit-scrollbar {
    width: 0 !important
  }
  border: 1px solid rgba(71, 75, 76, 0.055);
  box-shadow: rgba(0, 0, 0, 0.05) 0 0 0 1px, rgba(0, 0, 0, 0.12) 0 15px 30px 0px;
`
export const ListItem = styled.li`
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  position: relative;
  height: 30px;
  width: 100%;
  cursor: pointer;
  border-radius: 4px;
  background-color:white;
  :hover {
    background: whitesmoke;
  }
  p{
    width: 100%;
    margin-bottom: 0px;
    font-size: 12px;
    padding-left: 10px;
    text-align: left;
    font-weight: normal;
  }
`