import styled from "styled-components";

export const Container = styled.div`
   padding: 5px;
   background: white;
    
`;
export const Header = styled.h6`
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 5px;
  width: 100%;
  position: relative;
  span {
    display: inline-block;
    position: absolute;
    right: 0px;
    .icon {
      height: 18px;
      width: 18px;
      
    }
  }
`;
export const ItemsList = styled.ul`
    padding-left: 0px;
    columns: 160px;
    column-gap: 5px;
    position: relative;

`

