import React, { useEffect, useState } from 'react'

import { CheckOutBtnItem } from './styles'
import { Loader } from '../../../../loaders';
import { Icon } from '../../../../icons/styles';
import { SmallCheckMark } from '../../../../icons';

const Button = (props: any) => {
    const { loading, data, title, disabled } = props;

    const [state, setState] = useState(title);

    useEffect(() => {
        if (loading) {
            setState('spin')
        } else if (data) {
            setState('done');
            setTimeout(() => {
                setState(title)
            }, 3000);
        }

    }, [data, loading])


    return (
        <CheckOutBtnItem style={{ backgroundColor: '#232323' }}>
            {
                state === 'spin' ?
                    <Loader />
                    : state === 'done' ?
                        <Icon className="check">
                            <SmallCheckMark />
                        </Icon>
                        :
                        state
            }
        </CheckOutBtnItem>

    )
}

export default Button