import React from 'react'
import { Divider } from '../../../../../components/icons/styles'
import { HeaderCont, HeaderListItems, Item } from './styles'

const TableHeader = () => {
    return (
        <HeaderCont>
            <HeaderListItems>
                <Item>
                    <h6>PRODUCT</h6>
                </Item>
                <Item>
                    <h6></h6>
                </Item>
                <Item>
                    <h6 style={ {textAlign: 'center' }}>SOLD</h6>
                </Item>
                <Item>
                    <h6>TOTAL AMOUNT</h6>
                </Item>
                <Divider />
            </HeaderListItems>
        </HeaderCont>
    )
}

export default TableHeader