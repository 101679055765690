import styled from 'styled-components'

export const CardCont = styled.li.attrs({
  className: 'card'
})`
  border: none;
  width: 170px;
  display: flex;
  margin: 10px 5px;
  border-radius: 10px;
  position: relative;
  cursor: pointer;
  flex-direction: column;

  @media (min-width: 744px) and (max-width: 1024px) {
    width: 158px;
    margin: 7px 5px;
    border-radius: 6px;
  }
`
export const ImageCont = styled.div`
  border: none;
  position: relative;
  border-radius: 10px;
  width: 100%;
  height: 170px;
  overflow: hidden;
  img {
    height: 100%;
    width: 100%;
    border-radius: inherit;
    object-fit: cover;
    border: none;
    filter: contrast(1);
    mix-blend-mode: multiply;
    vertical-align: top center;
    
  }
  @media (max-width: 420px) {
    height: auto;
    max-height: 200px;
  }
  @media (min-width: 744px) and (max-width: 1024px) {
    height: 158px;
    border-radius: 6px;
  }
`
export const PriceCont = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  h6 {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 0px;
    sub {
      font-size: 12px;
      bottom: 0px;
    }
  }
  p {
    margin-bottom: 0px;
    font-size: 12px;
    text-align: right;
    span {
      color: grey;
    }
  }
  @media (min-width: 744px) and (max-width: 1024px) {
    h6 {
      font-size: 18px;
      font-weight: 700;
      sub {
        font-size: 12px;
        bottom: 0px;
      }
    }
  }
`
export const StatsCont = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  p {
    margin-bottom: 0px;
    font-size: 12px;
    color: grey;
  }
`
export const RatingsCont = styled.div`
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .icon {
    width: 12px;
    height: 12px;
  }
  @media (max-width: 420px) {
    .icon {
    width: 10px;
    height: 10px;
    }
  }
  @media (min-width: 744px) and (max-width: 1024px) {
    .icon {
        width: 10px;
        height: 8px;
    }
  }
`
export const Description = styled.div`
  padding: 10px;
  max-height: 100px;
  position: relative;
  display: flex;
  flex-direction: column;
  .desc {
    color: grey;
    font-size: 12px;
    width: 98%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 0px;
  }
  .discount {
    text-decoration: line-through;
    margin-bottom: 0px;
    color: grey;
    font-size: 14px;
  }
  @media (min-width: 744px) and (max-width: 1024px) {
    padding: 10px 5px;
  }
`

export const LikeCont = styled.div<any>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  z-index: 1;
  top: 6px;
  right: 6px;
  padding: 0px 4px;
  cursor: pointer;
  border-radius: 12px;
  height: 20px;
  min-width: 35px;
  background: rgba(0, 0, 0, 0.07);
  :hover {
    background-color: #0000002c;
  }
  p {
    margin-bottom: 0px;
    font-size: 11px;
    color: white;
    font-weight: 600;
  }
  @media (max-width: 420px) {
    p {
      font-weight: 400;
    }
  }
`
