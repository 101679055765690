import React, { ReactElement, useEffect, useState } from 'react'
import { Divider, Icon } from '../../components/icons/styles'
import { Share, Star } from './components/icons'
import CallToAction from './components/sections/checkoutActions'
import MoreProdInfoSec from './components/sections/moreInfo'
import RelatedItems from './components/sections/related'
import { ImageViews, ColorsCont, ColorsImageCont, Container, DescCont, DescSec, DetailImgCont, Details, DetailsSec, ImageAndDescCont, ImageCont, Main, Price, PriceCont, ReviewCont, SizeItem, SizesCont, StarsCont, LikeCont, DeskDetailsCont, ProductDetailsContainer, DetailsButtonsCont, Rating } from './components/styles'
import ReviewSection from './components/sections/review'
import { Like } from '../../components/icons'
import MobProdDetSec from './components/sections/mobile'
import Footer from './components/footer'
import SideBar from './components/sidebar'
import MainHeader from './components/headers/main'
import { Button, ButtonsCont, LikeShareCont, ShareCont } from './components/sections/checkoutActions/styles'
import { GET_PRODUCT, GET_PRODUCT_DETAILS } from '../../graphql/queries/product.query'
import { useMutation, useQuery, useReactiveVar } from '@apollo/client'
import { useParams } from 'react-router-dom'
import LoadingState from '../inventory/components/pagestate/loader'
import ErrorState from '../inventory/components/pagestate/error'
import EmptyState from '../inventory/components/pagestate/empty'
import { formatMoney } from '../../utils'
import { Link } from 'react-router-dom'
import { ADD_TO_CART } from '../../graphql/mutations'
// import { CheckOut } from './components/cart'
import AddToCartBtn from './components/buttons/cart/details'
import { ChatModal } from '../../components/modals/chat'
import ChatBox from '../../components/chat'
import { chatView, globalUser, onlineCart, openChatBox } from '../../globals'
import { getImageUrl } from '../../apollo'
// import { CartItem } from '../../model'
import { CheckOutOnlineModal } from '../../components/modals/onlineCheckout'
import { CartItem } from '../../models'
import SideNavigator from '../../components/sidenav'


interface Props {

}


const ProdDetaills = ({ }: Props): ReactElement => {
    // const [windowSize, setWindowSize] = useState({
    //     width: window.innerWidth,
    // });
    // const [nav, setNav] = useState(false)
    const { id } = useParams()


    const { data, loading, error, fetchMore, refetch } = useQuery(GET_PRODUCT_DETAILS, {
        variables: {
            id,
        },
        fetchPolicy: "network-only",
    })

    let product: any = {};
    let related: any = {};

    if (data) {
        product = data.productDetails.product
        related = data.productDetails.related
    }

    if (error) console.log({ ...error })

    
    const { stocks: stockItems } = useReactiveVar(onlineCart)
    const setLocalStorage = () => {
        // localStorage.setItem('owner', item?.owner)
    }

    const updateCart = () => {

        const { __typename, ...striped } = data.productDetails.product;
        let { stocks } = onlineCart()
        const i = stocks.find((st: CartItem) => st._id === data.productDetails.product._id)

        stocks = (!i) ?
            [
                ...stocks, {
                    _id: data.productDetails.product._id,
                    quantity: 1,
                    booked: false,
                    status: 'Pending',
                    // delivered: 0,
                    item: striped,
                }
            ]
            :
            stocks.filter((s: any) => s._id !== i._id)

        onlineCart({
            ...onlineCart(),
            stocks
        })

    }
   
    const openChat = () => {
        globalUser({
            ...product.owner
        })
        openChatBox(true)
        chatView('chat')
    }

    return (
        <> {
            data ? < MainHeader prod={product} /> : null
        }
            
            <Main>
                <Container> {
                    loading ?
                        <LoadingState />
                        :
                        error ?
                            <ErrorState retryCallback={refetch} />
                            :
                            data ?
                                <>
                                    <ProductDetailsContainer>
                                        <DeskDetailsCont>
                                            <DetailsSec>
                                                <DescSec>
                                                    <ImageAndDescCont id='jj'>
                                                        <DetailImgCont>
                                                            <ImageCont>
                                                                {
                                                                    product.mediaUrls[0] && <img src={getImageUrl(product.mediaUrls[0] ||  'd063578d-733d-4aca-8809-e18368d55184')} alt="" />
                                                                }
                                                                <LikeCont>
                                                                    <Icon>
                                                                        <Like />
                                                                    </Icon>
                                                                    <p>27.4K</p>
                                                                </LikeCont>
                                                            </ImageCont>
                                                            {/* <ImageViews>
                                                                            <img src="https://i.pinimg.com/474x/3c/91/c1/3c91c1b4a66680ee987c369455bcd9f6.jpg" alt="" />
                                                                            <img src="https://i.pinimg.com/474x/06/ae/6a/06ae6aead1f13f5743cd2e3ff2f3c91d.jpg" alt="" />
                                                                            <img src="https://i.pinimg.com/474x/e2/72/d3/e272d353f8c43b1ce5cfa1eb2633b79f.jpg" alt="" />
                                                                            <img src="https://i.pinimg.com/474x/2c/6a/c8/2c6ac8baee98f7412a964289170e7275.jpg" alt="" />
                                                                            <img src="https://i.pinimg.com/474x/2b/31/67/2b3167100f46bd1905cfb26d28533e13.jpg" alt="" />
                                                                            <img src="https://i.pinimg.com/474x/98/51/fa/9851fabda782f5478cbdbabb97e0f0b8.jpg" alt="" />
                                                                            <img src="https://i.pinimg.com/474x/c1/1c/f4/c11cf492edbb6a3c1cf0c5d9993649a0.jpg" alt="" />
                                                                </ImageViews> */}
                                                        </DetailImgCont>
                                                        <DescCont>
                                                            <Details>
                                                                {product?.name}
                                                            </Details>
                                                            <p className='prod-desc'>
                                                                {product?.description}
                                                            </p>
                                                            <ReviewCont>
                                                               {/*  <Rating>
                                                                    <StarsCont>
                                                                        <Icon>
                                                                            <Star />
                                                                        </Icon>
                                                                        <Icon>
                                                                            <Star />
                                                                        </Icon>
                                                                        <Icon >
                                                                            <Star />
                                                                        </Icon>
                                                                        <Icon >
                                                                            <Star />
                                                                        </Icon>
                                                                    </StarsCont>
                                                                    <p>
                                                                        4.7
                                                                        <span>2.8K Review <span>|</span> 502+ sold</span>
                                                                    </p>
                                                                </Rating>*/}
                                                                <Link className="store" to={`/store/${product?.owner?._id}`}>{product?.owner?.info.name}</Link>
                                                                <Divider />
                                                            </ReviewCont> 
                                                            <PriceCont>
                                                                <Price>
                                                                    <sub>N</sub>{formatMoney(product?.sellingPrice)}
                                                                    <sub>.52</sub>
                                                                    {/* <span>10,200</span>
                                                                    <span className='off'>52% off</span> */}
                                                                </Price>
                                                                <Divider />
                                                            </PriceCont>
                                                            {/* <ColorsCont>
                                                                            <h6>Colors:</h6>
                                                                            <ColorsImageCont>
                                                                                <img src="https://i.pinimg.com/474x/3c/91/c1/3c91c1b4a66680ee987c369455bcd9f6.jpg" alt="" />
                                                                                <img src="https://i.pinimg.com/474x/06/ae/6a/06ae6aead1f13f5743cd2e3ff2f3c91d.jpg" alt="" />
                                                                                <img src="https://i.pinimg.com/474x/e2/72/d3/e272d353f8c43b1ce5cfa1eb2633b79f.jpg" alt="" />
                                                                                <img src="https://i.pinimg.com/474x/2c/6a/c8/2c6ac8baee98f7412a964289170e7275.jpg" alt="" />
                                                                                <img src="https://i.pinimg.com/474x/2b/31/67/2b3167100f46bd1905cfb26d28533e13.jpg" alt="" />
                                                                                <img src="https://i.pinimg.com/474x/98/51/fa/9851fabda782f5478cbdbabb97e0f0b8.jpg" alt="" />
                                                                                <img src="https://i.pinimg.com/474x/c1/1c/f4/c11cf492edbb6a3c1cf0c5d9993649a0.jpg" alt="" />
                                                                            </ColorsImageCont>
                                                                            <h6>Sizes:</h6>
                                                                            <SizesCont>
                                                                                <SizeItem>200mg</SizeItem>
                                                                                <SizeItem>250mg/100mg</SizeItem>
                                                                                <SizeItem>200mg</SizeItem>
                                                                            </SizesCont>
                                                            </ColorsCont> */}
                                                            <DetailsButtonsCont>
                                                                <AddToCartBtn />
                                                                <Button onClick={() => updateCart()} buy>{stockItems.some((s: any) => s._id === product._id) ? 'Remove from Cart' : 'Add to Cart'}</Button>
                                                            </DetailsButtonsCont>
                                                        </DescCont>
                                                        <Divider />
                                                    </ImageAndDescCont> 
                                                     <RelatedItems related={data.productDetails.related} />
                                                    {/* <MoreProdInfoSec /> */}
                                                    {/* <ReviewSection /> */}
                                                </DescSec>
                                            </DetailsSec>
                                        </DeskDetailsCont>
                                    </ProductDetailsContainer> 
                                    <MobProdDetSec product={product} related={related} />
                                </>
                                :
                                null
                }

                    {/* <CallToAction />  */}
                    {/* <YouMayAlsoLikeSec /> */}
                </Container>


                {/* <ChatBox /> */}
                {/* <CheckOutOnlineModal /> */}
            </Main>
            <SideNavigator />
        </>
    )
}

export default ProdDetaills 
