import { ReactElement, } from "react";
import { getImageUrl } from "../../../../apollo";
import { chatID, chatUser, chatView } from "../../../../globals";
import { Divider } from "../../../icons/styles";

import { ImageCont } from "./image/styles";
import { Desc, DesCont, Item, ItemCont, TimeCont, TimeStamp } from "./styles";

export function Person(props: any): ReactElement {
    const { clickCallback, lastMessage, chatId, person} = props

    // console.log(chatId)

    const showChat = (e:any) => {
        // chatID(chatId)
        chatUser(person)
        chatView('chat')
        // clickCallback(e);
    }
    return (
        <ItemCont onClick={(e: any)=>showChat(e)}>
            <Item>
                <ImageCont>
                    <img src={person?.info?.dp ? getImageUrl(person.info?.dp) : 'https://i.pinimg.com/474x/a4/62/d0/a462d0625c5dddc1856ca3e14e8c9435.jpg'} alt="" />
                </ImageCont>
                <DesCont>
                    <Desc>
                        <h6>{person?.info?.name}</h6>                    
                        <p>{lastMessage || person?.username || person?.email}</p>
                    </Desc>
                    <TimeCont>
                        <TimeStamp >
                            02:47AM
                        </TimeStamp>
                    </TimeCont>
                </DesCont>
                {/* <Divider /> */}
            </Item>
        </ItemCont>
    )
}