import { useMutation, useReactiveVar } from '@apollo/client'
import React, { useEffect, useState } from 'react'
import { Cancel, Chevron, HidePass, ShowPass } from '../../../../../components/icons'
import { Icon } from '../../../../../components/icons/styles'
import { globalUser, showStaffModal } from '../../../../../globals'
import { SAVE_STAFF_INFO } from '../../../../../graphql/mutations/staff.mutation'
import { GET_STAFF } from '../../../../../graphql/queries'
import { Account } from '../../../../../models/account'
import Button from '../button'
import { Input } from './inputs'
import { ErrorMessage, FormGroup, FormGroupCont, FormItem, HeaderCont, InputsCont, SwitchCont } from './styles'
import { format_date } from '../../../../../utils'

const StaffForm = (props: any) => {

    const [account, setAccount] = useState<any>({});
 
    const [focused, setFocused] = useState('')

    const [cancel, setCancel] = useState('')
    const [type, setType] = useState('password')

    const [view, setView] = useState('form')
    const modal = useReactiveVar(showStaffModal)
    const user: Account = useReactiveVar(globalUser) as any;

    useEffect(() => {
        setAccount(user)
    }, [user])
    
    // const onSwitchView = (view: string) => {
    //     setView(view);
    // }

    const [submit, { error, loading, data }] = useMutation(SAVE_STAFF_INFO, {
        update: (cache, { data: { saveStaffInfo } }) => {
            let cached:any = cache.readQuery({
                query: GET_STAFF
            })
            const i: number = cached.staff.findIndex((s: any) => format_date(s._id.toString()) === format_date(saveStaffInfo?.createdAt?.toString()));

            console.log(cached)

            if(i===-1) {
                cached =  {
                    ...cached,
                    staff: [
                        {
                        __typename: 'Accounts',
                         _id: saveStaffInfo.createdAt, 
                         records: [{ ...saveStaffInfo }]
                        }, 
                        ...cached.staff
                    ]
                }
            } else {
                cached = {
                    ...cached,
                    staff: cached.staff.map((s:any, n:any)=>{
                        if(n===i) {
                            return {
                                ...s,
                                records: [saveStaffInfo, ...s.records]
                            }
                        }
                        else {
                            return s
                        }
                    })
                    // cached.staff.map((s:any, j:number)=>{
                    //     return i===j  ? 
                    //     {
                    //         ...s, 
                    //         records: s.records.map((r: any) => (format_date(r._id.toString()) === format_date(saveStaffInfo._id.toString()) ? saveStaffInfo: r))
                    //     }
                    //     :
                    //     s
                    // })
                }
            }

            cache.writeQuery({
                query: GET_STAFF,
                data: {
                    staff: [
                        ...cached.staff
                    ]
                }
            })
         }
    })

    if (error) console.log({ ...error });

    const handleChange = (e: any) => {
        e.persist();
        if(e.target.name==='username' || e.target.name === 'password') {
            setAccount({
                ...account,
                [e.target.name]: e.target.value
            })
        } else {
            setAccount({
                ...account,
                info: {
                    ...account.info,
                    [e.target.name]: e.target.value
                }
            })
        }
    }

    const handleSubmit = (e: any) => {
        e.preventDefault();
        submit({
            variables: {
                input: account
            }
        })
    }

    const switchType = () => {
        setType(type === 'password' ? 'text' : 'password')
    }

    const clearInput = (name: string) => {
        if (name === 'username' || name === 'password') {
            setAccount({
                ...account,
                [name]: ''
            })
        } else {
            setAccount({
                ...account,
                info: {
                    ...account.info,
                    [name]: ''
                }
            })
        }
    }
    // const switchView = (e: any,name: string) => {
    //     e.preventDefault()
    //     setView(name)
    // }
    const focus = (name: string) => {
        setFocused(name)
    }
    const mouseEnter = () => {
        setCancel('address');
    }
    const mouseLeave = () => {
        setCancel('')
    }
    return (
        <FormItem show={modal} onSubmit={(e: React.SyntheticEvent) => handleSubmit(e)}>
            <SwitchCont>
                <HeaderCont>
                   <h6 onClick={()=>setView('form')}>
                        {
                           view ==='dp' && 
                           <span>
                                <Icon rot={90} onClick={() => setView('form')}>
                                    <Chevron />
                                </Icon>
                            </span>
                        }
                        New Staff
                    </h6>
                    <Icon onClick={() => showStaffModal(false)}>
                      <Cancel />
                   </Icon> 
               </HeaderCont>
                <InputsCont in={view === 'form'}>
                    <FormGroupCont>
                        <FormGroup 
                            focused={focused === 'name'}
                            onMouseLeave={() => mouseLeave()} 
                            onMouseEnter={() => mouseEnter()}>
                            <Input
                               
                                name='name'
                                label='Full name'
                                placeholder='John Doe'
                                value={account?.info?.name}
                                focused={focused === 'name'}
                                changeCallback={(e: any) => handleChange(e)}
                                focusedCallback={(name: string) => focus(name)}
                            />
                            {
                                (cancel === 'name') &&
                                <Icon onClick={() => clearInput('name')}>
                                    <Cancel />
                                </Icon>
                            }
                        </FormGroup>
                    </FormGroupCont>
                    <FormGroupCont>
                        <FormGroup
                            top
                            focused={focused === 'phone'}
                            onMouseLeave={() => mouseLeave()}
                            onMouseEnter={() => mouseEnter()}>
                            <Input
                                name='phone'
                                label='Phone'
                                placeholder='0801234565789'
                                value={account.info?.phone}
                                focused={focused === 'phone'}
                                changeCallback={(e: any) => handleChange(e)}
                                focusedCallback={(name: string) => focus(name)}
                            />
                            {
                                (cancel === 'phone') &&
                                <Icon onClick={() => clearInput('phone')}>
                                    <Cancel />
                                </Icon>
                            }
                        </FormGroup>
                        <FormGroup focused={focused === 'email'}>
                            <Input
                                name='email'
                                label='Email (Optional)'
                                placeholder='user@example.com'
                                value={account.info?.email}
                                focused={focused === 'email'}
                                changeCallback={(e: any) => handleChange(e)}
                                focusedCallback={(name: string) => focus(name)}
                            /> {
                                <Icon>
                                    <Cancel />
                                </Icon>
                            }
                        </FormGroup>
                    </FormGroupCont>
                    <FormGroupCont>
                        <FormGroup focused={focused === 'username'} top>
                            <Input
                                required
                                name='username'
                                label='Username'
                                placeholder='john.doe'
                                value={account?.username}
                                focused={focused === 'username'}
                                changeCallback={(e: any) => handleChange(e)}
                                focusedCallback={(name: string) => focus(name)}
                            />
                            {
                                (cancel === 'username') &&
                                <Icon onClick={() => clearInput('username')}>
                                    <Cancel />
                                </Icon>
                            }
                        </FormGroup>
                        <FormGroup focused={focused === 'password'}>
                            <Input
                                type={type}
                                name='password'
                                label='Password'
                                placeholder='your state'
                                value={account?.password}
                                focused={focused === 'password'}
                                changeCallback={(e: any) => handleChange(e)}
                                focusedCallback={(name: string) => focus(name)}
                            />
                            <Icon onClick={() => switchType()} id='pwd'> {
                                type === 'password' ? <HidePass /> : <ShowPass />
                            }
                            </Icon>
                        </FormGroup>
                    </FormGroupCont> {
                        error && <ErrorMessage className="error">{error?.message}</ErrorMessage>
                    }
                    <Button
                        data={data}
                        title='Save Information'
                        loading={loading}
                    />
                    {/* <NextBtn
                        title='Continue'
                        onClick={(e: any) => switchView(e,'dp')}
                        disabled={!info.username || !info.password || !info.name ||!info.phone}
                    >
                        Save
                    </NextBtn> */}
                </InputsCont> 
                {/* <Preview data={data} error={error} loading={loading} view={view} info={info}  /> */}
            </SwitchCont>
        </FormItem>
    )
}

export default StaffForm