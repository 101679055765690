import styled from "styled-components";

export const Header = styled.header`
  width: 100%;
  height: 100%;
  padding: 10px 15px;
  display: grid;
  grid-template-columns: auto 20px 20px;
  align-items: center;
  background: #ffffff;
  position: relative;
  cursor: pointer;
  border-radius: 15px 15px 0px 0px;
  h6, p {
    margin-bottom: 0px;
    font-size: 18px;
    font-weight: 800;
  }
  .icon {
    width: 18px;
    height: 18px;
    right:  10px;
    position: absolute;
    svg {
      stroke: #363535;
      stroke-width: .7;
    }
  }
`;
