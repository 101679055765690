import styled from "styled-components";

export const CheckOutBtnItem = styled.button<any>`
  height: 45px;
  width: 100%;
  margin-top: 40px;
  border-radius: 10px;
  position: absolute;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  outline: none;
  font-size: 14px;
  font-weight: 700;
  cursor: pointer;
   color: #ffffff;
   background-color: 'black';
  &:hover {
    border: none;
    background-color: '#111211';
    color: white;
  }
  .check {
    width: 18px;
    height: 18px;
    svg {
      stroke: white;
    }
  }
  @media (max-width: 768px) {
    height: 55px;
    bottom: 90px;
  }
`;
export const DoneBtn = styled.button<any>`
  height: 45px;
  width: 100%;
  //margin-top: 46px;
  border-radius: 10px;
  position: relative;
  //bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  outline: none;
  font-size: 12px;
  font-weight: normal;
  text-decoration: underline;
  cursor: pointer;
   color: #222222;
  &:hover {
    border: none;
    background-color: '#111211';
  }
 
  @media (max-width: 768px) {
    height: 55px;
    bottom: 90px;
  }
`;